<template>
    <Layout>
        <div>
            <section class="w-100 p-4">
                <div class="card shadow mb-4">
                    <div class="card-header">
                        Lesson Management
                    </div>

                    <div class="card-body d-flex flex-row  justify-content-evenly align-items-center">
                        <div class="input-group">
                            <span class="input-group-text" id="basic-addon1">Search</span>
                            <input type="text" class="form-control" v-model="searchInput"
                                   placeholder="search for name, date or description"
                                   aria-describedby="basic-addon1">
                        </div>

                        <Link :href="this.$route('lesson.add')">
                            <button class="btn btn-success text-white">Add Lesson</button>
                        </Link>
                    </div>
                </div>
                <div class="card shadow">
                    <div class="card-header">
                        Lesson Listing
                    </div>
                    <div class="card-body d-flex flex-column  justify-content-center align-items-center">

                        <table class="table  table-hover">
                            <thead>
                            <tr>
                                <th scope="col" class="text-center">#</th>
                                <th scope="col" class="text-center">Name</th>
                                <th scope="col" class="text-center">Teacher</th>
                                <th scope="col" class="text-center description-block">Description</th>
                                <th scope="col" class="text-center">Link</th>
                                <th scope="col" class="text-center">Edit</th>
                                <th scope="col" class="text-center">Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in paginatedData" :key="index">
                                <td><span class="d-flex justify-content-center">{{ item.id }}</span></td>
                                <td><span>{{ item.name }}</span></td>
                                <td class="d-flex justify-content-center align-items-center h-90">
                                    <select name="" id="">
                                        <option v-for="(second, index) in item.members" :value="second.id">
                                            {{ second.name }}
                                        </option>
                                    </select>
                                </td>
                                <td class="description-block">
                                    <span
                                        v-if="item.description.length <= 50">{{ item.description.slice(0, 50) }}</span>
                                    <span v-else>{{ item.description.slice(0, 50) + '...' }}</span>
                                </td>
                                <td>
                                    <span v-if="item.link.length <= 50">{{ item.link.slice(0, 50) }}</span>
                                    <span v-else>{{ item.link.slice(0, 50) + '...' }}</span>
                                </td>

                                <td class="btn-width"><span><Link :href="$route('lesson.edit',item.id)"><button
                                    class="btn btn-info text-white">Edit</button></Link></span></td>
                                <td class="btn-width"><span><button @click="deleteProject(item.id)"
                                                                    class="btn btn-danger text-white">Delete</button></span>
                                </td>
                            </tr>
                            </tbody>
                        </table>


                        <nav aria-label="member pagination">
                            <ul class="pagination">
                                <li class="page-item" @click="prevPage">
                                <span class="page-link" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </span>
                                </li>
                                <li v-for="(item,index) in this.pageCount" :key="index" class="page-item"
                                    @click="goToPage(item)"><span class="page-link">{{ item }}</span></li>
                                <li class="page-item" @click="nextPage">
                                <span class="page-link" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </span>
                                </li>
                            </ul>
                        </nav>

                    </div>
                </div>
            </section>
        </div>

        <SuccessToaster v-show="showToaster" @handleToaster="handleToaster"></SuccessToaster>

    </Layout>
</template>

<script>

import Layout from "./Shared/Layout";
import {Link} from '@inertiajs/inertia-vue';
import SuccessToaster from "../../Components/Status/SuccessToaster";

export default {
    name: "LessonManagement",
    props: ['allTeacher', 'allLessonMember', 'allLesson'],
    components: {
        Layout,
        Link,
        SuccessToaster
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.showToaster = false
            }, 3000)
        })
    },
    data() {
        return {
            showToaster: true,
            pageNumber: 0,
            size: 10,
            searchInput: '',
            recalcPage: 0,
            selectedTeacher: 0,
            filteredElement: null,
        }
    },
    computed: {
        pageCount() {
            if (this.recalcPage === 0) {
                let l = this.allLesson.length;
                let s = this.size;
                return Math.ceil(l / s);
            } else {
                let l = this.recalcPage;
                let s = this.size;
                return Math.ceil(l / s);
            }
        },
        paginatedData() {
            const start = this.pageNumber * this.size,
                end = start + this.size;

            if (this.selectedTeacher) {
                this.filteredElement = this.allLesson.filter(item => {
                    return item;
                })
            } else {
                this.filteredElement = this.allLesson;
            }
            if (this.searchInput.length !== 0) {

                let data = this.filteredElement.filter(item => {

                    return item.name.toLowerCase().includes(this.searchInput.toLowerCase()) ||
                        item.description.toLowerCase().includes(this.searchInput.toLowerCase()) ||
                        item.link.toLowerCase().includes(this.searchInput.toLowerCase()) ||
                        JSON.stringify(Array(item.members)).toLowerCase().includes(this.searchInput.toLowerCase())
                });

                data.slice(start, end);
                this.recalcPage = data.slice.length;
                return data;
            } else {
                this.recalcPage = 0;
                return this.filteredElement.slice(start, end);
            }

        },

    },
    methods: {
        handleToaster(e) {
            this.showToaster = e
        },
        nextPage() {
            if (this.pageNumber !== Math.ceil(this.allLesson.length / this.size) - 1) {
                this.pageNumber++;
            }
        },
        prevPage() {
            if (this.pageNumber !== 0) {
                this.pageNumber--;
            }
        },
        goToPage(e) {
            this.pageNumber = e - 1;
        },
        deleteProject(e) {
            this.showToaster = true;
            this.$inertia.post(this.$route('lesson.delete', e), {},
                {
                    onFinish: visit => {
                        setTimeout(() => {
                            this.showToaster = false;
                        }, 3000)
                    }
                }
            )
        }
    }
}
</script>

<style scoped>
.btn-width {
    width: 50px;
}

.table td span {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;

}

.h-90 {
    height: 80px;
}

.description-block {
    width: 40%;
}

.page-item {
    cursor: pointer;
}

.input-group {
    width: 400px;
}

</style>
