<template>
    <Layout>
        <TopImg :url="headerImage" :pageTitle="pageTitle" :pageDescription="pageDescription"></TopImg>
        <section class="page-section">
            <Parallax :background="background"></Parallax>

            <div  class="main-content container">
                <div v-scroll-animation-side :class="'block ' + 'side-anim_'+index" v-for="(n, index) in 4" :key="index">
                    <img :src="blockImageArray[n-1]" alt="">
                    <div class="text">
                        <h2>{{ blockTitleArray[n-1]}}</h2>
                        <p>{{ blockContentArray[n-1]}}</p>
                    </div>
                </div>
            </div>

        </section>
    </Layout>
</template>

<script>
import Layout from "../Shared/Layout";
import TopImg from "../Components/TopImg";
import Parallax from "../Components/Parallax";
import TopTitle from "../Components/TopTitle";

export default {
    name: "Research",
    props:['pageTitle','pageDescription','headerImage','blockTitleArray','blockContentArray','blockImageArray'],
    components: {
        Layout,
        TopImg,
        Parallax,
        TopTitle
    },
    data() {
        return {
            background: 'img/default/background/home-parallax.jpg',
        }
    }
}
</script>

<style scoped>
/* Parallax page style @ css/page-parallax.css*/

.main-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 100px 0;
}

.block{
    width:90%;
}

.block:nth-child(odd) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

.block:nth-of-type(even) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.block img {
    max-width: 450px;
    margin: 1rem;
    box-shadow: rgba(255, 255, 255, 0.08) 0px 10px 15px -3px, rgba(255, 255, 255, 0.08) 0px 4px 6px -2px;
}

.block:nth-of-type(odd) .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: right;
    width: 40%;
}

.block:nth-of-type(even) .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 40%;
}

.text p{
    font-size:18px;
}

@media (max-width: 720px) {

    .main-content {
        padding: 50px 0;
    }

    .block {
        flex-direction: column !important;
        margin-top: 2rem;
    }

    .block img {
        width: 60%;
    }

    .text {
        display:flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items:center !important;
        text-align: center !important;
        width: 100% !important;
        margin-top: 1rem;
    }

    .text h2 {
        width: 100%;
        text-align: center !important;
    }

    .text p {
        margin: 1rem;
    }

}
</style>
