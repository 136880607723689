<template>
    <section class="map-block">
        <div ref="loader" class="loader">
            <Loading></Loading>
        </div>
        <div class="map-content">
            <object ref="object" class="map" type="text/html"></object>
        </div>
        <div class="map-text">
            <p>{{ description }}</p>
        </div>
    </section>

</template>

<script>
import Loading from "./Loading";

export default {
    name: "GoogleMap",
    components: {
        Loading
    },
    props: ['description', 'mapUrl'],
    data() {
        return {
            isLoading: true,
        }
    },
    mounted() {
        this.$nextTick(() => {
            //Async population of object
                this.setContent()
        })
    },
    methods: {
        setContent() {
            const start = new Promise((resolve, reject) => {
                setTimeout(() => {
                    let el = this.$refs.object;
                    let url = this.mapUrl;
                    if (this.$refs.object) {
                        el.setAttribute('data', url);
                    }
                    resolve()
                }, 2000)
            })
            start.then(() => {
                setTimeout(() => {
                    if (this.$refs.loader) {
                        this.$refs.loader.style.display = 'none';
                    }
                    console.clear()
                }, 5000)
            })
        }
    }
}
</script>

<style scoped>


.map-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: transparent;
}

.map-content {
    width: 1000px;
    height: 600px;
    margin: auto;
    position: relative;
}

.loader {
    position: absolute;
    top: 50%;
    z-index: 99;
}

.map {
    position: absolute;
    left: 0;
    top: -70px;
    width: 100%;
    height: 100%;
}

.map-text {
    margin:auto;
    width: 80%;
}

.map-text p{
    position: relative;
    top: -2rem;
}

@media (max-width: 1200px) {
    .map-content {
        width: 800px;
    }
}

@media (max-width: 920px) {
    .map-content {
        margin: auto;
        width: 600px;
    }
}

@media (max-width: 720px) {
    .map-content {
        width: 400px;
        height: 400px;
    }
}

@media (max-width: 420px) {
    .map-content {
        width: 350px;
        height: 350px;
    }

    .map-text {
        bottom: 0;
        width: 100%;
    }
}

</style>
