<template>
    <main class="container-lg">
        <Navbar></Navbar>
        <article>
            <slot/>
        </article>

    </main>
</template>

<script>
import Navbar from "./Navbar";

export default {
    name: "Layout",
    components: {
        Navbar,
    },


}
</script>

<style>
body {
    background-color: #eee;
}
</style>

<style scoped>

</style>
