<template>
    <div class="top-block w-100 d-flex justify-content-center align-items-center">
        <div class="top-text">
            <div v-scroll-animation-fast-up2 class="text-nowrap">
                <h1>{{pageTitle}}</h1>
            </div>
            <div v-scroll-animation-fast-up>
                <p>{{pageDescription}}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TopTitle",
    props:['pageTitle','pageDescription']
}
</script>

<style scoped>
.top-block {
    height:100%;
    background-color: rgba(0, 50, 50,0.25);
}

.top-text {
    position:relative;
    top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 800px;
    width:100%;
    z-index: 9;
}

h1,p{
    color:whitesmoke;
}

.top-text p {
    font-size:18px;
}

.top-text > div:nth-child(1) {
    width: 30%
}

.top-text > div:nth-child(2) {
    width: 70%;
    padding-left: 4rem;
}

@media (min-width: 1200px) {
    .top-text {
        max-width: 1000px;
    }
}

@media (max-width: 920px) {
    .top-block {
        padding: 100px 50px;
    }

}

@media (max-width: 720px) {

    .top-text {
        top:30px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .top-text h1 {
        text-align: center;
    }

    .top-text p {
        font-size:16px;
    }

    .top-text div {
        width: 100% !important;
    }

    .top-text > div:nth-child(2) {
        padding-left: 0;
    }

    .top-block{
        text-align:center;
    }
}

</style>
