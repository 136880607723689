<template>
    <Layout>
        <section class="w-100 p-4">

            <form @submit.prevent="submit" class="card shadow mb-4">
                <div class="card-header">
                    <Link :href="this.$route('publications.management')">Publications Management</Link>
                    // Edit publications #{{publication.id}}
                </div>

                <div class="card-body d-flex flex-column  justify-content-center align-items-center">
                    <div class="col-lg-6  mt-2 pb-3 text-center position-relative">
                        <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                            <h5 class="card-title m-0">Publications Image</h5>
                            <ToolTip class="p-2" :data="tooltipPublications"></ToolTip>
                        </div>
                        <div class="input-group mb-3 ">
                            <input type="file"
                                   ref="publicationsImageUpload"
                                   @input="form.image = $event.target.files[0]"
                                   class="form-control">
                        </div>
                        <div v-if="$page.props.errors.image" class="position-absolute bottom-0 w-100">
                            <p class="text-danger">{{ $page.props.errors.image }}</p>
                        </div>
                    </div>

                    <div class="col-12 row">
                        <div class=" col-6 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Publications Name</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="form.name">
                                <label class="d-flex justify-content-between  w-110"><span>Name</span><span
                                    :class="[ this.charLeftPublicationsName > 25 ? 'text-danger' : '']">{{
                                        this.charLeftPublicationsName
                                    }} / 25</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.name">
                                <p class="text-danger">{{ $page.props.errors.name }}</p>
                            </div>
                            <div class="d-flex col-12 justify-content-center">
                                <div class=" col-6 mt-2 text-center position-relative">
                                    <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                        <h5 class="card-title m-0">Publications date</h5>
                                    </div>

                                    <div class="form-floating mb-3">
                                        <input type="date" class="form-control" v-model="form.date">
                                        <label class="d-flex justify-content-between  w-110"><span>Date</span></label>
                                    </div>
                                    <div class="mb-3" v-if="$page.props.errors.date">
                                        <p class="text-danger">{{ $page.props.errors.date }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex col-12 justify-content-center">
                                <div class=" col-6 mt-2 text-center position-relative">
                                    <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                        <h5 class="card-title m-0">Themes</h5>
                                    </div>

                                    <div class="form-floating mb-3">
                                        <select v-model="form.theme">
                                            <option v-for="(item,index) in allThemes" :key="index" :value="item.id">{{item.name}}</option>
                                        </select>
                                    </div>
                                    <div class="mb-3" v-if="$page.props.errors.date">
                                        <p class="text-danger">{{ $page.props.errors.date }}</p>
                                    </div>
                                </div>
                            </div>


                        </div>


                        <div class=" col-6 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Description</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="form.description"
                                          style="height: 300px"></textarea>
                                <label class="d-flex justify-content-between  w-110"><span>Description</span><span
                                    :class="[ this.charLeftPublicationsDescription > 1000 ? 'text-danger' : '']">{{
                                        this.charLeftPublicationsDescription
                                    }} / 1000</span></label>
                            </div>

                            <div class="mb-3" v-if="$page.props.errors.description">
                                <p class="text-danger">{{ $page.props.errors.description }}</p>
                            </div>
                        </div>

                    </div>


                    <div class="col-12 d-flex justify-content-evenly align-items-start">
                        <div class="col-5">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Upload Document</h5>
                                <ToolTip class="p-2" :data="tooltipDocument"></ToolTip>
                            </div>
                            <div class="input-group mb-3 ">
                                <input type="file"
                                       ref="publicationsDocUpload"
                                       @input="document = $event.target.files[0]"
                                       class="form-control">
                            </div>
                            <div v-if="$page.props.errors.document" class="position-absolute w-100">
                                <p class="text-danger">{{ $page.props.errors.document }}</p>
                            </div>
                        </div>

                        <div style="height:150px;" class="col-1 d-flex justify-content-center align-items-center">
                            <p>Or</p>
                        </div>

                        <div class="col-5">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3 pt-3">
                                <h5 class="card-title m-0">Download Link</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input ref="downloadLinkInput" style="height:60px;" type="text" class="form-control"
                                       v-model="downloadLink">
                                <label class="d-flex justify-content-between  w-110"><span>Download Link</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.downloadLink">
                                <p class="text-danger">{{ $page.props.errors.downloadLink }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 d-flex justify-content-evenly align-items-center col-12 mb-4">
                        <div class="col-5">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Add Author</h5>
                                <ToolTip class="p-2" :data="tooltipAuthor"></ToolTip>
                            </div>
                            <div class="form-floating mb-3 d-flex justify-content-start position-relative">
                                <input type="text" class="form-control w-75" v-model="searchAuthor">
                                <label class="d-flex justify-content-between  w-85 "><span>Search</span><span>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                         width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path
                                        d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/></svg>
                                </span></label>
                                <div class="d-flex justify-content-center align-items-center ms-5" @click="showSearchModal= false; searchAuthor = null;"><span class="btn btn-info text-white">X</span></div>

                                <div v-show="showSearchModal">
                                    <div
                                        class="search-modal d-flex flex-column justify-content-start align-items-center">
                                    <span class="w-100 search-element" v-for="(item,index) in searchResult"
                                          @click="addAuthor(item.id,item.name)">
                                        <p class="m-2 "
                                        >{{ item.name }}</p>
                                    </span>
                                    </div>
                                </div>
                            </div>


                        </div>


                        <div class="col-5">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3 pt-3">
                                <h5 class="card-title m-0">Author</h5>
                            </div>


                            <div class="recap-author">
                                <div v-for="(item,index) in form.author" @click="removeAuthor(item.id)"
                                     class="selected-author bg-info">
                                    <div class="d-flex justify-content-evenly align-items-center p-2 text-white cursor">
                                        <span>#{{ item.id }}</span><span class="ms-1 me-1">-</span>
                                        <p>{{ item.name }}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <Submit :loading="loading"></Submit>
                </div>

            </form>
        </section>


    </Layout>
</template>

<script>
import Layout from "./Shared/Layout";
import Submit from "../../Components/Button/Submit";
import ToolTip from "../../Components/Info/ToolTip";
import {Link} from '@inertiajs/inertia-vue'

export default {
    name: "EditPub",
    props:['publication','allThemes','allMembers','publicationMember'],
    components: {
        Layout,
        Submit,
        ToolTip,
        Link
    },
    data() {
        return {
            loading: false,
            tooltipPublications: 'Change project image. Dimension ratio for image must be 3:2, only .jpg .jpeg or .png . Max weight 1mb.',
            tooltipDocument: 'Upload document. Accepted .pdf, 20MB Max Size !!',
            tooltipAuthor: 'Please enter the member to add as an author',
            downloadLink: null,
            document: null,
            searchAuthor: null,
            showSearchModal: false,
            form: {
                image: null,
                name: this.publication.name,
                date: this.publication.date,
                theme: this.publication.theme_id,
                description: this.publication.description,
                author: this.publicationMember,
            },
        }
    },
    computed: {
        charLeftPublicationsName() {
            return this.charLeft(this.form.name)
        },
        charLeftPublicationsDescription() {
            return this.charLeft(this.form.description)
        },
        searchResult() {
            if (this.searchAuthor !== null) {
                this.showSearchModal = true;
                return this.allMembers.filter(item => {
                    return item.name.toLowerCase().includes(this.searchAuthor.toLowerCase());
                })
            } else {
                this.showSearchModal = false;
            }

        }
    },
    watch: {
        downloadLink: function (e) {
            if (e.length > 0) {
                this.$refs.publicationsDocUpload.value = null;
                this.$refs.publicationsDocUpload.disabled = true;
                this.document = null;
            } else {
                this.$refs.publicationsDocUpload.disabled = false;
            }
        },
        document: function (e) {
            if (e) {
                this.downloadLink = null;
                this.$refs.downloadLinkInput.disabled = true;
            } else {
                this.$refs.downloadLinkInput.disabled = false;
            }
        }
    },
    methods: {
        charLeft(e) {
            return e.length;
        },
        addAuthor(id, name) {
            let value = {
                id: id,
                name: name,
            };
            let array = [];

            Object.keys(this.form.author).forEach(key => {
                array.push(this.form.author[key].id)
            })

            if (!array.includes(id) || array.length === 0) {
                this.form.author.push(value);
                this.searchAuthor = null;
                this.showSearchModal = false;
            }

        },
        removeAuthor(e) {
            Object.keys(this.form.author).forEach(key => {
                if (this.form.author[key].id === e) {
                    this.form.author.splice(key, 1);
                }
            })
        },
        submit() {
            this.loading = true;
            this.$inertia.post(this.$route('publication.update',this.publication.id),
                {
                    image: this.form.image,
                    name: this.form.name,
                    date: this.form.date,
                    theme: this.form.theme,
                    description: this.form.description,
                    document: this.document,
                    downloadLink: this.downloadLink,
                    author: this.form.author,
                },
                {
                    preserveScroll: true,
                    onError: visit => {
                        this.loading = false;
                    },
                    onFinish: visit => {
                        this.loading = false;
                    }
                }
            )
        }
    }
}
</script>

<style scoped>
.w-110 {
    width: 110%;
}

.w-85 {
    width: 85%;
}

.recap-author {
    width: 100%;
    height: 300px;
    background-color: #f8fafc;
    border: 1px solid #ced4da;
    border-radius: 5px;
}

.search-modal {
    position: absolute;
    top: 55px;
    width: 75%;
    left: 0;
    background-color: #f8fafc;
    border: 1px solid #ced4da;
    border-radius: 5px;
}

.search-element {
    cursor: pointer;
}

.search-element:hover {
    background-color: #ced4da;
}

.selected-author {
    display: inline-flex;
    min-width: 75px;
    height: 30px;
    border-radius: 5px;
    margin: 1rem;
}

.cursor {
    cursor: pointer;
}
</style>
