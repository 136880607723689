<template>
    <div class="main">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="orange">
            <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/>
        </svg>
        <span class="tooltiptext p-2">{{data}}</span>
    </div>
</template>

<script>
export default {
    name: "ToolTip",
    props: ['data']
}
</script>

<style scoped>
.main {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    position:relative;
}
.main .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 0;
    margin-left: -80px;
}
.main:hover .tooltiptext {
    visibility: visible;
}
</style>
