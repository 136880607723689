import { render, staticRenderFns } from "./AddProject.vue?vue&type=template&id=4961669c&scoped=true&"
import script from "./AddProject.vue?vue&type=script&lang=js&"
export * from "./AddProject.vue?vue&type=script&lang=js&"
import style0 from "./AddProject.vue?vue&type=style&index=0&id=4961669c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4961669c",
  null
  
)

export default component.exports