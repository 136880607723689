<template>
    <div>
        <section class="page">

            <Parallax :background="this.background"></Parallax>

            <div class="main-content">

                <div class="login-form bg-light mt-4 p-4">
                    <form @submit.prevent="submit" method="POST" class="row g-3">

                        <div class="col-12">
                            <label>Email</label>
                            <input v-model="form.email" type="text" name="email" class="form-control" placeholder="email" readonly>
                        </div>
                        <div v-if="$page.props.errors.email">
                            <p class="text-danger text-center">{{ $page.props.errors.email }}</p>
                        </div>

                        <div class="col-12">
                            <label>New Password</label>
                            <input v-model="form.password" type="password" name="email" class="form-control" placeholder="New password">
                        </div>
                        <div v-if="$page.props.errors.password">
                            <p class="text-danger text-center">{{ $page.props.errors.password }}</p>
                        </div>

                        <div class="col-12">
                            <label>Confirm Password</label>
                            <input v-model="form.verifyPassword" type="password" name="email" class="form-control" placeholder="Confirm password">
                        </div>
                        <div v-if="$page.props.errors.verifyPassword">
                            <p class="text-danger text-center">{{ $page.props.errors.verifyPassword }}</p>
                        </div>

                        <div class="col-12">
                            <label>
                                Password must have :
                            </label>

                            <div class="mt-1">
                                <div class="d-flex justify-content-start align-items-center">
                                    <p>One uppercase letter</p>
                                </div>

                                <div class="d-flex justify-content-start align-items-center">
                                    <p>One lower case letter</p>
                                </div>

                                <div class="d-flex justify-content-start align-items-center">
                                    <p>One numeric value</p>
                                </div>

                                <div class="d-flex justify-content-start align-items-center">
                                    <p>One special character</p>
                                </div>

                                <div class="d-flex justify-content-start align-items-center">
                                    <p>Min 6 char longs</p>
                                </div>

                            </div>
                        </div>



                        <div class="col-12 d-flex justify-content-center align-items-center">
                            <button type="submit" class="btn btn-dark float-end">Submit</button>
                        </div>

                        <div v-if="$page.props.errors.maxAttempt">
                            <p class="text-danger text-center">{{ $page.props.errors.maxAttempt }}</p>
                        </div>
                    </form>

                </div>
            </div>

        </section>

    </div>
</template>

<script>
import Layout from "../Shared/Layout";
import {Link} from '@inertiajs/inertia-vue';
import Parallax from "../Components/Parallax";

export default {
    name: "UpdatePwd",
    props:['token','background'],
    components: {
        Layout,
        Link,
        Parallax,
    },
    mounted(){
        document.querySelector('body').style.overflow = "hidden";
        this.$nextTick(() => {
            if (window.location.href.indexOf('=') > 0) {
                this.form.email = window.location.href.slice(window.location.href.indexOf('=') + 1, window.location.href.length).replace('%40', '@');
            }
        })
    },
    data() {
        return {
            scrollPos:null,
            loading: false,
            showModal: true,
            topUrl: 'img/default/background/slider-var1.jpg',
            form:{
                token:this.token,
                email: null,
                password:null,
                verifyPassword: null,
            }
        }
    },
    methods: {
        history() {
            history.pushState(null, document.title, this.$route('login'));
        },
        handleModal(e) {
            console.log(e)
            this.showModal = e
        },
        submit(){
            this.$inertia.post(this.$route('password.update'), this.form);
        }
    },
    beforeDestroy() {
        document.querySelector('body').style.overflow = "auto";
    }
}
</script>

<style scoped>
/* Parallax page style @ css/page-parallax.css*/

.page {
    background-color: rgb(25, 22, 22);
    height: 100vh;
    width: 100vw;
    overflow:hidden;
}

.main-content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.main-content > * {
    z-index: 999;
    color: black;
}

.login-form {
    width:350px;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.25) 0px 8px 24px;
}

</style>
