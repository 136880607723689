<template>
    <div>
        <div class="main" v-if="$page.props.flash.success">
            <div
                @click="closeToaster"
                class="toaster text-white">
                <div class="position-relative w-100 h-100 d-flex justify-content-center align-items-center">
                    <span class="close">x</span>
                    <p v-if="$page.props.flash.success === 'data-updated'">
                        Data Updated
                    </p>
                    <p v-if="$page.props.flash.success === 'data-inserted'">
                        Data Inserted
                    </p>
                    <p v-if="$page.props.flash.success === 'data-deleted'">
                        Data Deleted
                    </p>
                    <p v-if="$page.props.flash.success === 'resetLinkSend'">
                        Reset Link Sent
                    </p>
                    <p v-if="$page.props.flash.success === 'password-updated'">
                        Password Updated
                    </p>
                </div>
            </div>
        </div>
        <div class="main" v-if="$page.props.flash.error">
            <div
                @click="closeToaster"
                class="errorToaster text-white">
                <div class="position-relative w-100 h-100 d-flex justify-content-center align-items-center">
                    <span class="close">x</span>
                    <p v-if="$page.props.flash.error === 'wrong-password'">
                        Wrong Password
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SuccessToaster",
    data() {
        return {
            showToaster: true,
            translate: false,
        }
    },
    methods: {
        closeToaster() {
            this.$emit('handleToaster', false)
        }
    },

}
</script>

<style scoped>

.main {
    position: fixed;
    top: 50px;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.toaster {
    margin-right: 50px;
    width: 150px;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
    background-color: mediumseagreen;
}

.errorToaster {
    margin-right: 50px;
    width: 150px;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
    background-color: indianred;
}

.close {
    position: absolute;
    top: 0;
    right: 5px;
}

@media (max-width: 639px) {
    .main {
        justify-content: center;
        width: 100%;
    }

    .toaster {
        margin-right: 0;
    }
}

</style>
