<template>
    <section class="container-xxl">
        <div class="text-box-top">
            <h1 v-scrollAnimationUp class="title">{{data.header_title_1}}</h1>
            <h4 v-scrollAnimationUp id="text_box_title_desc" class="description">{{data.header_subtitle_1}}</h4>
        </div>
        <div class="text-box-bottom">
            <h3 v-scrollAnimationUp2>{{data.header_title_2}}</h3>
            <h4 v-scrollAnimationUp2>{{data.header_subtitle_2}}</h4>
        </div>

            <div v-scrollAnimationUp class="moving-mouse-holder">
                <a href="#presentation" class="d-flex flex-column justify-content-center align-items-center text-decoration-none">
                <div class="mouse">
                    <div class="mouse-button">&nbsp;</div>
                </div>
                <div class="text">SCROLL DOWN <br> TO EXPLORE MORE</div>
                </a>
            </div>


    </section>
</template>

<script>
export default {
    name: "SliderText",
    props:['data'],
    data() {
        return {}
    },
    methods: {
        scrollToPres() {
            let presTop = document.getElementById('presentation').getBoundingClientRect().top;
            window.scrollTo({
                top: presTop - 75,
                behavior: 'smooth'
            });

            this.$emit('handleCloseMenu', true)
        }
    },
    mounted() {
    },
    beforeDestroy() {
    },
}
</script>

<style scoped>

section {
    position: absolute;
    content: '';
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.text-box-top {
    position: absolute;
    text-align: left;
    height: 300px;
    color: white;
    left: 10%;
    top: 20%;
}


.text-box-bottom {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
    height: 300px;
    color: white;
    right: 10%;
    bottom: 17.5%;
}


/* Mouse Scroll down to explore */
.moving-mouse-holder {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width:100%;
    bottom: 0;
    margin: 1rem auto;
}

.moving-mouse-holder .mouse {
    position: relative;
    width: 26px;
    height: 40px;
    right: 0;
    border-radius: 18px;
    border: 2px solid white;
}

.moving-mouse-holder .mouse-button {
    background-color: white;
    width: 4px;
    height: 10px;
    border-radius: 2px;
    position: absolute;
    top: 10px;
    left: 50%;
    margin: 0 0 0 -2px;
    animation: mouse-scroll 1s infinite alternate;
}

.moving-mouse-holder .text {
    text-align:center;
    margin-top: 1rem;
    color: white;
    font-size: 12px;
    line-height: 1em;
}

@keyframes mouse-scroll {
    to {
        transform: translate(0, 6px) scale(1, 0.8);
    }

}


@media (max-width: 920px) {
    .text-box-top,
    .text-box-bottom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100px;
        left: 0;
    }

    .text-box-top {
        top: 15%;
    }

    .text-box-bottom {
        bottom: 12.5%;
    }
}

@media (max-width: 720px) {
    .text-box-bottom {
        display: none;
    }

    .text-box-top {
        height: 100vh;
        display: flex;
        justify-content: space-around;
        top: 0;
    }

    .text-box-top h1 {
        font-size: 35px;
        margin: 1rem;
        transform: translateY(25px);
        transition: 1s cubic-bezier(.39, .01, .58, 1);
    }

    .text-box-top h4 {
        position: relative;
        bottom: -10px;
        font-size: 18px;
        margin: 1rem;
        transform: translateY(25px);
        transition: 1.75s cubic-bezier(.39, .01, .58, 1);
    }
}

</style>
