<template>
  <div class="block-text">
    <h1 class="mb-4">{{data.block_title}}</h1>
    <p>{{data.block_content}}</p>
  </div>
</template>

<script>
export default {
  name: "ParagraphBlockLanding",
  props: ['data'],
}
</script>

<style scoped>
.block-text {
  display: block;
  position: relative;
  padding: 150px 0 150px 0;
  max-width: 600px;
  text-align: center;
}

.block-text h1 {
  font-size: 2rem;
}

.block-text p {
  font-size: 18px;
}

</style>
