<template>
    <div>
        <section class="page">

            <Parallax :background="background"></Parallax>

            <div class="main-content">

                <div class="login-form bg-light mt-4 p-4">
                    <form @submit.prevent="submit" method="POST" class="row g-3">

                        <div class="col-12">
                            <label>Email</label>
                            <input v-model="form.email" type="text" name="email" class="form-control" placeholder="email">
                        </div>

                        <div class="col-12">
                            <Link :href="this.$route('login')" class="btn btn-outline-dark float-start">Previous
                            </Link>
                            <button type="submit" class="btn btn-dark float-end">Submit</button>
                        </div>
                        <div v-if="$page.props.errors.email">
                            <p class="text-danger text-center">{{ $page.props.errors.email }}</p>
                        </div>
                        <div v-if="$page.props.errors.maxAttempt">
                            <p class="text-danger text-center">{{ $page.props.errors.maxAttempt }}</p>
                        </div>
                    </form>

                </div>
            </div>

        </section>

    </div>
</template>

<script>
import Layout from "../Shared/Layout";
import {Link} from '@inertiajs/inertia-vue';
import Parallax from "../Components/Parallax";

export default {
    name: "ResetPwd",
    components: {
        Layout,
        Link,
        Parallax,
    },
    mounted(){
        document.querySelector('body').style.overflow = "hidden";
        this.history();
    },
    data() {
        return {
            scrollPos:null,
            loading: false,
            showModal: true,
            topUrl: 'img/default/background/slider-var1.jpg',
            background: 'img/default/background/home-parallax.jpg',
            form:{
                email: null,
            }
        }
    },
    methods: {
        history() {
            history.pushState(null, document.title, this.$route('login'));
        },
        handleModal(e) {
            console.log(e)
            this.showModal = e
        },
        submit(){
            this.$inertia.post(this.$route('password.email'), this.form);
        }
    },
    beforeDestroy() {
        document.querySelector('body').style.overflow = "auto";
    }
}
</script>

<style scoped>
/* Parallax page style @ css/page-parallax.css*/

.page {
    background-color: rgb(25, 22, 22);
    height: 100vh;
    width: 100vw;
    overflow:hidden;
}

.main-content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.main-content > * {
    z-index: 999;
    color: black;
}

.login-form {
    width:350px;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.25) 0px 8px 24px;
}

</style>
