require('./bootstrap');

import Vue from 'vue'
import { createInertiaApp } from '@inertiajs/inertia-vue'
import { InertiaProgress } from '@inertiajs/progress'


import ScrollAnimationFastUp from './Directives/scrollAnimationFastUp'
import ScrollAnimationFastUp2 from './Directives/scrollAnimationFastUp2'
import ScrollAnimationUp from './Directives/scrollAnimationUp'
import ScrollAnimationUp2 from './Directives/scrollAnimationUp2'
import ScrollAnimationUp3 from './Directives/scrollAnimationUp3'
import ScrollAnimationSide from './Directives/scrollAnimationSide'
import ScrollAnimationLeft from './Directives/scrollAnimationLeft'
import ScrollAnimationRight from './Directives/scrollAnimationRight'


Vue.directive('scrollAnimationFastUp', ScrollAnimationFastUp);
Vue.directive('scrollAnimationFastUp2', ScrollAnimationFastUp2);
Vue.directive('scrollAnimationUp', ScrollAnimationUp);
Vue.directive('scrollAnimationUp2', ScrollAnimationUp2);
Vue.directive('scrollAnimationUp3', ScrollAnimationUp3);
Vue.directive('scrollAnimationSide', ScrollAnimationSide);
Vue.directive('scrollAnimationLeft', ScrollAnimationLeft);
Vue.directive('scrollAnimationRight', ScrollAnimationRight);



InertiaProgress.init()

Vue.prototype.$route = route

createInertiaApp({
    resolve: name => require(`./Pages/${name}`),
    setup({ el, App, props }) {
        new Vue({
            render: h => h(App, props),
        }).$mount(el)
    },
})

