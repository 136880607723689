<template>
    <Layout>
        <div>
            <section class="w-100 p-4">
                <div class="card shadow mb-4">
                    <div class="card-header">
                        Opportunities Management
                    </div>

                    <div class="card-body d-flex flex-row  justify-content-evenly align-items-center">
                        <div class="input-group">
                            <span class="input-group-text" id="basic-addon1">Search</span>
                            <input type="text" class="form-control" v-model="searchInput"
                                   placeholder="search for name, date or description"
                                   aria-describedby="basic-addon1">
                        </div>
                        <Link :href="this.$route('oppo.add')">
                            <button class="btn btn-success text-white">Add Oppo</button>
                        </Link>
                    </div>
                </div>
                <div class="card shadow">
                    <div class="card-header">
                        Oppo Listing
                    </div>
                    <div class="card-body d-flex flex-column  justify-content-center align-items-center">

                        <table class="table  table-hover">
                            <thead>
                            <tr>
                                <th scope="col" class="text-center">#</th>
                                <th scope="col" class="text-center">Title</th>
                                <th scope="col" class="text-center">Start</th>
                                <th scope="col" class="text-center">End</th>
                                <th scope="col" class="text-center">Link</th>
                                <th scope="col" class="text-center">Edit</th>
                                <th scope="col" class="text-center">Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in paginatedData" :key="index">
                                <td class="text-center"><span class="d-flex justify-content-center">{{item.id}}</span></td>
                                <td class="text-center"><span class="d-flex justify-content-center">{{item.title}}</span></td>
                                <td class="text-center"><span class="d-flex justify-content-center">{{item.start}}</span></td>
                                <td class="text-center"><span class="d-flex justify-content-center">{{item.end}}</span></td>
                                <td class="link-td text-center" v-if="item.link.length > 52"><span>{{item.link.slice(0,52) + '...'}}</span></td>
                                <td class="link-td text-center" v-else><span>{{item.link}}</span></td>
                                <td class="btn-width"><span class="justify-content-center"><Link :href="$route('oppo.edit',item.id)"><button class="btn btn-info text-white">Edit</button></Link></span></td>
                                <td class="btn-width"><span class="justify-content-center"><button @click="deleteProject(item.id)" class="btn btn-danger text-white">Delete</button></span></td>
                            </tr>
                            </tbody>
                        </table>


                        <nav aria-label="member pagination">
                            <ul class="pagination">
                                <li class="page-item" @click="prevPage">
                                <span class="page-link" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </span>
                                </li>
                                <li v-for="(item,index) in this.pageCount" :key="index" class="page-item"
                                    @click="goToPage(item)"><span class="page-link">{{ item }}</span></li>
                                <li class="page-item" @click="nextPage">
                                <span class="page-link" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </span>
                                </li>
                            </ul>
                        </nav>

                    </div>
                </div>
            </section>
        </div>

        <SuccessToaster v-show="showToaster" @handleToaster="handleToaster"></SuccessToaster>

    </Layout>
</template>

<script>

import Layout from "./Shared/Layout";
import { Link } from '@inertiajs/inertia-vue';
import SuccessToaster from "../../Components/Status/SuccessToaster";

export default {
    name: "PartnersManagement",
    props:['allOppo'],
    components:{
        Layout,
        Link,
        SuccessToaster
    },
    mounted() {
        this.$nextTick(()=>{
            setTimeout(()=>{
                this.showToaster = false
            },3000)
        })
    },
    data(){
        return{
            showToaster: true,
            pageNumber: 0,
            size: 10,
            searchInput: '',
            recalcPage: 0,
        }
    },
    computed:{
        pageCount() {
            if (this.recalcPage === 0) {
                let l = this.allOppo.length;
                let s = this.size;
                return Math.ceil(l / s);
            } else {
                let l = this.recalcPage;
                let s = this.size;
                return Math.ceil(l / s);
            }
        },

        paginatedData() {
            const start = this.pageNumber * this.size,
                end = start + this.size;
            if (this.searchInput.length !== 0) {
                let data = this.allOppo.filter(item => {

                    return item.title.toLowerCase().includes(this.searchInput.toLowerCase()) ||
                        item.start.toLowerCase().includes(this.searchInput.toLowerCase()) ||
                        item.end.toLowerCase().includes(this.searchInput.toLowerCase()) ||
                        item.description.toLowerCase().includes(this.searchInput.toLowerCase()) ||
                        item.link.toLowerCase().includes(this.searchInput.toLowerCase())
                });
                data.slice(start, end);
                this.recalcPage = data.slice.length;
                return data;
            } else {
                this.recalcPage = 0;
                return this.allOppo.slice(start, end);
            }

        },

    },
    methods:{
        handleToaster(e) {
            this.showToaster = e
        },
        nextPage() {
            if (this.pageNumber !== Math.ceil(this.allOppo.length / this.size) - 1) {
                this.pageNumber++;
            }
        },
        prevPage() {
            if (this.pageNumber !== 0) {
                this.pageNumber--;
            }
        },
        goToPage(e) {
            this.pageNumber = e - 1;
        },
        deleteProject(e){
            this.showToaster = true;
            this.$inertia.post(this.$route('oppo.delete',e),{},
                {
                    onFinish: visit =>{
                        setTimeout(()=>{
                            this.showToaster = false;
                        },3000)
                    }
                }
            )
        }
    }
}
</script>

<style scoped>
.btn-width {
    width: 50px;
}

.table td span {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;

}

.link-td{
    max-width:250px;
}

.image {
    width: 75px;
}

.page-item {
    cursor: pointer;
}

.input-group {
    width: 400px;
}
.table {
    table-layout: fixed;
}
</style>
