<template>
    <Layout>
        <div>
            <section class="w-100 p-4">
                <div class="card shadow mb-4">
                    <div class="card-header">
                        Partners Management
                    </div>

                    <div class="card-body d-flex flex-row  justify-content-evenly align-items-center">
                        <div class="input-group">
                            <span class="input-group-text" id="basic-addon1">Search</span>
                            <input type="text" class="form-control" v-model="searchInput"
                                   placeholder="search for name, date or description"
                                   aria-describedby="basic-addon1">
                        </div>
                        <Link :href="this.$route('partner.add')">
                            <button class="btn btn-success text-white">Add Partners</button>
                        </Link>
                    </div>
                </div>
                <div class="card shadow">
                    <div class="card-header">
                        Partners Listing
                    </div>
                    <div class="card-body d-flex flex-column  justify-content-center align-items-center">

                        <table class="table  table-hover">
                            <thead>
                            <tr>
                                <th scope="col" class="text-center">#</th>
                                <th scope="col" class="text-center">Image</th>
                                <th scope="col" class="text-center">Order</th>
                                <th scope="col" class="text-center">Name</th>
                                <th scope="col" class="text-center">Link</th>
                                <th scope="col" class="text-center">Edit</th>
                                <th scope="col" class="text-center">Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(partner, index) in paginatedData" :key="index">
                                <td><span>{{ partner.id }}</span></td>
                                <td><span><img class="image" :src="partner.thumb_path" alt=""></span></td>
                                <td class="name-td"><span>{{ partner.name }}</span></td>
                                <td class="d-flex flex-column">
                                    <div class="h30" role="button" @click="increaseOrder(partner.id)">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                             width="24px" fill="black">
                                            <path d="M0 0h24v24H0V0z" fill="none"/>
                                            <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z"/>
                                        </svg>
                                    </div>
                                    <div class="h30" role="button" @click="decreaseOrder(partner.id)">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                             width="24px" fill="black">
                                            <path d="M0 0h24v24H0V0z" fill="none"/>
                                            <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>
                                        </svg>
                                    </div>
                                </td>
                                <td class="link-td" v-if="partner.link && partner.link.length > 52">
                                    <span>{{ partner.link.slice(0, 52) + '...' }}</span>
                                </td>
                                <td class="link-td" v-if="partner.link.length < 52"><span>{{ partner.link }}</span></td>
                                <td class="btn-width"><span><Link :href="$route('partner.edit',partner.id)"><button
                                    class="btn btn-info text-white">Edit</button></Link></span></td>
                                <td class="btn-width"><span><button @click="deleteProject(partner.id)"
                                                                    class="btn btn-danger text-white">Delete</button></span>
                                </td>
                            </tr>
                            </tbody>
                        </table>


                        <nav aria-label="member pagination">
                            <ul class="pagination">
                                <li class="page-item" @click="prevPage">
                                <span class="page-link" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </span>
                                </li>
                                <li v-for="(item,index) in this.pageCount" :key="index" class="page-item"
                                    @click="goToPage(item)"><span class="page-link">{{ item }}</span></li>
                                <li class="page-item" @click="nextPage">
                                <span class="page-link" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </span>
                                </li>
                            </ul>
                        </nav>

                    </div>
                </div>
            </section>
        </div>

        <SuccessToaster v-show="showToaster" @handleToaster="handleToaster"></SuccessToaster>

    </Layout>
</template>

<script>

import Layout from "./Shared/Layout";
import {Link} from '@inertiajs/inertia-vue';
import SuccessToaster from "../../Components/Status/SuccessToaster";

export default {
    name: "PartnersManagement",
    props: ['allPartners'],
    components: {
        Layout,
        Link,
        SuccessToaster
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.showToaster = false
            }, 3000)
        })
    },
    data() {
        return {
            showToaster: true,
            pageNumber: 0,
            size: 10,
            searchInput: '',
            recalcPage: 0,
        }
    },
    computed: {
        pageCount() {
            if (this.recalcPage === 0) {
                let l = this.allPartners.length;
                let s = this.size;
                return Math.ceil(l / s);
            } else {
                let l = this.recalcPage;
                let s = this.size;
                return Math.ceil(l / s);
            }
        },

        paginatedData() {
            const start = this.pageNumber * this.size,
                end = start + this.size;
            if (this.searchInput.length !== 0) {
                let data = this.allPartners.filter(item => {
                    return item.name.toLowerCase().includes(this.searchInput.toLowerCase()) ||
                        item.link.toLowerCase().includes(this.searchInput.toLowerCase())
                });
                data.slice(start, end);
                this.recalcPage = data.slice.length;
                return data;
            } else {
                this.recalcPage = 0;
                return this.allPartners.slice(start, end);
            }

        },

    },
    methods: {
        handleToaster(e) {
            this.showToaster = e
        },
        nextPage() {
            if (this.pageNumber !== Math.ceil(this.allPartners.length / this.size) - 1) {
                this.pageNumber++;
            }
        },
        prevPage() {
            if (this.pageNumber !== 0) {
                this.pageNumber--;
            }
        },
        goToPage(e) {
            this.pageNumber = e - 1;
        },
        deleteProject(e) {
            this.showToaster = true;
            this.$inertia.post(this.$route('partner.delete', e), {},
                {
                    onFinish: visit => {
                        setTimeout(() => {
                            this.showToaster = false;
                        }, 3000)
                    }
                }
            )
        },
        //This bring element closer to 1st position
        increaseOrder(id) {
            let movingElement = this.allPartners.find(item => item.id === id);
            let movingElementIndex = this.allPartners.findIndex(item => item.id === id);
            if (movingElementIndex > 0) {
                this.allPartners[movingElementIndex].element_order--;
                this.allPartners.splice(movingElementIndex, 1);
                this.allPartners.splice(movingElementIndex - 1, 0, movingElement)
            }
            this.$inertia.post(this.$route('partner.increaseOrder'),
                {
                    id: id,
                },
                {
                    preserveScroll: true,
                }
            )
        },
        decreaseOrder(id) {
            let movingElement = this.allPartners.find(item => item.id === id);
            let movingElementIndex = this.allPartners.findIndex(item => item.id === id);
            if (movingElementIndex < this.allPartners.length - 1) {
                this.allPartners[movingElementIndex].element_order++;
                this.allPartners.splice(movingElementIndex, 1);
                this.allPartners.splice(movingElementIndex + 1, 0, movingElement)
            }
            this.$inertia.post(this.$route('partner.decreaseOrder'),
                {
                    id: id,
                },
                {
                    preserveScroll: true
                }
            )
        }
    }
}
</script>

<style scoped>
.btn-width {
    width: 50px;
}

.table td span {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;

}

.table tr span,
.table tr div {
    display: flex;
    justify-content: center;
}

.h30 {
    height: 30px;
}

.name-td {
    max-width: 200px;
}

.link-td {
    max-width: 250px;
}

.image {
    width: 75px;
}

.page-item {
    cursor: pointer;
}

.input-group {
    width: 400px;
}
</style>
