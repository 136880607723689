<template>
    <Layout>
        <section class="w-100 p-4">

            <form @submit.prevent="submit" class="card shadow mb-4">
                <div class="card-header">
                    <Link :href="this.$route('lesson.management')">Lesson Management</Link>
                    // Add lesson
                </div>

                <div class="card-body d-flex flex-column  justify-content-center align-items-center">

                    <div class="col-6 row">
                        <div class=" col-12 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Lesson Name</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="form.name">
                                <label class="d-flex justify-content-between  w-110"><span  class="text-danger">Name</span><span
                                    :class="[ this.charLeftLessonName > 50 ? 'text-danger' : '']">{{
                                        this.charLeftLessonName
                                    }} / 50</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.name">
                                <p class="text-danger">{{ $page.props.errors.name }}</p>
                            </div>

                        </div>

                    </div>

                    <div class="col-6 row">

                        <div class=" col-12 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Lesson Link</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="form.link">
                                <label class="d-flex justify-content-between  w-110"><span>Link</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.link">
                                <p class="text-danger">{{ $page.props.errors.link }}</p>
                            </div>

                        </div>

                    </div>



                    <div class="col-6 row d-flex justify-content-evenly align-items-center">
                        <div class=" col-12 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Description</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <textarea style="height:250px;" type="date" class="form-control" v-model="form.description"></textarea>
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Description</span><span
                                    :class="[ this.charLeftLessonDescription > 1000 ? 'text-danger' : '']">{{
                                        this.charLeftLessonDescription
                                    }} / 1000</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.description">
                                <p class="text-danger">{{ $page.props.errors.description }}</p>
                            </div>

                        </div>


                    </div>

                    <div class="col-12 d-flex justify-content-evenly align-items-center col-12 mb-4">
                        <div class="col-5">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Add Teacher</h5>
                                <ToolTip class="p-2" :data="tooltipMember"></ToolTip>
                            </div>
                            <div class="form-floating mb-3 d-flex justify-content-start position-relative">
                                <input type="text" class="form-control w-75" v-model="searchMember">
                                <label class="d-flex justify-content-between  w-85 "><span>Search</span><span>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                         width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path
                                        d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/></svg>
                                </span></label>
                                <div class="d-flex justify-content-center align-items-center ms-5" @click="showSearchMemberModal= false; searchMember = null;"><span class="btn btn-info text-white">X</span></div>

                                <div v-show="showSearchMemberModal">
                                    <div
                                        class="search-modal d-flex flex-column justify-content-start align-items-center">
                                    <span class="w-100 search-element" v-for="(item,index) in searchMemberResult"
                                          @click="addMembers(item.id,item.name)">
                                        <p class="m-2 "
                                        >{{ item.name }}</p>
                                    </span>
                                    </div>
                                </div>
                            </div>


                        </div>


                        <div class="col-5">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3 pt-3">
                                <h5 class="card-title m-0">Teachers</h5>
                            </div>


                            <div class="recap-author">
                                <div v-for="(item,index) in form.members" @click="removeMembers(item.id)"
                                     class="selected-author bg-info">
                                    <div class="d-flex justify-content-evenly align-items-center p-2 text-white cursor">
                                        <span>#{{ item.id }}</span><span class="ms-1 me-1">-</span>
                                        <p>{{ item.name }}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <Submit :loading="loading"></Submit>
                </div>

            </form>
        </section>


    </Layout>
</template>

<script>
import Layout from "./Shared/Layout";
import Submit from "../../Components/Button/Submit";
import ToolTip from "../../Components/Info/ToolTip";
import {Link} from '@inertiajs/inertia-vue'

export default {
    name: "AddLesson",
    props:['allTeacher'],
    components: {
        Layout,
        Submit,
        ToolTip,
        Link
    },
    data() {
        return {
            loading: false,
            tooltipMember: 'Search for members !!! Member must exist in db in order to be shown !!!',
            searchMember: null,
            showSearchMemberModal: false,
            form: {
                name: '',
                link: null,
                description:'',
                members: []
            },
        }
    },
    computed: {
        charLeftLessonName() {
            return this.charLeft(this.form.name)
        },
        charLeftLessonDescription(){
            return this.charLeft(this.form.description)
        },
        searchMemberResult() {
            if (this.searchMember !== null) {
                this.showSearchMemberModal = true;
                return this.allTeacher.filter(item => {
                    return item.name.toLowerCase().includes(this.searchMember.toLowerCase());
                })
            } else {
                this.showSearchMemberModal = false;
            }
        }
    },
    methods: {
        charLeft(e) {
            return e.length;
        },
        addMembers(id, name) {
            let value = {
                id: id,
                name: name,
            };
            let array = [];

            Object.keys(this.form.members).forEach(key => {
                array.push(this.form.members[key].id)
            })

            if (!array.includes(id) || array.length === 0) {
                this.form.members.push(value);
                this.searchMember = null;
                this.showSearchMemberModal = false;
            }
        },
        removeMembers(e) {
            Object.keys(this.form.members).forEach(key => {
                if (this.form.members[key].id === e) {
                    this.form.members.splice(key, 1);
                }
            })
        },
        submit() {
            this.loading = true;
            this.$inertia.post(this.$route('lesson.add'),
                this.form,
                {
                    preserveScroll: true,
                    onError: visit => {
                        this.loading = false;
                    },
                    onFinish: visit => {
                        this.loading = false;
                    }
                }
            )
        }
    }
}
</script>

<style scoped>
.w-110 {
    width: 110%;
}
.recap-author {
    width: 100%;
    height: 300px;
    background-color: #f8fafc;
    border: 1px solid #ced4da;
    border-radius: 5px;
}

.search-modal {
    position: absolute;
    top: 55px;
    width: 75%;
    left: 0;
    background-color: #f8fafc;
    border: 1px solid #ced4da;
    border-radius: 5px;
    z-index:9;
}
.search-element {
    cursor: pointer;
}

.search-element:hover {
    background-color: #ced4da;
}

.selected-author {
    display: inline-flex;
    min-width: 75px;
    height: 30px;
    border-radius: 5px;
    margin: 1rem;
}

.cursor {
    cursor: pointer;
}
</style>
