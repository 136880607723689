<template>
<div class="topimg-height">
    <div class="home-parallax" :style='{ backgroundImage: `url(${url})` }'>
        <TopTitle class="top-title" :pageTitle="pageTitle" :pageDescription="pageDescription"></TopTitle>
    </div>
</div>
</template>

<script>
import TopTitle from "./TopTitle";
export default {
    name: "TopImg",
    components:{
      TopTitle
    },
    props:['url','pageTitle','pageDescription']
}
</script>

<style scoped>

.topimg-height{
    height:550px;
}


.home-parallax{
    height: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media(max-width:720px){

}
</style>
