<template>
    <Layout>
        <TopImg :url="headerImage" :pageTitle="involvedData.page_title" :pageDescription="involvedData.page_description"></TopImg>
        <section class="page-section">
            <Parallax :background="background"></Parallax>

            <div class="main-content">

                <ParagraphAndImage v-scroll-animation-left :data="textContent[0]"></ParagraphAndImage>
                <ParagraphBlock v-scroll-animation-up  :data="textContent[1].text"></ParagraphBlock>

<!--    This is  Opportunities block       -->
                <div class="available-position" v-scroll-animation-right>
                    <div>
                        <div class="container">
                            <div class="row">
                                <div class="col-6">
                                    <h3 class="mb-3">Opportunities </h3>
                                </div>
                                <div class="col-12">
                                    <div id="carouselExampleFade" class="carousel slide carousel-fade"
                                         data-interval="false">
                                        <div class="carousel-inner">
                                            <div class="carousel-item" v-for="chunk in chunkedItems" >
                                                <div class="row">
                                                    <div class="col-md-4 mb-3" v-for="(item,index) in chunk" :key="index">
                                                        <div class="card">
                                                            <div class="card-body">
                                                                <h4 class="card-title">{{ item.title }}</h4>
                                                                <h4 class="card-title">{{ item.position }}</h4>
                                                                <h5 class="dates">
                                                                    <span class="d-flex">Starts : <span
                                                                    v-if="item.start">{{ item.start }}</span><span
                                                                    v-else>NA</span></span> <span class="d-flex"> Ends : <span v-if="item.end">{{
                                                                        item.end
                                                                    }}</span><span
                                                                    v-else>NA</span></span></h5>
                                                                <p class="card-text">{{ item.description.slice(0,255)+ '...' }}</p>
                                                                <div class="mt-2 float-end">
                                                                        <button @click="openModal(item.id)" class="btn btn-dark">More Info
                                                                        </button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="control-box">
                    <div class="col-6 text-right">
                        <button class="carousel-control-prev" type="button"
                                data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button"
                                data-bs-target="#carouselExampleFade" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>

            </div>
        </section>
        <Footer></Footer>


        <modalOppo v-show="isModalOpen" :isModalOpen="isModalOpen" :selectedContent="selectedContent" @handleModal="handleModal"></modalOppo>

    </Layout>
</template>

<script>
import Layout from "../Shared/Layout";
import TopImg from "../Components/TopImg";
import Parallax from "../Components/Parallax";
import TopTitle from "../Components/TopTitle";
import ParagraphAndImage from "../Components/Text/ParagraphAndImage";
import ParagraphBlock from "../Components/Text/ParagraphBlock";
import modalOppo from "../Components/getinvolved/modalOppo";

export default {
    name: "GetInvolved",
    props:['headerImage','involvedData','involvedContent','allOpportunities'],
    components: {
        Layout,
        TopImg,
        Parallax,
        TopTitle,
        ParagraphAndImage,
        ParagraphBlock,
        modalOppo
    },
    data() {
        return {
            background: 'img/default/background/home-parallax.jpg',
            isModalOpen: false,
            selectedContent : null,
            textContent: [
                {
                    title: this.involvedContent.block_title,
                    image: this.involvedContent.image,
                    text: this.involvedContent.block_description,
                },
                {
                    text: this.involvedContent.paragraph,
                }
            ],
        }
    },
    mounted() {
        this.$nextTick(() => {
            let el = document.querySelectorAll('.carousel-item');
            el[0].classList.add('active');
        })
    },
    computed: {
        chunkedItems() {
            return _.chunk(this.allOpportunities, 3)
        }
    },
    methods:{
        handleModal(e){
            this.isModalOpen = e;
        },
        openModal(e){
            let data = this.allOpportunities.filter(item=>{
                if(item.id === e){
                    return item;
                }
            });

            this.selectedContent = data[0];
            this.isModalOpen = true;
        }
    }
}
</script>

<style scoped>
.main-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 75px 0;
    max-width: 1200px;
    margin: auto;
}

.available-position{
    margin-top:2rem;
}

.card h4,
.card h5,
.card h6 {
    color: rgba(10, 10, 10, 1);
    margin: 0.5rem 0;
}
.dates{
    display:flex;
    flex-direction: column;
}

.card p {
    color: rgba(100, 100, 100, 1);
}

.control-box {
    position: relative;
    width: 300px;
    height: 75px;
}

@media (max-width: 420px) {
    .control-box {
        position: relative;
        width: 200px;
        height: 75px;

    }

    .main-content{
        padding: 50px 0;
    }

    .container{
        margin-top:50px;
    }
}

</style>
