<template>
    <div>
        <div class="parallax" :style='{ backgroundImage: `url(${background})` }'>
        </div>
    </div>
</template>

<script>
export default {
    name: "Parallax",
    props: ['background']
}
</script>

<style scoped>
.parallax {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: .1;
    top:0;
    bottom:0;
    background-attachment: fixed;
    background-position: center;
    background-repeat: repeat-x;
    background-size: cover;
    -webkit-transform: translateZ(1px) scale(1.01);
    z-index: 0;
}

</style>
