<template>
    <section>
        <nav id="navbar" class="d-flex align-items-center">

            <div class="container-xxl d-flex justify-content-evenly align-items-center">
                <Logo class="logo"></Logo>

                <ul class="ms-2 d-flex justify-content-between align-items-center col-6">
                    <div id="the_lab" @mouseenter="showMenu" @mouseleave="showMenu"
                         class="position-relative d-flex justify-content-center align-items-center">
                    <span id="lab_arrow" class="menu-arrow">
                        <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 24 24" width="30px"
                             fill="white"><path d="M0 0h24v24H0V0z" fill="none"/><path
                            d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/></svg>
                    </span>
                        <button id="lab_btn">The Lab</button>
                        <ul id="lab_submenu" v-show="showLab" class="nav-submenu position-absolute">
                            <div class="sublist-box">
                                <li>
                                    <Link href="/teaching">Teaching</Link>
                                </li>
                                <li>
                                    <Link href="/team">Team</Link>
                                </li>
                            </div>
                        </ul>
                    </div>
                    <div id="the_project" @mouseenter="showMenu" @mouseleave="showMenu"
                         class="position-relative d-flex justify-content-center align-items-center">
                    <span id="project_arrow" class="menu-arrow">
                        <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 24 24" width="30px"
                             fill="white"><path d="M0 0h24v24H0V0z" fill="none"/><path
                            d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/></svg>
                    </span>
                        <button id="project_btn">Research</button>
                        <ul id="project_submenu" v-show="showProject" class="nav-submenu position-absolute">
                            <div class="sublist-box">
                                <li>
                                    <Link href="/research">Themes</Link>
                                </li>
                                <li>
                                    <Link href="/projets">Projects</Link>
                                </li>
                                <li>
                                    <Link href="/impact">Impact</Link>
                                </li>
                            </div>
                        </ul>
                    </div>
                    <li class="correct-left">
                        <Link href="/publications">Publications</Link>
                    </li>
                    <div id="the_partners" @mouseenter="showMenu" @mouseleave="showMenu"
                         class="position-relative correct-left d-flex justify-content-center align-items-center">
                    <span id="partners_arrow" class="menu-arrow">
                        <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 24 24" width="30px"
                             fill="white"><path d="M0 0h24v24H0V0z" fill="none"/><path
                            d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/></svg>
                    </span>
                        <button id="partners_btn">Partners</button>
                        <ul id="partners_submenu" v-show="showPartners" class="nav-submenu position-absolute">
                            <div class="sublist-box">
                                <li>
                                    <Link href="/partners">Partners</Link>
                                </li>
                                <li>
                                    <Link href="/funding">Funding</Link>
                                </li>
                            </div>
                        </ul>
                    </div>


                    <div id="join" @mouseenter="showMenu" @mouseleave="showMenu"
                         class="position-relative d-flex justify-content-center align-items-center">
                    <span id="join_arrow" class="menu-arrow">
                        <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 24 24" width="30px"
                             fill="white"><path d="M0 0h24v24H0V0z" fill="none"/><path
                            d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"/></svg>
                    </span>
                        <button id="join_btn">Join</button>
                        <ul id="join_submenu" v-show="showJoin" class="nav-submenu position-absolute">
                            <div class="sublist-box">
                                <li>
                                    <Link href="/getinvolved">Get involved</Link>
                                </li>
                                <li>
                                    <Link href="/login">Login</Link>
                                </li>
                            </div>
                        </ul>
                    </div>
                </ul>
            </div>
        </nav>
    </section>
</template>

<script>
import {Link} from '@inertiajs/inertia-vue'
import Logo from "../Logo";

export default {

    name: "NavbarFull",
    components: {
        Link,
        Logo
    },
    data() {
        return {
            showLab: false,
            showProject: false,
            showPartners: false,
            showJoin: false,
        }
    },
    mounted() {
    },
    methods: {
        showMenu(e) {
            if (e.target.id === 'the_lab') {
                if (this.showLab === false) {
                    this.showLab = true;
                    document.querySelector('#lab_arrow').style.transform = 'rotate(90deg)';
                    document.querySelector('#lab_arrow svg').style.fill = '#71BF6D';
                    document.querySelector('#lab_btn').style.color = '#71BF6D';

                } else {
                    this.showLab = false;
                    document.querySelector('#lab_arrow').style.transform = 'rotate(0deg)';
                    document.querySelector('#lab_arrow svg').style.fill = `white`;
                    document.querySelector('#lab_btn').style.color = `white`;
                }
            } else if (e.target.id === 'the_project') {
                if (this.showProject === false) {
                    this.showProject = true;
                    document.querySelector('#project_arrow').style.transform = 'rotate(90deg)';
                    document.querySelector('#project_arrow svg').style.fill = '#71BF6D';
                    document.querySelector('#project_btn').style.color = '#71BF6D';

                } else {
                    this.showProject = false;
                    document.querySelector('#project_arrow').style.transform = 'rotate(0deg)';
                    document.querySelector('#project_arrow svg').style.fill = `white`;
                    document.querySelector('#project_btn').style.color = `white`;
                }
            } else if (e.target.id === 'the_partners') {
                if (this.showPartners === false) {
                    this.showPartners = true;
                    document.querySelector('#partners_arrow').style.transform = 'rotate(90deg)';
                    document.querySelector('#partners_arrow svg').style.fill = '#71BF6D';
                    document.querySelector('#partners_btn').style.color = '#71BF6D';

                } else {
                    this.showPartners = false;
                    document.querySelector('#partners_arrow').style.transform = 'rotate(0deg)';
                    document.querySelector('#partners_arrow svg').style.fill = `white`;
                    document.querySelector('#partners_btn').style.color = `white`;
                }
            } else if (e.target.id === 'join') {
                if (this.showJoin === false) {
                    this.showJoin = true;
                    document.querySelector('#join_arrow').style.transform = 'rotate(90deg)';
                    document.querySelector('#join_arrow svg').style.fill = '#71BF6D';
                    document.querySelector('#join_btn').style.color = '#71BF6D';

                } else {
                    this.showJoin = false;
                    document.querySelector('#join_arrow').style.transform = 'rotate(0deg)';
                    document.querySelector('#join_arrow svg').style.fill = `white`;
                    document.querySelector('#join_btn').style.color = `white`;
                }
            }
        },
    }
}
</script>

<style scoped>

nav {
    position: fixed;
    height: 75px;
    width: 100%;
    top: 0;
    left: 0;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    background-color: rgba(0, 0, 0, 0.95);
    color: white;
    transform: translateY(0px);
    transition: 1s transform cubic-bezier(0, .12, .14, 1);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    z-index: 99;
}

img {
    width: 60px;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul li {
    text-decoration: none !important;
}

a {
    text-underline: none;
    text-decoration: none;
    color: white;
}

.logo {
    height: 70px;
    position: relative;
    left:-2rem;
}

nav ul > li:hover,
.nav-submenu li :hover,
a:hover {
    color: #71BF6D;
    text-decoration: none;
    text-underline: none;
}

nav button {
    padding: 0;
    font-weight: 500;
    background-color: transparent;
    text-decoration: none;
    color: white;
    border: none;
    z-index: 10;
}

nav .nav-submenu {
    top: 0;
    padding-top: 3rem;
    padding-left: 0;
    text-align: center;
    transition: 1s transform cubic-bezier(0, .12, .14, 1);
    white-space: nowrap;
}

nav .sublist-box {
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.95);
    padding: 1rem 2rem 2rem;
    border-radius: 5px;
}

nav .nav-submenu li {
    padding-top: 1.5rem;
    width: 100%;
    cursor: pointer;
}

nav .nav-submenu li:hover {
    text-decoration: underline;
}

.menu-arrow {
    position: absolute;
    left: -30px;
    top: -2px;
    transition: .25s ease;
}

.correct-left {
    position: relative;
    left: -20px;
}


</style>
