<template>
    <Layout>
        <TopImg :url="headerImage" :pageTitle="teachingData.page_title" :pageDescription="teachingData.page_description"></TopImg>
        <section class="page-section">

            <Parallax :background="background"></Parallax>

<!--    Desktop View      -->
            <div v-if="!mobileView" class="main-content">
                <div  v-scroll-animation-side :class="'box ' + 'side-anim_'+index" v-for="(profil, index) in allTeacher"
                     :key="index">
                    <div class="box-img">
                        <img :src="profil.profile_thumb" alt="">
                    </div>
                    <div class="box-text">
                        <h4>{{ profil.name }} - {{ profil.surname }}</h4>
                        <h5>{{ profil.position }}</h5>
                        <p v-if="profil.bio.length > 255">{{ profil.bio.slice(0, 255) + '...' }}</p>
                        <p v-if="profil.bio.length < 255">{{ profil.bio }}</p>
                        <div class="w-100 d-flex align-items-center justify-content-evenly">
                            <button class="btn btn-light" @click="openModal( index )">Read more</button>
                            <p>{{ profil.email }}</p>
                        </div>
                    </div>
                </div>
            </div>

<!--    Mobile View    -->
            <div v-show="mobileView" class="main-content">
                <div class="box mobile">
                    <div class="box-img">
                        <img :src="path(mobileViewData.profile_thumb)" alt="">
                    </div>
                    <div class="box-text">

                        <h4>{{ mobileViewData.name }} - {{ mobileViewData.surname }}</h4>
                        <h5>{{ mobileViewData.position }}</h5>

                        <p class="mt-3" v-if="sliceBio(mobileViewData.bio)">{{ mobileViewData.bio.slice(0, 200) + '...' }}</p>
                        <p class="mt-3" v-else>{{ mobileViewData.bio }}</p>

                        <div class="w-100 d-flex align-items-center justify-content-evenly">
                            <button class="btn btn-light mb-2" @click="openModal( index )">Read more</button>
                        </div>

                    </div>
                </div>
                <div class="prev-btn" :class="(this.index) ? '': 'grey' " @click="prev()">
                    <svg xmlns="http://www.w3.org/2000/svg" height="36px" width="36px" viewBox="0 0 24 24"
                         fill="white">
                        <path d="M0 0h24v24H0V0z" fill="none" opacity=".87"/>
                        <path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z"/>
                    </svg>
                </div>
                <div  class="next-btn" :class="(this.index < this.allTeacher.length -1 ) ? '' : 'grey'" @click="next()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36px" height="36px"
                         enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="white">
                        <g>
                            <path d="M0,0h24v24H0V0z" fill="none"/>
                        </g>
                        <g>
                            <polygon points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12"/>
                        </g>
                    </svg>

                </div>


            </div>
        </section>
        <ProfileModal :selectedProfile="selectedProfile" :isModalOpen="isModalOpen" :pageTitle="teachingData.page_title"
                      @handleModalClose="handleModalClose"></ProfileModal>
    </Layout>

</template>


<script>
import Layout from "../Shared/Layout";
import TopImg from "../Components/TopImg";
import Parallax from "../Components/Parallax";
import TopTitle from "../Components/TopTitle";
import ProfileModal from "../Components/TheLab/ProfileModal";

export default {
    name: "Presentation",
    props:['teachingData','headerImage','allTeacher'],
    components: {
        Layout,
        TopImg,
        Parallax,
        TopTitle,
        ProfileModal,
    },
    data() {
        return {
            background: 'img/default/background/home-parallax.jpg',
            mobileView: false,
            selectedProfile: null,
            isModalOpen: false,
            index: 0,

        }
    },
    mounted() {

        this.handleView()
        this.$nextTick(() => {
            this.handleOverflow();
            this.handleView();
        })

    },
    computed:{
        mobileViewData(){
            let profile = {};
            profile.profile_thumb = this.allTeacher[this.index].profile_thumb;
            profile.name = this.allTeacher[this.index].name;
            profile.surname = this.allTeacher[this.index].surname;
            profile.position = this.allTeacher[this.index].position;
            profile.bio = this.allTeacher[this.index].bio;
            return profile;
        },
    },
    methods: {
        handleView(){
            this.mobileView = window.innerWidth < 720;
        },
        handleOverflow() {
            document.querySelector('body').style.overflowY = "auto";
        },
        next() {
            if(this.index < this.allTeacher.length -1 ){
                this.index++;
            }
        },
        prev() {
            if(this.index) {
                this.index--;
            }
        },
        openModal(index) {
            this.selectedProfile = this.allTeacher[index];
            this.isModalOpen = true;
        },
        handleModalClose() {
            this.isModalOpen = false;
        },
        sliceBio(value){
            return (value.length > 200);
        },
        path(arg){
            return arg;
        }
    },
}
</script>

<style scoped>
/* Parallax page style @ css/page-parallax.css*/

.main-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    z-index: 9;
    min-height:500px;
}

.box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5rem 0;
    width: 750px;
    height: 275px;
    background-color: rgba(26, 32, 44, 0.7);
    box-shadow: 5px 5px 2px 2px rgba(255, 255, 255, 0.075);
    z-index: 9;
}

.box:nth-child(even) {
    flex-direction: row-reverse;
}

.box-img {
    width: 250px;
    height: 250px;
    margin: 0 1rem;
}

.box img {
    width: inherit;
    border-radius: 50%;
}

.box-text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width:100%;
    padding: 1rem 2rem 1rem;
    color: white;
}

.box-text p{
    font-size:16px;
}

.prev-btn {
    position: absolute;
    top: 50%;
    left: 10%;
    z-index: 99;
}

.next-btn {
    position: absolute;
    top: 50%;
    right: 10%;
    z-index: 99;
}

.grey{
    opacity: .25;
}

@media (max-width: 920px) {

    .box {
        width: 675px;
    }
}

@media (max-width: 720px) {

    .main-content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 750px;
    }

    .box {
        position: absolute;
        flex-direction: column !important;
        height: 600px;
        width: 350px;
        opacity: 0;
    }

    .box:nth-child(1) {
        opacity: 100%;
    }

    .box-img {
        padding-top: 1rem;
    }

    .box-text > div {
        margin-top: 1rem;
    }

    .box-text h4 {
        margin: 1rem 0 1rem;
    }

    .box-text h6 {
        margin-bottom: 1rem;
    }
}

</style>
