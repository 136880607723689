<template>
    <div>
        <div class="home-parallax" :style='{ backgroundImage: `url(${url})` }'>
        </div>
    </div>
</template>

<script>
export default {
    name: "Slider",
    props:['headerImage'],
    data(){
        return{
         url : this.headerImage
        }
    }
}
</script>

<style scoped>

.home-parallax{
    min-height: calc(100vh);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>
