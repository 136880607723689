<template>
    <div class="row">
        <div v-scrollAnimationUp v-for="(value,index) in data" :key="index" class="partner col-lg-3 col-md-3 col-sm-4 col-6">
            <a :href="value.link" target="_blank"><img :src="value.image" alt=""></a>
            <div>
                <h5>{{value.name}}</h5>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PartnersLogo",
    props:['data'],
}
</script>

<style scoped>
.row {
    max-width: 1000px;
    margin:1rem;
    justify-content: center;
}

.partner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    cursor: pointer;
    width: 150px;
}

.partner > div {
    margin-top: 1rem;
}

.partner img {
    width: 150px;
}
h5{
    text-align:center;
}

</style>
