<template>
    <div class="text-block">
        <p>{{data}}</p>
    </div>
</template>

<script>
export default {
    name: "ParagraphBlock",
    props: ['data']
}
</script>

<style scoped>
.text-block {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    max-width: 600px;
    margin: 2.5rem;
    padding: 1.5rem;
}

.text-block p{
    font-size:18px;
}

@media (max-width:720px){
    .text-block{
        padding:1rem;
        text-align: center;
    }


}
</style>
