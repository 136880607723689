<template>
    <div>
        <div ref="modal" v-show="isModalOpen" @click="closeModal" class="newModal">
            <div ref="modalContent" v-if="selectedProfile != null" class="modal-content">
                <div class="close-modal close-btn">
                    <svg class="close-btn" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                         width="24px" fill="white">
                        <path class="close-btn" d="M0 0h24v24H0V0z" fill="none"/>
                        <path class="close-btn"
                              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/>
                    </svg>
                </div>
                <div class="top-content">
                    <img class="top-content-img" :src="selectedProfile.profile_thumb" alt="">
                    <div class="profile-info">
                        <h3>{{ selectedProfile.name }} {{ selectedProfile.surname }}</h3>
                        <h4>{{ selectedProfile.position }}</h4>
                        <p>{{ selectedProfile.email }}</p>
                        <p>{{ selectedProfile.address }} {{ selectedProfile.country }}</p>
                        <div class="tag-block">


                            <div v-if="this.$route().current() === 'teaching' && selectedProfile.has_publications">
                                <div class="tag">{{ '#Teaching' }}</div>
                            </div>
                            <div v-else class="d-flex">
                                <Link v-if="selectedProfile.has_teaching" :href="$route('teaching')"
                                      class="tag">{{ '#Teaching' }}
                                </Link>
                            </div>
                            <div class="d-flex" v-if="selectedProfile.publications.length > 0">
                                <Link
                                    :href="$route('publications','name='+selectedProfile.name)"
                                    @click="handleOverflow()"

                                    class="tag">{{ '#Publications' }}
                                </Link>
                            </div>
                            <div class="d-flex" v-if="selectedProfile.projects.length > 0">
                                <Link
                                    :href="$route('coop','name='+selectedProfile.name)"
                                    @click="handleOverflow()"

                                    class="tag">{{ '#Projects' }}
                                </Link>
                            </div>

                        </div>
                        <div class="social-content">
                            <a v-if="selectedProfile.orbi_link" :href="selectedProfile.orbi_link" target="_blank"><img
                                src="img/default/socials/orbi.png" alt=""></a>
                            <a v-if="selectedProfile.google_link" :href="selectedProfile.google_link"
                               target="_blank"><img
                                src="img/default/socials/google-scholar.png" alt=""></a>
                            <a v-if="selectedProfile.research_link" :href="selectedProfile.research_link"
                               target="_blank"><img
                                src="img/default/socials/research-gate.png" alt=""></a>
                            <a v-if="selectedProfile.scopus_link" :href="selectedProfile.scopus_link"
                               target="_blank"><img
                                src="img/default/socials/scopus.png" alt=""></a>
                        </div>
                    </div>
                </div>
                <div class="main-content">
                    <div class="w-100 float-start mb-1">
                        <h4 class="info-title">Bio :</h4>
                    </div>
                    <div class="info-content">
                        <p>{{ selectedProfile.bio }}</p>
                    </div>

                    <div class="w-100" v-show="this.$route().current() === 'teaching' && selectedProfile.lessons.length > 0">
                        <div class="w-100 float-start mt-3">
                            <h4 class="publications-title">Lessons :</h4>
                        </div>
                        <div class="w-100">
                            <div class="lesson-content">
                                <div v-for="(lesson,index) in selectedProfile.lessons" :key="index">
                                    <div class="lesson-link">
                                        <a :href="lesson.link" target="_blank"><h6>{{ lesson.name }}</h6></a>
                                    </div>
                                    <div class="lesson-description">
                                        <p>{{ lesson.description }}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="w-100" v-if="this.$route().current() === 'team'  && selectedProfile.publications.length > 0">
                        <div class="w-100 float-start mt-3">
                            <h4 class="publications-title">Publications :</h4>
                        </div>
                        <div class="publications-content">
                            <div v-for="(publication,index) in selectedProfile.publications" :key="index">
                                <div class="publications-link">
                                    <a @click="handleOverflow" v-if="selectedProfile.publications"
                                       :href="$route('publications','name='+publication.name)"
                                    ><h6>{{ publication.name }}</h6></a>
                                </div>
                                <div class="publications-link-description">
                                    <p>{{ publication.description }}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {Link} from '@inertiajs/inertia-vue'

export default {
    name: "ProfileModal",
    props: ['isModalOpen', 'selectedProfile', 'pageTitle'],
    components: {
        Link
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    updated(){
        //Fix to avoid modal off screen
        this.$refs.modal.style.top =  window.scrollY + 'px';
        if(this.$refs.modalContent.offsetHeight > 900){
            this.$refs.modalContent.classList.add("add25Top");
        }else{
            this.$refs.modalContent.classList.remove("add25Top");
        }
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        closeModal(e) {
            if (e.target.classList.contains('newModal') || e.target.classList.contains('close-btn')) {
                this.$emit('handleModalClose');
            }
        },
        handleScroll() {
            this.$refs.modal.style.top =  window.scrollY + 'px';
        },
        handleOverflow() {
            document.querySelector('body').style.overflowY = "auto";
        }

    },
    watch: {
        isModalOpen: function (e) {
            if (e) {
                document.querySelector('body').style.overflowY = "hidden";

            } else {
                document.querySelector('body').style.overflowY = "auto";
            }
        },
    }
}
</script>

<style scoped>

/* Track */
::-webkit-scrollbar-track {
    background: rgba(77, 144, 142, .2) !important;
    border-radius: 10px !important;
}

.newModal p{
    font-size:18px;
}

.newModal {
    display: flex;
    justify-content: center;
    align-items: center;
    position:absolute;
    overflow:auto;
    background-color: rgba(0, 0, 0, .6);
    content: "";
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
}

.modal-content {
    position: absolute;
    background-color: #1e2125;
    width: 600px;
    /*top:25px;*/
    color: whitesmoke;
    box-shadow: 5px 4px 2px rgba(255, 255, 255, 0.2);
}

.add25Top{
    top:25px;
}

.close-modal {
    position: absolute;
    right: 1rem;
    top: 1rem;
    color: white;
    cursor: pointer;
}

.top-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40%;
    margin-top: 20px;
}

.top-content-img {
    width: 40%;
    border-radius: 50%;
    margin: 14px;
}

.tag-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.tag {
    background-color: rgba(255, 255, 255, 0.5);
    color: whitesmoke;
    text-decoration: none;
    text-underline: transparent;
    padding: 5px 10px;
    margin: 2px 2px;
    border-radius: 5px;
    cursor: pointer;
}

.social-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
}

.social-content img {
    width: 40px;
    border-radius: 5px;
    margin-right: 1rem;
}

.social-content img:hover {
    opacity: .6;
}

.profile-info {
    width: 50%;
}

.profile-info > * {
    margin-bottom: 1rem;
}

.main-content {
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 1rem 2rem 2rem;
    font-size: 16px;
    overflow: auto;
    margin-right: 10px;
    margin-bottom: 10px;
}

.info-title,
.lesson-title,
.publications-title {
    border-bottom: 1px solid white;
}

.info-content p,
.publications-content p {
    padding: 1rem;
}

.lesson-content > div,
.publications-content > div {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 0;
}

.lesson-link,
.publications-link {
    width: 25%;
}

.lesson-link a,
.publications-link a {
    text-decoration: none;
}

.lesson-link h6,
.publications-link h6 {
    text-decoration: none;
    line-height: 18px;
    font-size: 16px;
}

.lesson-description,
.publications-link-description {
    width: 75%;
    line-height: 18px;
    font-size: 16px;
}

.lesson-description p,
.publications-link-description p {
    padding: 0 0 0 1rem;
}

@media(max-width: 600px){
    .modal-content{
        width:100%;
    }
}


@media (max-width: 420px) {

    .modal {
        align-items: flex-start;
    }

    .tag-block {
        justify-content: center;
    }

    .modal-content {
        height: auto;
        top:0;
        width:100%;
    }

    .top-content {
        flex-direction: column;
        height: 100%;
    }

    .profile-info {
        text-align: center;
        width: 100%;
    }

    .profile-info p {
        padding: 0 2rem;
    }

    .social-content {
        justify-content: center;
    }

    .main-content {
        overflow: visible;
    }

    .lesson-title,
    .publications-title {
        margin-bottom: 1rem;
    }

    .lesson-content,
    .publications-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .lesson-content p,
    .publications-content p {
        padding: 1rem;
    }

    .lesson-link,
    .publications-link {
        width: 100%;
        text-align: center;
    }

    .lesson-description,
    .publications-link-description {
        display: none;
    }


}

</style>
