<template>
    <main>
        <Head>
            <title>{{appName}}</title>
        </Head>
        <Navbar></Navbar>
        <article>
            <slot />
        </article>
        <ScrollTop></ScrollTop>
        <Footer></Footer>
    </main>
</template>

<script>
import Navbar from "../Components/Navbar";
import ScrollTop from "../Components/scrollTop";
import Footer from "../Components/Footer";
import { Head } from '@inertiajs/inertia-vue'

export default {
    components: {
        Head,
        Navbar,
        Footer,
        ScrollTop
    },
    metaInfo: {
        meta: [
            { charset: 'utf-8' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' }
        ]
    },
    data() {
        return {
            email: 'mailto:' + this.$page.props.settings.email,
            url: 'img/default/background/home-parallax.jpg'
        }
    },
    computed: {
        appName: function () {
            return this.$page.props.settings.appName;
        },
    }
}
</script>

<style>
p{
    font-size: 16px;
}
</style>
