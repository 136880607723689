<template>

        <div class="number-block">
            <div>
                <h1>{{ data.data_title_1 }}</h1>
                <p>{{ data.data_content_1 }}</p>
            </div>
            <div>
                <h1>{{ data.data_title_2 }}</h1>
                <p>{{ data.data_content_2 }}</p>
            </div>
            <div>
                <h1>{{ data.data_title_3 }}</h1>
                <p>{{ data.data_content_3 }}</p>
            </div>
        </div>

</template>

<script>
export default {
    name: "DataText",
    props: ['data']
}
</script>

<style scoped>
.number-block {
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    width:100%;
    margin: auto;
    padding-bottom: 50px;
}

.number-block > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    text-align: center;
    padding:1rem;
}

.number-block > div h1 {
    white-space: nowrap;
}


@media (max-width: 720px) {
    .number-block {
        flex-direction: column;
        align-items:center;
        padding-bottom:0;
    }

    .number-block > div {
        width:75%;
    }
}


</style>
