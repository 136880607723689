<template>
    <div v-scroll-animation-side class="block">
        <img :src="data.image" alt="">
        <div class="text">
            <h2>{{data.title}}</h2>
            <p>{{data.text}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "ParagrapheAndImage",
    props: ['data'],
}
</script>

<style scoped>
.block {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    margin: 2rem;
}


.block img {
    width: 40%;
    max-width: 450px;
    margin: 1rem;
    box-shadow: rgba(255, 255, 255, 0.08) 0px 10px 15px -3px, rgba(255, 255, 255, 0.08) 0px 4px 6px -2px;
}

.block .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    width: 40%;
}

.block p{
    font-size:18px;
}

@media (max-width: 720px) {

    .block {
        flex-direction: column !important;
        margin-top: 2rem;
    }

    .block img {
        width: 100%;
    }

    .text {
        justify-content: center !important;
        text-align: center !important;
        width: 100% !important;
        margin-top: 1rem;
    }

    .text h2 {
        width: 100%;
        text-align: center !important;
    }

    .text p {
        margin: 1rem;
    }
}

@media (max-width:720px){
    .text p{
        /*text-align: left;*/
    }
}


</style>
