<template>
    <Layout>
        <section class="w-100 p-4">

            <!--     Settings       -->
            <div class="card shadow">
                <div class="card-header">
                    Settings
                </div>

                <form @submit.prevent="submit" method="post">

                    <div class="card-body d-flex flex-column  justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-10 col-sm-12  text-center">
                            <div class="d-flex justify-content-center align-items-center  mt-2 mb-3">
                                <h5 class="card-title m-0">Logo</h5>
                                <ToolTip :data="tooltipLogo"></ToolTip>
                            </div>

                            <div class="input-group mb-3 ">
                                <input type="file"
                                       ref="fileUpload"
                                       @input="form.logo = $event.target.files[0]"
                                       class="form-control" id="inputGroupFile02">
                                <label class="input-group-text" for="inputGroupFile02">Upload</label>
                            </div>
                        </div>

                        <div class="mb-3" v-if="$page.props.errors.logo">
                            <p class="text-danger">{{ $page.props.errors.logo }}</p>
                        </div>

                        <div class="col-lg-6 col-md-10 col-sm-12  text-center">
                            <h5 class="card-title mt-2 mb-3">Reset logo to default</h5>
                            <div class="input-group mb-3 d-flex justify-content-center align-items-center">
                                <div class="form-check form-switch">
                                    <input v-model="form.reset" class="form-check-input" type="checkbox" role="switch"
                                           id="flexSwitchCheckChecked">
                                    <label class="form-check-label" for="flexSwitchCheckChecked"></label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">App Settings</h5>
                                <ToolTip :data="tooltipApp"></ToolTip>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" v-model="form.appName" class="form-control">
                                <label class="d-flex justify-content-between  w-110 "><span class="text-danger">App Name</span><span
                                    :class="[ this.countAppName > 20 ? 'text-danger' : '']">{{
                                        this.countAppName
                                    }} / 20</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.appName">
                                <p class="text-danger">{{ $page.props.errors.appName }}</p>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="email" v-model="form.appEmail" class="form-control">
                                <label class="d-flex justify-content-between  w-110"><span  class="text-danger">App Email</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.appEmail">
                                <p class="text-danger">{{ $page.props.errors.appEmail }}</p>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Footer Contact</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" v-model="form.name" class="form-control">
                                <label class="d-flex justify-content-between  w-110"><span  class="text-danger">Name</span><span
                                    :class="[ this.countName > 50 ? 'text-danger' : '']">{{
                                        this.countName
                                    }} / 50</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.name">
                                <p class="text-danger">{{ $page.props.errors.name }}</p>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="text" v-model="form.faculty" class="form-control">
                                <label class="d-flex justify-content-between  w-110"><span>Faculty</span><span
                                    :class="[ this.countFaculty > 50 ? 'text-danger' : '']">{{
                                        this.countFaculty
                                    }} / 50</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.faculty">
                                <p class="text-danger">{{ $page.props.errors.faculty }}</p>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="text"  v-model="form.address" class="form-control">
                                <label class="d-flex justify-content-between  w-110"><span  class="text-danger">Address</span><span
                                    :class="[ this.countAddress > 75 ? 'text-danger' : '']">{{
                                        this.countAddress
                                    }} / 75</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.address">
                                <p class="text-danger">{{ $page.props.errors.address }}</p>
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="form-floating mb-3 w-30">
                                    <input type="text" v-model="form.postalCode" class="form-control">
                                    <label class="d-flex justify-content-between"><span  class="text-danger">Postal Code</span></label>
                                </div>
                                <div class="form-floating mb-3 w-30">
                                    <input type="text" v-model="form.city" class="form-control">
                                    <label class="d-flex justify-content-between "><span  class="text-danger">City</span></label>
                                </div>
                                <div class="form-floating mb-3 w-30">
                                    <input type="text" v-model="form.country" class="form-control">
                                    <label class="d-flex justify-content-between  w-110"><span  class="text-danger">Country</span></label>
                                </div>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.postalCode">
                                <p class="text-danger">{{ $page.props.errors.postalCode }}</p>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.city">
                                <p class="text-danger">{{ $page.props.errors.city }}</p>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.country">
                                <p class="text-danger">{{ $page.props.errors.country }}</p>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Social Links</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" v-model="form.twitter" class="form-control">
                                <label class="d-flex justify-content-between  w-110"><span>Twitter</span><span
                                    :class="[ this.countTwitter > 75 ? 'text-danger' : '']">{{
                                        this.countTwitter
                                    }} / 75</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.twitter">
                                <p class="text-danger">{{ $page.props.errors.twitter }}</p>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="text" v-model="form.facebook" class="form-control">
                                <label class="d-flex justify-content-between  w-110"><span>Facebook</span><span
                                    :class="[ this.countFacebook > 75 ? 'text-danger' : '']">{{
                                        this.countFacebook
                                    }} / 75</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.facebook">
                                <p class="text-danger">{{ $page.props.errors.facebook }}</p>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="text" v-model="form.instagram" class="form-control">
                                <label class="d-flex justify-content-between  w-110"><span>Instagram</span><span
                                    :class="[ this.countInstagram > 75 ? 'text-danger' : '']">{{
                                        this.countInstagram
                                    }} / 75</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.instagram">
                                <p class="text-danger">{{ $page.props.errors.instagram }}</p>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="text" v-model="form.youtube" class="form-control">
                                <label class="d-flex justify-content-between  w-110"><span>Youtube</span><span
                                    :class="[ this.countYoutube > 75 ? 'text-danger' : '']">{{
                                        this.countYoutube
                                    }} / 75</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.youtube">
                                <p class="text-danger">{{ $page.props.errors.youtube }}</p>
                            </div>

                        </div>

                        <Submit :loading="loading"></Submit>
                    </div>
                </form>



            </div>

            <div class="card shadow mt-5">
                <div class="card-header">
                    Change Profile
                </div>

                <form @submit.prevent="passwordChange" method="post">

                    <div class="card-body d-flex flex-column  justify-content-center align-items-center">


                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">

                            <div class="form-floating mb-3">
                                <input type="text" v-model="account.email" class="form-control">
                                <label class="d-flex justify-content-between  w-110"><span  class="text-danger">Email</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.email">
                                <p class="text-danger">{{ $page.props.errors.email }}</p>
                            </div>


                            <div class="form-floating mb-3">
                                <input type="password" v-model="account.currentPassword" class="form-control">
                                <label class="d-flex justify-content-between  w-110"><span  class="text-danger">Current Password</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.currentPassword">
                                <p class="text-danger">{{ $page.props.errors.currentPassword }}</p>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="password" v-model="account.newPassword" class="form-control">
                                <label class="d-flex justify-content-between  w-110"><span  class="text-danger">New Password</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.newPassword">
                                <p class="text-danger">{{ $page.props.errors.newPassword }}</p>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="password" v-model="account.verifyPassword" class="form-control">
                                <label class="d-flex justify-content-between  w-110"><span  class="text-danger">Verify Password</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.verifyPassword">
                                <p class="text-danger">{{ $page.props.errors.verifyPassword }}</p>
                            </div>

                        </div>


                        <Submit :loading="loading"></Submit>
                    </div>
                </form>
            </div>


        </section>

        <SuccessToaster v-show="showToaster" @handleToaster="handleToaster"></SuccessToaster>
        <ModalError v-show="showModal" @handleModal="handleModal" :style="{ top : scrollPos + 'px' }"></ModalError>


    </Layout>
</template>

<script>
import Layout from "./Shared/Layout";
import Submit from "../../Components/Button/Submit";
import SuccessToaster from "../../Components/Status/SuccessToaster";
import ModalError from "../../Components/Status/ModalError";
import ToolTip from "../../Components/Info/ToolTip";

export default {
    name: "Dashboard",
    props:['appName'],
    components: {
        Layout,
        Submit,
        SuccessToaster,
        ModalError,
        ToolTip
    },
    data() {
        return {
            tooltipLogo: 'Upload new logo, ratio is 4:1, edit native logo for ease.',
            tooltipApp: 'Change name & admin email. !!! If change name app will restart !!!',
            scrollPos:null,
            loading: false,
            showToaster: true,
            showModal: true,
            form: {
                logo: null,
                reset: null,
                name: this.$page.props.settings.name,
                appName: this.appName,
                appEmail: this.$page.props.settings.email,
                faculty: this.$page.props.settings.faculty,
                address: this.$page.props.settings.address,
                postalCode: this.$page.props.settings.postal_code,
                city: this.$page.props.settings.city,
                country: this.$page.props.settings.country,
                twitter: this.$page.props.settings.twitter_link,
                facebook: this.$page.props.settings.facebook_link,
                instagram: this.$page.props.settings.instagram_link,
                youtube: this.$page.props.settings.youtube_link,
            },
            account:{
                currentPassword: null,
                newPassword: null,
                verifyPassword: null,
            }
        }
    },
    computed:{
        error: function () {
            return this.$page.props.errors.maxAttempt;
        },
        countAppName: function () {
            return this.charLeft(this.form.appName)
        },
        countName: function () {
            return this.charLeft(this.form.name)
        },
        countFaculty: function () {
            if(this.form.faculty === null){
                return 0;
            }else{
                return this.charLeft(this.form.faculty)
            }
        },
        countAddress: function () {
            return this.charLeft(this.form.address)
        },
        countPostalCode: function () {
            return this.charLeft(this.form.postalCode)
        },
        countTwitter: function () {
            if(this.form.twitter === null){
                return 0;
            }else{
                return this.charLeft(this.form.twitter)
            }
        },
        countFacebook: function(){
            if(this.form.facebook === null){
                return 0;
            }else{
                return this.charLeft(this.form.facebook)
            }
        },
        countInstagram: function () {
            if(this.form.instagram === null){
                return 0;
            }else{
                return this.charLeft(this.form.instagram)
            }
        },
        countYoutube: function () {
            if(this.form.youtube === null){
                return 0;
            }else{
                return this.charLeft(this.form.youtube)
            }
        },
    },
    watch:{
        error: function(e){
            if(e && this.showModal){
                document.body.style.overflow = 'hidden'
            }else{
                document.body.style.overflow = 'auto'
            }
        },
        showModal: function(e){
            if(!e){
                document.body.style.overflow = 'auto'
            }
        }

    },
    methods: {
        handleToaster(e) {
            this.showToaster = e
        },
        handleModal(e) {
            console.log(e)
            this.showModal = e
        },
        charLeft(e) {
            return e.length;
        },
        submit() {
            this.scrollPos = window.scrollY;
            this.loading = true;
            this.showToaster = true;
            this.showModal = true;
            this.$inertia.post(this.$route('dashboard.store'),
                {
                    logo: this.form.logo,
                    reset: this.form.reset,
                    name: this.form.name,
                    appName: this.form.appName,
                    appEmail: this.form.appEmail,
                    faculty:this.form.faculty,
                    address: this.form.address,
                    postalCode: this.form.postalCode,
                    city: this.form.city,
                    country: this.form.country,
                    twitter: this.form.twitter,
                    facebook: this.form.facebook,
                    instagram: this.form.instagram,
                    youtube: this.form.youtube,
                },
                {
                    preserveScroll: true,
                    onFinish: visit => {
                        this.loading = false;
                        this.form.reset = false;
                        this.$refs.fileUpload.value = '';
                        setTimeout(() => {
                            this.showToaster = false;
                        }, 3000)
                    }
                }
            );
        },passwordChange(){
            this.scrollPos = window.scrollY;
            this.loading = true;
            this.showToaster = true;
            this.showModal = true;
            this.$inertia.post(this.$route('dashboard.password'),this.account,{
                preserveScroll: true,
                onFinish: visit => {
                    this.loading = false;
                    setTimeout(() => {
                        this.showToaster = false;
                    }, 3000)
                },
                onSuccess: visit => {
                    this.loading = false;
                    this.account.email = null;
                    this.account.currentPassword = null;
                    this.account.newPassword = null;
                    this.account.verifyPassword = null;
                    setTimeout(() => {
                        this.showToaster = false;
                    }, 3000)
                }
            })
        }

    }
}
</script>

<style scoped>
.w-30{
    width:30%;
}

.w-110{
    width:110%;
}
</style>
