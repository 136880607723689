<template>
    <section class="w-100 p-4" >
        <nav class="navbar navbar-expand-lg navbar-light bg-light position-relative">
            <div class="container-fluid">
                <Logo class="logo "></Logo>

                <ul class="full-menu d-flex justify-content-evenly align-items-center m-0 list-unstyled w-75">
                    <li>
                        <Link :href="this.$route('dashboard')">Dashboard</Link>
                    </li>
                    <li>
                        <Link :href="this.$route('page.management')">Page</Link>
                    </li>

                    <div class="position-relative btn-partners show-team" @mouseover="handleShowTeam" @mouseleave="showTeam = false">
                        Team

                        <ul ref="showPartners" v-show="showTeam" class="shadow bg-light show-team-menu position-absolute">
                            <li class="list-unstyled">
                                <Link :href="this.$route('team.management')">Team Management</Link>
                            </li>
                            <li class="list-unstyled">
                                <Link :href="this.$route('lesson.management')">Lesson Management</Link>
                            </li>
                        </ul>
                    </div>

                    <li>
                        <Link :href="this.$route('project.management')">Project</Link>
                    </li>

                    <li class="list-unstyled">
                        <Link :href="this.$route('publications.management')">Publications</Link>
                    </li>

                    <div class="position-relative btn-partners show-partners" @mouseover="handleShowPartners" @mouseleave="showPartners = false">
                        Partners

                            <ul ref="showPartners" v-show="showPartners" class="shadow bg-light show-partners-menu position-absolute">
                                <li class="list-unstyled">
                                    <Link :href="this.$route('partners.management')">Partners</Link>
                                </li>
                                <li class="list-unstyled">
                                    <Link :href="this.$route('funders.management')">Funding</Link>
                                </li>
                            </ul>
                    </div>
                    <li>
                        <Link :href="this.$route('oppo.management')">Oppo</Link>
                    </li>
                    <li>
                        <Link :href="this.$route('logout')">Logout</Link>
                    </li>
                </ul>


                <button @click="showModal = !showModal" class="navbar-toggler" type="button">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div v-show="showModal" class="menu-modal bg-light">
                    <ul class="text-center list-unstyled m-0">
                        <li class="m-4">
                            <Link :href="this.$route('dashboard')">Dashboard</Link>
                        </li>
                        <li class="m-4">
                            <Link :href="this.$route('page.management')">Page</Link>
                        </li>
                        <li class="m-4">
                            <Link :href="this.$route('team.management')">Team</Link>
                        </li>
                        <li class="m-4">
                            <Link :href="this.$route('lesson.management')">Lesson</Link>
                        </li>
                        <li class="m-4">
                            <Link :href="this.$route('project.management')">Project</Link>
                        </li>
                        <li class="m-4">
                            <Link :href="this.$route('publications.management')">Publications</Link>
                        </li>
                        <li class="m-4">
                            <Link :href="this.$route('partners.management')">Partners</Link>
                        </li>
                        <li class="m-4">
                            <Link :href="this.$route('funders.management')">Funding</Link>
                        </li>
                        <li class="m-4">
                            <Link :href="this.$route('logout')">Logout</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </section>
</template>

<script>
import {Link} from '@inertiajs/inertia-vue';
import Logo from "../../../Components/Logo";

export default {
    name: "Navbar",
    components: {
        Link,
        Logo
    },
    data() {
        return {
            showModal: false,
            showTeam:false,
            showPartners: false,
        }
    },
    methods: {
        handleShowTeam(e) {
            if(e.target.classList.contains('show-team') || e.target.closest('div').classList.contains('show-team') ){
                return this.showTeam = true;
            }
        },
        handleShowPartners(e) {
            if(e.target.classList.contains('show-partners') || e.target.closest('div').classList.contains('show-partners') ){
                return this.showPartners = true;
            }
        },

    }
}
</script>

<style scoped>
.navbar {
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .1), 0 2px 4px rgba(0, 0, 0, .1);
    padding-top: 0.5625rem;
}

.logo {
    height: 75px;
    width: 25%;
}

.menu-modal {
    position: absolute;
    left: 0;
    padding: 20px;
    top: 95px;
    background: white;
    width: 100%;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, .1), 0 0 4px rgba(0, 0, 0, .1);
    z-index:99;
}

.show-team,
.show-partners{
    color:#448aff ;
    text-decoration: underline;
    cursor:pointer;
}

.show-team-menu{
    height:100px;
    width:175px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
    left:-70px;
    z-index:99;
}



.show-partners-menu{
    height:100px;
    width:100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
    left:-17.5px;
    z-index:99;
}


@media (max-width: 1200px) {


    .full-menu {
        display: none !important;
    }

    .navbar-toggler{
        display:block;
    }
}
</style>
