<template>
    <section class="footer d-flex flex-column justify-content-between align-items-center">
        <div class="home-parallax" :style='{ backgroundImage: `url(${url})` }'>
        </div>
        <div class="container">
            <div class="info row mb-4 d-flex justify-content-around align-items-center w-100">
                <div class="block col-lg-4 col-md-4 col-sm-4 col-12">
                    <h4 class="mb-2">Contact</h4>
                    <p>{{ footer.name }}</p>
                    <p>{{ footer.faculty }}</p>
                    <p>{{ footer.address }}</p>
                    <p>{{ footer.postal_code }}, {{ footer.city }}</p>
                    <p>{{ footer.country }}</p>
                    <p class="email"><a :href="email">Email</a></p>
                </div>
                <div v-if="footer.twitter_link || footer.facebook_link || footer.instagram_link || footer.youtube_link"
                     class="block col-lg-4 col-md-4 col-sm-4 col-12 social-block">
                    <h4 class="mb-2">Social</h4>
                    <div>
                        <div>
                            <a v-if="footer.twitter_link && footer.twitter_link != null" :href="footer.twitter_link"
                               target="_blank">
                                <svg fill="#0A9396" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="40px"
                                     height="40px">
                                    <path
                                        d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z"/>
                                </svg>
                            </a>
                            <a v-if="footer.facebook_link && footer.facebook_link != null" :href="footer.facebook_link"
                               target="_blank">
                                <svg fill="#0A9396" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40px"
                                     height="40px">
                                    <path
                                        d="M12,2C6.477,2,2,6.477,2,12c0,5.395,4.275,9.78,9.621,9.981v-6.942H9.278v-2.725h2.343v-2.005 c0-2.324,1.421-3.591,3.495-3.591c0.699-0.002,1.397,0.034,2.092,0.105v2.43h-1.428c-1.13,0-1.35,0.534-1.35,1.322v1.735h2.7 l-0.351,2.725h-2.365v6.659C18.768,20.613,22,16.689,22,12C22,6.477,17.523,2,12,2z"/>
                                </svg>
                            </a>
                        </div>
                        <div>
                            <a v-if="footer.instagram_link && footer.instagram_link != null"
                               :href="footer.instagram_link" target="_blank">
                                <svg fill="#0A9396" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="40px"
                                     height="40px">
                                    <path
                                        d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z"/>
                                </svg>
                            </a>
                            <a v-if="footer.youtube_link && footer.youtube_link != null" :href="footer.youtube_link"
                               target="_blank">
                                <svg fill="#0A9396" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="40px"
                                     height="40px">
                                    <path
                                        d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z"/>
                                </svg>
                            </a>
                        </div>
                    </div>

                </div>
                <div class="links block col-lg-4 col-md-4  col-sm-4  col-12">
                    <h4 class="mb-2">Links</h4>
                    <a v-for="(item,index) in footer.random_partner_link" :href="item.link"
                       target="_blank">{{ item.name }}</a>

                </div>
            </div>


        </div>
        <div class="copy d-flex flex-column justify-content-center align-items-center">
            <div class="container d-flex justify-content-center align-items-center w-100 ">

                <a class="col-4 d-flex justify-content-center" href="https://koosk.dev">by Koosk</a>
                <Link  class="col-4 d-flex justify-content-center" href="/privacy">Privacy Policy</Link>
                <p  class="col-4 d-flex justify-content-center text-nowrap" >© {{ footer.appName }}</p>

            </div>
        </div>
    </section>
</template>

<script>
import {Link} from '@inertiajs/inertia-vue'

export default {
    name: "PageFooter",
    components: {
        Link
    },
    data() {
        return {
            isModalOpen: false,
            email: 'mailto:' + this.$page.props.settings.email,
            url: 'img/default/background/home-parallax.jpg'
        }
    },
    computed: {
        footer: function () {
            return this.$page.props.settings;
        },
    },
    methods: {
        handleModal(e) {
            this.isModalOpen = e;
        },
        openModal() {
            this.isModalOpen = true;
        }
    }
}
</script>

<style scoped>

.privacy {
    margin: 0 25px 0 25px;
}

.footer {
    overflow: hidden;
    position: relative;
    background-color: rgb(0, 22, 25);
    color: whitesmoke;
    min-height: 300px;
}

p {
    margin-bottom: 0;
}

a {
    text-decoration: none;
    font-size: 16px;
    color: #0A9396;
    cursor: pointer;
}

a:hover {
    color: #94D2BD;
}

svg:hover {
    fill: #94D2BD;
}


.home-parallax {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: .1;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    transform: translateZ(1px) scale(1.09);
    -webkit-transform: translateZ(1px) scale(1.09);
}

.info {
    padding-top: 40px;
    margin: auto;
}

.block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 175px;
    max-width: 250px;
    text-align: center;
    z-index: 9;
}

.copy {
    background-color: rgb(0, 5, 25);
    height: 40px;
    width: 100%;
    z-index: 9;
}

.email a {
    font-size: 18px;
}

@media (max-width: 575px) {

    .footer {
        min-height: 450px;
    }

    .links {
        display: none;
    }

    .info {
        height: 350px;
        margin: 0;
    }

    .social-block {
        margin-top: 1rem;
    }
}

@media (max-width: 420px) {
    .copy {
        font-size: 14px;
    }
}
</style>
