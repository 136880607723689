<template>
    <Layout>
        <TopImg :url="headerImage" :pageTitle="coopData.page_title" :pageDescription="coopData.page_description"></TopImg>
        <section class="page-section">

            <Parallax :background="background"></Parallax>

            <div class="searchbar-content">

                <div class="searchbar">

                    <div class="keywords">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                             fill="white">
                            <path d="M0 0h24v24H0V0z" fill="none"/>
                            <path
                                d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
                        </svg>
                        <label>
                            <input placeholder="Search..." v-model="searchInput">
                        </label>
                    </div>

                    <div class="thema">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                             fill="white">
                            <path d="M0 0h24v24H0V0z" fill="none"/>
                            <path
                                d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
                        </svg>
                        <label>
                            <select name="show-project" v-model="showProject" id="show_project">
                                <option selected value="current">Current Project</option>
                                <option value="past">Past Project</option>
                            </select>
                        </label>
                    </div>
                    <div class="year">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                             fill="white">
                            <path d="M0 0h24v24H0V0z" fill="none"/>
                            <path
                                d="M7 11h2v2H7v-2zm14-5v14c0 1.1-.9 2-2 2H5c-1.11 0-2-.9-2-2l.01-14c0-1.1.88-2 1.99-2h1V2h2v2h8V2h2v2h1c1.1 0 2 .9 2 2zM5 8h14V6H5v2zm14 12V10H5v10h14zm-4-7h2v-2h-2v2zm-4 0h2v-2h-2v2z"/>
                        </svg>
                        <select v-model="selectedYear">
                            <option v-for="(item,index) in orderedYear" :value="item">{{ item }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div v-show="showProject === 'current'" id="active_project" class="project">
                <h1>Active Project</h1>
                <div class="main-content row">
                    <div v-for="(item,index) in filteredList" :key="index"
                         v-show="handleShowCurrent(item.status,item.date)"
                         class="box col-lg-3 col-md-3 col-sm-4 col-6">
                        <div class="img-box">
                            <img :src="item.image" alt="">
                        </div>
                        <div>
                            <h3>{{ item.name }}</h3>
                            <h6>{{ item.date }}</h6>
                        </div>
                        <div>
                            <p>{{ item.description.slice(0, 150) + '...' }}</p>
                        </div>
                        <button class="btn btn-light" @click="openModal(item.id)">Read More</button>
                    </div>
                </div>
            </div>

            <div v-show="showProject === 'past'" id="past_project" class="project">
                <h1>Past Project</h1>
                <div class="main-content row">
                    <div v-for="(item,index) in filteredList" :key="index"
                         v-show="handleShowPast(item.status,item.date)"
                         class="box col-lg-3 col-md-3 col-sm-4 col-6">
                        <div class="img-box">
                            <img :src="item.image" alt="">
                        </div>
                        <div>
                            <h3>{{ item.name }}</h3>
                            <h6>{{ item.date }}</h6>
                        </div>
                        <div>
                            <p>{{ item.description.slice(0, 150) + '...' }}</p>
                        </div>
                        <button class="btn btn-light" @click="openModal(item.id)">Read More</button>
                    </div>
                </div>
            </div>

        </section>

        <CoopModal :isModalOpen="isModalOpen" :selectedContent="selectedContent"
                   @handleModalClose="handleModalClose"></CoopModal>

    </Layout>
</template>

<script>
import Layout from "../Shared/Layout";
import TopImg from "../Components/TopImg";
import Parallax from "../Components/Parallax";
import TopTitle from "../Components/TopTitle";
import CoopModal from "../Components/Project/CoopModal";

export default {
    name: "Coop",
    components: {
        Layout,
        TopImg,
        Parallax,
        TopTitle,
        CoopModal,
    },
    props:['coopData','headerImage','allProject','orderedYear'],
    data() {
        return {
            background: 'img/default/background/home-parallax.jpg',
            isModalOpen: false,
            selectedContent: null,
            searchInput: '',
            showProject: 'current',
            selectedYear: 'All',
        }
    },
    mounted() {
        this.$nextTick(() => {
            if(window.location.href.indexOf('=') >0){
                this.searchInput =  window.location.href.slice(window.location.href.indexOf('=')+1,window.location.href.length).split('%20').join(' ');
                this.searchInput = this.searchInput.slice(0,-1);
                setTimeout(()=>{
                    window.history.replaceState(
                        null,
                        null,
                        this.$route('coop')
                    )
                },50)

            }
        })
    },
    methods: {
        openModal(index) {
            this.isModalOpen = true;
            this.selectedContent = this.allProject.filter(item=>{
                if(item.id === index){
                    return item;
                }
            });
            this.selectedContent = this.selectedContent[0];
        },
        handleModalClose() {
            this.isModalOpen = false;
        },
        handleShowCurrent(status, date) {
            if (status) {
                return date.slice(-4) === this.selectedYear || this.selectedYear === 'All';
            } else {
                return false;
            }
        },
        handleShowPast(status, date) {
            if (!status) {
                return date.slice(-4) === this.selectedYear || this.selectedYear === 'All';
            } else {
                return false;
            }
        }
    },
    computed: {
        filteredList() {
            return this.allProject.filter(item => {
                return item.name.toLowerCase().includes(this.searchInput.toLowerCase())
                    || JSON.stringify(Array(item.collaborators)).toLowerCase().includes(this.searchInput.toLowerCase())
                    || JSON.stringify(Array(item.members)).toLowerCase().includes(this.searchInput.toLowerCase())
                    || item.description.toLowerCase().includes(this.searchInput.toLowerCase())
            })
        }
    },
}
</script>

<style scoped>
/* Parallax page style @ css/page-parallax.css*/
.main-content {
    position: relative;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    margin: 0 auto;
    min-height:500px;
}

.row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.project {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
}

.project h1 {
    margin-bottom: 40px;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    width: 300px;
    height: 500px;
    margin: 1rem;
    padding: 0 10px 10px;
    background-color: rgba(26, 32, 44, 0.6);
    box-shadow: 2px 2px 2px 1px rgba(255, 255, 255, .1);
}

.img-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
}

.box img {
    width: 100%;
}

.box button {
    margin: 10px 0;
}

.searchbar-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 75px 0 30px;
}

.searchbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 900px;
    z-index: 9;
}

.keywords svg,
.thema svg,
.year svg {
    margin-right: 1rem;
}

.searchbar input,
.searchbar select {
    padding: 10px;
    width: 150px;
    border-radius: 5px;
    border: none;
    color: white;
    background-color: rgba(10, 147, 150, 0.7);
    z-index: 999;
}

.searchbar input:focus {
    text-decoration: none;
    outline: none;
}

.searchbar input::placeholder {
    color: rgba(240, 240, 240, .7);
}

@media (max-width: 663px) {
    .searchbar {
        flex-direction: column;
    }

    .searchbar div {
        margin-top: 1rem;
    }
}

</style>
