<template>
    <Layout>
        <!--        <Navbar :closeMenu="closeMenu" @isMenuClosed="isMenuClosed"></Navbar>-->
        <Slider :headerImage="headerImage"></Slider>
        <SliderText :data="homepageData" @handleCloseMenu="handleCloseMenu"></SliderText>

        <section id="presentation" class="presentation">
            <Parallax :background="background"></Parallax>
            <div class="container d-flex flex-column justify-content-around align-items-center">

                <ParagraphBlockLanding :data="paragraphBlock1" v-scrollAnimationUp></ParagraphBlockLanding>

                <PublicationsCarousel v-scrollAnimationUp :projects="allPublications" :route="'publications'"></PublicationsCarousel>

                <div v-scrollAnimationUp class="fire-map text-center mt-5 mb-5">
                    <h1>{{homepageData.map_title}}</h1>
                    <GoogleMap :description="homepageData.map_description" :mapUrl="homepageData.map_url"></GoogleMap>
                </div>

                <ParagraphBlockLanding :data="paragraphBlock2" v-scrollAnimationUp ></ParagraphBlockLanding>

                <ProjectsCarousel v-scrollAnimationUp :projects="allProjects" :route="'coop'"></ProjectsCarousel>

                <ParagraphBlockLanding :data="paragraphBlock3" v-scrollAnimationUp></ParagraphBlockLanding>

            </div>

            <div v-scrollAnimationUp id="owl_carousel">
                <Owlcarousel :autoplay="true" :margin="10" :responsive="{0:{items:2},720:{items:3},920:{items:5}}"
                             :nav="true" :dots="false" :loop="true"
                             :autoplayTimeout="2750">

                    <a v-for="(item,index) in partnersContent" :key="index" :href="item.link" target="_blank"><img :src="item.image" alt=""></a>

                </Owlcarousel>

            </div>

        </section>
    </Layout>
</template>

<script>
import Layout from "../Shared/Layout";
import Slider from "../Components/Home/Slider";
import SliderText from "../Components/Home/SliderText";
import Parallax from "../Components/Parallax";
import ParagraphBlockLanding from "../Components/Text/ParagraphBlockLanding";
import PublicationsCarousel from "../Components/Home/Carousel";
import ProjectsCarousel from "../Components/Home/Carousel";
import Owlcarousel from "vue-owl-carousel";
import GoogleMap from "../Components/GoogleMap";


export default {
    name: "Home",
    props:['homepageData','headerImage','allPublications','allProjects','partnersContent'],
    components: {
        Layout,
        Slider,
        SliderText,
        Parallax,
        ParagraphBlockLanding,
        PublicationsCarousel,
        ProjectsCarousel,
        Owlcarousel,
        GoogleMap,
    },
    data() {
        return {
            background: 'img/default/background/home-parallax.jpg',
            closeMenu: false,
            paragraphBlock1:{
                block_title: this.homepageData.block_title_1,
                block_content: this.homepageData.block_content_1
            },
            paragraphBlock2:{
                block_title: this.homepageData.block_title_2,
                block_content: this.homepageData.block_content_2
            },
            paragraphBlock3:{
                block_title: this.homepageData.block_title_3,
                block_content: this.homepageData.block_content_3
            },
        }
    },
    created() {
    },
    methods: {
        handleCloseMenu() {
            this.closeMenu = true;
        },
        isMenuClosed() {
            this.closeMenu = false;
        }
    }
}
</script>

<style>

body {
    overflow-x: hidden;
}

.fire-map{
    padding-top:150px;
}

.fire-map h1{
    margin-bottom: 1rem;
}

.container-xxl {
    padding: 0;
}

@media (min-width: 1400px) {
    .container-xxl {
        max-width: 1600px !important;
    }
}

@media(max-width:920px){
    .map-text{
        margin-top:50px;
    }
}
@media(max-width:720px){
    .fire-map{
        padding-top:75px;
    }
}

</style>

<style scoped>
section.presentation {
    background-color: rgb(25, 22, 22);
    color: white;
    position: relative;
    overflow: hidden;

}

.container {
    height: 100%;
}

/*Id use if else , gets override*/
#owl_carousel {
    max-width: 800px;
    margin: auto;
    padding-bottom: 100px;
}


</style>

