<template>
    <div>
        <div id="scroll_box" @click="scrollTop" class="scrolltop-block">
            <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 24 24" width="40px" fill="white">
                <path d="M0 0h24v24H0V0z" fill="none"/>
                <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6 1.41 1.41z"/>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: "scrollTop",

    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        scrollTop() {
            window.scrollTo({top: 0, behavior: 'smooth'});
        },
        handleScroll() {
            if(scrollY > innerHeight/3 ){
                document.querySelector('#scroll_box').classList.add('translate-down')
            }else{
                document.querySelector('#scroll_box').classList.remove('translate-down')
            }
        },

    },
}
</script>

<style scoped>

.scrolltop-block {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    bottom: 50px;
    right: 50px;
    cursor: pointer;
    border-radius: 5px;
    background: rgb(7, 138, 166);
    background: linear-gradient(322deg, rgba(7, 138, 166, 1) 0%, rgba(15, 142, 144, 1) 36%, rgba(17, 149, 148, 1) 53%, rgba(63, 164, 135, 1) 69%, rgba(63, 164, 94, 1) 100%, rgba(224, 190, 60, 1) 100%);
    transform: translateY(-100vh);
    opacity: 0;
    z-index: 99;
    transition: .5s ease;
}

.translate-down{
    opacity:1;
    transform: translateY(0vh);
}


</style>
