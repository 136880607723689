<template>
    <div>
        <NavbarFull id="nav_full" v-show="!mobileView"></NavbarFull>
        <NavbarRes id="nav_res" @isMenuClosed="isMenuClosed" :closeMenu="closeMenu" v-show="mobileView"></NavbarRes>
    </div>
</template>

<script>
import NavbarFull from "./Navbar/NavbarFull";
import NavbarRes from "./Navbar/NavbarRes";
export default {
    name: "Navbar",
    components:{
        NavbarFull,
        NavbarRes
    },
    props:["closeMenu"],
    data(){
        return{
            mobileView : false,
        }
    },
    methods:{
        handleView(){
            this.mobileView = window.innerWidth < 920;
        },
        isMenuClosed(e){
            this.$emit('isMenuClosed',e)
        }

    },
    mounted(){
        this.handleView();
        addEventListener('resize',function(){
            if(window.innerWidth < 920){
                document.querySelector('#nav_full').style.display = 'none';
                document.querySelector('#nav_res').style.display = 'block';
            }else{
                document.querySelector('#nav_full').style.display = 'block';
                document.querySelector('#nav_res').style.display = 'none';
            }
        })
    }
}
</script>

<style scoped>


</style>
