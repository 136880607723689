<template>
    <div ref="modal" @click="closeModal" class="modal">
        <div v-if="selectedContent != null" class="box">
            <p @click="closeModal" class="close-btn close-modal">X</p>
            <div  class="inner-box d-flex flex-column justify-content-evenly align-items-start">
                <h1>{{ selectedContent.title }}</h1>
                <div class="dates" >
                    <h3 v-if="selectedContent.start">Start: {{selectedContent.start}}</h3> <h3 class="mt-2" v-if="selectedContent.end"> End: {{selectedContent.end}}</h3>
                </div>
                <p class="min-height-100px">{{selectedContent.description}}</p>
                <div class="d-flex justify-content-end w-100" v-if="selectedContent.link">
                    <a :href="selectedContent.link" target="_blank" class="btn btn-dark">More info</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Link} from '@inertiajs/inertia-vue'

export default {
    name: "modalOppo",
    props:['isModalOpen','selectedContent'],
    components: {
        Link
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    methods: {
        closeModal(e) {
            if (e.target.classList.contains('modal') || e.target.classList.contains('close-btn')) {
                this.$emit('handleModal', false);
            }
        },
        handleScroll() {
            if(this.$refs.modal) {
                this.$refs.modal.style.top = window.scrollY + 'px';
            }
        },
        handleOverflow(){
            document.querySelector('body').style.overflowY = "auto";
        }

    },
    watch: {
        isModalOpen: function (e) {
            if (e) {
                document.querySelector('body').style.overflowY = "hidden";
            } else {
                document.querySelector('body').style.overflowY = "auto";
            }
        },
    }
}
</script>

<style scoped>
.modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow:auto;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
}

.box {
    width: 600px;
    min-height: 500px;
    background-color: rgba(245, 245, 245, 1);
    color: black;
    position: relative;
    box-shadow: 5px 4px 2px rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
}

.inner-box {
    width: 75%;
    min-height: 400px;
}

.close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    color: black;
}

.dates{
    display:flex;
    flex-direction: column;
}

.min-height-100px{
    min-height:100px;
}

@media(max-width: 520px){
    .dates{
        flex-direction: column;
    }
}
</style>
