<template>
    <Layout>
        <section class="w-100 p-4">

            <form @submit.prevent="submit" class="card shadow mb-4">
                <div class="card-header">
                    <Link :href="this.$route('project.management')">Project Management</Link>
                    // Add project
                </div>

                <div class="card-body d-flex flex-column  justify-content-center align-items-center">
                    <div class="col-lg-6  mt-2 pb-3 text-center position-relative">
                        <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                            <h5 class="card-title m-0">Profil Image</h5>
                            <ToolTip class="p-2" :data="tooltipProject"></ToolTip>
                        </div>
                        <div class="input-group mb-3 ">
                            <input type="file"
                                   ref="projectImageUpload"
                                   @input="form.image = $event.target.files[0]"
                                   class="form-control">
                        </div>
                        <div v-if="$page.props.errors.image" class="position-absolute bottom-0 w-100">
                            <p class="text-danger">{{ $page.props.errors.image }}</p>
                        </div>
                    </div>

                    <div class="col-12 row">
                        <div class=" col-6 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Project Name</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="form.name">
                                <label class="d-flex justify-content-between  w-110"><span>Name</span><span
                                    :class="[ this.charLeftProjectName > 25 ? 'text-danger' : '']">{{
                                        this.charLeftProjectName
                                    }} / 25</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.name">
                                <p class="text-danger">{{ $page.props.errors.name }}</p>
                            </div>
                            <div class="d-flex row col-12">
                                <div class=" col-6 mt-2 text-center position-relative">
                                    <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                        <h5 class="card-title m-0">Project start date</h5>
                                    </div>

                                    <div class="form-floating mb-3">
                                        <input type="date" class="form-control" v-model="form.date">
                                        <label class="d-flex justify-content-between  w-110"><span>Date</span></label>
                                    </div>
                                    <div class="mb-3" v-if="$page.props.errors.date">
                                        <p class="text-danger">{{ $page.props.errors.date }}</p>
                                    </div>
                                </div>

                                <div class=" col-6 mt-2 text-center position-relative">
                                    <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                        <h5 class="card-title m-0">Project status</h5>
                                    </div>

                                    <div class="form-floating mb-3">
                                        <select v-model="form.status">
                                            <option value="1">Active</option>
                                            <option value="0">Finished</option>
                                        </select>
                                    </div>
                                    <div class="mb-3" v-if="$page.props.errors.status">
                                        <p class="text-danger">{{ $page.props.errors.status }}</p>
                                    </div>
                                </div>

                            </div>

                        </div>


                        <div class=" col-6 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Description</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="form.description"
                                          style="height: 300px"></textarea>
                                <label class="d-flex justify-content-between  w-110"><span>Description</span><span
                                    :class="[ this.charLeftProjectDescription > 1000 ? 'text-danger' : '']">{{
                                        this.charLeftProjectDescription
                                    }} / 1000</span></label>
                            </div>

                            <div class="mb-3" v-if="$page.props.errors.description">
                                <p class="text-danger">{{ $page.props.errors.description }}</p>
                            </div>
                        </div>

                    </div>


                    <div class="col-12 d-flex justify-content-evenly align-items-center col-12 mb-4">
                        <div class="col-5">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Add Partner</h5>
                                <ToolTip class="p-2" :data="tooltipPartner"></ToolTip>
                            </div>
                            <div class="form-floating mb-3 d-flex justify-content-start position-relative">
                                <input type="text" class="form-control w-75" v-model="searchPartner">
                                <label class="d-flex justify-content-between  w-85 "><span>Search</span><span>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                         width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path
                                        d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/></svg>
                                </span></label>
                                <div class="d-flex justify-content-center align-items-center ms-5" @click="showSearchPartnerModal= false; searchPartner = null;"><span class="btn btn-info text-white">X</span></div>

                                <div v-show="showSearchPartnerModal">
                                    <div
                                        class="search-modal d-flex flex-column justify-content-start align-items-center">
                                    <span class="w-100 search-element" v-for="(item,index) in searchPartnerResult"
                                          @click="addPartners(item.id,item.name)">
                                        <p class="m-2 "
                                        >{{ item.name }}</p>
                                    </span>
                                    </div>
                                </div>
                            </div>


                        </div>


                        <div class="col-5">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3 pt-3">
                                <h5 class="card-title m-0">Partner</h5>
                            </div>


                            <div class="recap-author">
                                <div v-for="(item,index) in form.partners" @click="removePartners(item.id)"
                                     class="selected-author bg-info">
                                    <div class="d-flex justify-content-evenly align-items-center p-2 text-white cursor">
                                        <span>#{{ item.id }}</span><span class="ms-1 me-1">-</span>
                                        <p>{{ item.name }}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 d-flex justify-content-evenly align-items-center col-12 mb-4">
                        <div class="col-5">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Add Teacher</h5>
                                <ToolTip class="p-2" :data="tooltipMember"></ToolTip>
                            </div>
                            <div class="form-floating mb-3 d-flex justify-content-start position-relative">
                                <input type="text" class="form-control w-75" v-model="searchMember">
                                <label class="d-flex justify-content-between  w-85 "><span>Search</span><span>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                         width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path
                                        d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/></svg>
                                </span></label>
                                <div class="d-flex justify-content-center align-items-center ms-5" @click="showSearchMemberModal= false; searchMember = null;"><span class="btn btn-info text-white">X</span></div>

                                <div v-show="showSearchMemberModal">
                                    <div
                                        class="search-modal d-flex flex-column justify-content-start align-items-center">
                                    <span class="w-100 search-element" v-for="(item,index) in searchMemberResult"
                                          @click="addMembers(item.id,item.name)">
                                        <p class="m-2 "
                                        >{{ item.name }}</p>
                                    </span>
                                    </div>
                                </div>
                            </div>


                        </div>


                        <div class="col-5">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3 pt-3">
                                <h5 class="card-title m-0">Members</h5>
                            </div>


                            <div class="recap-author">
                                <div v-for="(item,index) in form.members" @click="removeMembers(item.id)"
                                     class="selected-author bg-info">
                                    <div class="d-flex justify-content-evenly align-items-center p-2 text-white cursor">
                                        <span>#{{ item.id }}</span><span class="ms-1 me-1">-</span>
                                        <p>{{ item.name }}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <Submit :loading="loading"></Submit>
                </div>

            </form>
        </section>



    </Layout>
</template>

<script>
import Layout from "./Shared/Layout";
import Submit from "../../Components/Button/Submit";
import ToolTip from "../../Components/Info/ToolTip";
import {Link} from '@inertiajs/inertia-vue'

export default {
    name: "AddProject",
    props:['allPartners','allMembers'],
    components: {
        Layout,
        Submit,
        ToolTip,
        Link
    },
    data() {
        return {
            loading: false,
            tooltipProject: 'Change project image. Dimension ratio for image must be 3:2, only .jpg .jpeg or .png . Max weight 1mb.',
            tooltipPartner: 'Search for partners !!! Partners must exist in db in order to be shown !!!',
            tooltipMember: 'Search for members !!! Member must exist in db in order to be shown !!!',
            searchPartner: null,
            searchMember: null,
            showSearchPartnerModal: false,
            showSearchMemberModal: false,
            form: {
                image: null,
                name: '',
                date: null,
                status: 1,
                description: '',
                partners: [],
                members: []
            },
        }
    },
    computed: {
        charLeftProjectName() {
            return this.charLeft(this.form.name)
        },
        charLeftProjectDescription() {
            return this.charLeft(this.form.description)
        },
        searchPartnerResult() {
            if (this.searchPartner !== null) {
                this.showSearchPartnerModal = true;
                return this.allPartners.filter(item => {
                    return item.name.toLowerCase().includes(this.searchPartner.toLowerCase());
                })
            } else {
                this.showSearchPartnerModal = false;
            }
        },
        searchMemberResult() {
            if (this.searchMember !== null) {
                this.showSearchMemberModal = true;
                return this.allMembers.filter(item => {
                    return item.name.toLowerCase().includes(this.searchMember.toLowerCase());
                })
            } else {
                this.showSearchMemberModal = false;
            }
        }
    },
    methods: {
        charLeft(e) {
            return e.length;
        },
        addPartners(id, name) {
            let value = {
                id: id,
                name: name,
            };
            let array = [];

            Object.keys(this.form.partners).forEach(key => {
                array.push(this.form.partners[key].id)
            })

            if (!array.includes(id) || array.length === 0) {
                this.form.partners.push(value);
                this.searchPartner = null;
                this.showSearchPartnerModal = false;
            }
        },
        addMembers(id, name) {
            let value = {
                id: id,
                name: name,
            };
            let array = [];

            Object.keys(this.form.members).forEach(key => {
                array.push(this.form.members[key].id)
            })

            if (!array.includes(id) || array.length === 0) {
                this.form.members.push(value);
                this.searchMember = null;
                this.showSearchMemberModal = false;
            }
        },
        removePartners(e) {
            Object.keys(this.form.partners).forEach(key => {
                if (this.form.partners[key].id === e) {
                    this.form.partners.splice(key, 1);
                }
            })
        },
        removeMembers(e) {
            Object.keys(this.form.members).forEach(key => {
                if (this.form.members[key].id === e) {
                    this.form.members.splice(key, 1);
                }
            })
        },
        submit() {
            this.loading = true;
            this.$inertia.post(this.$route('project.add'), this.form,
                {
                    preserveScroll: true,
                    onError: visit => {
                        this.loading = false;
                    },
                    onFinish: visit => {
                        this.loading = false;
                    }
                }
            )
        }
    }
}
</script>

<style scoped>
.w-110 {
    width: 110%;
}

.w-85 {
    width: 85%;
}

.recap-author {
    width: 100%;
    height: 300px;
    background-color: #f8fafc;
    border: 1px solid #ced4da;
    border-radius: 5px;
}

.search-modal {
    position: absolute;
    top: 55px;
    width: 75%;
    left: 0;
    background-color: #f8fafc;
    border: 1px solid #ced4da;
    border-radius: 5px;
    z-index:9;
}

.search-element {
    cursor: pointer;
}

.search-element:hover {
    background-color: #ced4da;
}

.selected-author {
    display: inline-flex;
    min-width: 75px;
    height: 30px;
    border-radius: 5px;
    margin: 1rem;
}

.cursor {
    cursor: pointer;
}
</style>
