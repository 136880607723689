<template>
    <section>
        <nav id="res-nav" class="d-flex align-items-center">
            <div class="d-flex justify-content-between align-items-center w-100 position-relative zinde">
                <div class="w-25 hamburger-menu d-flex justify-content-center align-items-center">
                    <div class="nav-icon1" @click="toggleMenu">
                        <span class="line1"></span>
                        <span class="line2"></span>
                        <span class="line3"></span>
                    </div>
                </div>
                <div class="w-50 d-flex justify-content-center align-items-end pre-logo">
                    <Logo class="logo"></Logo>
                </div>
            </div>
        </nav>
        <ul :class="{'open': showMenu }"
            class="res-menu">
            <div id="res_lab" class="d-flex justify-content-center align-items-center position-relative">
                                <span @click="showLab = !showLab ; showProject = false; showPartners = false ; "
                                      :class="{ 'arrow-rotate' : showLab  , 'opacity1': showLab }"
                                      class="menu-arrow position-absolute">
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="white"><path
    d="M0 0h24v24H0V0z" fill="none"/><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"/></svg>
                </span>
                <span v-show="!showLab" @click="showLab = !showLab ; showProject = false; showPartners = false; ">The Lab</span>
                <div v-show="showLab" class="list-unstyled justify-content-center align-items-center">
                    <span class="text-decoration-underline me-2"><Link href="/teaching">Teaching</Link></span>
                    <span class="text-decoration-underline ms-2"><Link href="/team">Team</Link></span>
                </div>
            </div>
            <div id="res_project" class="d-flex justify-content-center align-items-center position-relative">
                                <span @click="showProject = !showProject; showLab =false; showPartners = false;"
                                      :class="{ 'arrow-rotate' : showProject ,  'opacity1': showProject  }"
                                      class="menu-arrow position-absolute">
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="white"><path
    d="M0 0h24v24H0V0z" fill="none"/><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"/></svg>
                </span>
                <span v-show="!showProject" @click="showProject = !showProject; showLab =false; showPartners = false;">Research</span>
                <div v-show="showProject" class="list-unstyled justify-content-center align-items-center">
                    <span class="text-decoration-underline me-2"><Link href="/research">Themes</Link></span>
                    <span class="text-decoration-underline ms-2"><Link href="/projets">Projects</Link></span>
                    <span class="text-decoration-underline ms-2"><Link href="/impact">Impact</Link></span>
                </div>
            </div>
            <li @click="toggleMenu()"><Link href="/publications">Publications</Link></li>
            <div id="res_partners" class="d-flex justify-content-center align-items-center position-relative">
                <span @click="showPartners = !showPartners ; showLab = false; showProject = false;"
                      :class="{ 'arrow-rotate' : showPartners,  'opacity1': showPartners   }"
                      class="menu-arrow position-absolute">
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="white"><path
    d="M0 0h24v24H0V0z" fill="none"/><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"/></svg>
                </span>
                <span v-show="!showPartners"
                      @click="showPartners = !showPartners ; showLab = false; showProject = false;">Partners</span>
                <div v-show="showPartners" class="list-unstyled justify-content-center align-items-center">
                    <span class="text-decoration-underline me-2"><Link href="/partners">Our Partners</Link></span>
                    <span class="text-decoration-underline ms-2"><Link href="/funding">Funding</Link></span>
                </div>
            </div>
            <li @click="toggleMenu()"><Link href="/getinvolved">Get Involved</Link></li>
        </ul>
    </section>
</template>

<script>
import Logo from "../Logo";
import { Link } from '@inertiajs/inertia-vue'
export default {
    name: "NavbarRes",
    components:{
        Logo,
        Link
    },
    data() {
        return {
            showMenu: false,
            showLab: false,
            showProject: false,
            showPartners: false,
            showArrow: false,
        }
    },
    props: ['closeMenu'],
    mounted() {
    },
    methods: {
        toggleMenu() {
            this.showMenu = !this.showMenu
            this.showLab = false;
            this.showProject = false;
            this.showPartners = false;
            if (this.showMenu) {
                document.querySelector('.line1').style.top = '20px'
                document.querySelector('.line3').style.top = '20px'

                setTimeout(function () {
                    document.querySelector('.line1').style.transform = 'rotate(45deg)'
                    document.querySelector('.line2').style.transform = 'rotate(45deg)'
                    document.querySelector('.line3').style.transform = 'rotate(-45deg)'
                }, 100);

                setTimeout(function () {
                    document.querySelector('.res-menu').style.opacity = '1';
                }, 100)

            } else {
                document.querySelector('.line1').style.transform = 'rotate(0)'
                document.querySelector('.line2').style.transform = 'rotate(0)'
                document.querySelector('.line3').style.transform = 'rotate(-0)'

                setTimeout(function () {
                    document.querySelector('.line1').style.top = '5px'
                    document.querySelector('.line3').style.top = '35px'
                }, 100);

                document.querySelector('.res-menu').style.opacity = '0';
            }
        }
    },
    watch: {
        closeMenu: function (e) {
            if (e) {
                if(this.showMenu){
                    this.toggleMenu()
                    this.$emit('isMenuClosed', true)
                }
            }
        }
    }
}
</script>

<style scoped>
nav {
    position: fixed;
    height: 75px;
    width: 100%;
    top: 0;
    left: 0;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    background-color: rgba(0, 0, 0, 0.95);
    color: white;
    transform: translateY(0px);
    transition: .75s transform cubic-bezier(0, .12, .14, 1);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    z-index: 999;
}

span,
li{
    cursor:pointer;
}

ul li{
    text-decoration: none !important;
}

a{
    text-underline: none;
    text-decoration: none;
    color:white;
}

.logo{
    height:50px;
    width:200px;
}

.w100px {
    width: 100px;
    height: 75px;
}

.hamburger-menu {
    position: relative;
    height: 50px;
}

.nav-icon1 {
    position: relative;
    height: 50px;
    width: 50px;
    margin-left: 10px;
}

.nav-icon1 span {
    display: block;
    position: absolute;
    margin: 3px;
    height: 3px;
    width: 40px;
    background: white;
    border-radius: 5px;
    transition: .25s ease;
}

.pre-logo{
    margin-right:1rem;
}

.line1 {
    top: 5px;
    left: 0;
}

.line2 {
    top: 20px;
    left: 0;
}

.line3 {
    top: 35px;
    left: 0;
}

img {
    width: 60px;
    height: 60px;
}

#involved_btn {
    background-color: #71BF6D;
    border: none;
    color: white;
    white-space: nowrap;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
}

#involved_btn:hover {
    background-color: #43AA8B;
    color: white;
}


.res-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: translateY(-500px);
    position: fixed;
    top: 75px;
    width: 100%;
    height: 50vh;
    padding: 1rem;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 500;
    list-style: none;
    color: white;
    background-color: rgba(0, 0, 0, 0.95);
    transition: .5s ease;
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
    opacity: 0;
    z-index: 99;
}

.open {
    transform: translateY(0);
}

.res-menu > li,
.res-menu > div {
    margin: 1rem;
}

.menu-arrow {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    left: -40px;
    top: -2px;
    width: 30px;
    height: 30px;
    transition: 1s ease;
}

.arrow-rotate {
    transform: rotateZ(180deg);
}

.opacity1 {
    opacity: 1;
}

@media(max-width:330px){
    #involved_btn{
        padding:5px 5px;
    }
}

@media (max-width: 325px) {
    .res-menu {
        font-size: 14px;
    }


}

@media (min-width: 375px) {
    .res-menu > div,
    .res-menu > li {
        margin: 1.5rem;
    }
}


@media (min-width: 568px) {
    .res-menu > div,
    .res-menu > li {
        margin: 2rem;
    }

    .pre-logo{
        margin-right:0;
    }

    .logo{
        width:240px;
        height:60px;
    }
}

</style>
