<template>
    <Layout>
        <section class="w-100 p-4">

            <form @submit.prevent="submit(member.id)" class="card shadow mb-4">
                <div class="card-header">
                  <Link :href="this.$route('team.management')">Team Management</Link> // Edit user - #{{ member.id }}
                </div>

                <div class="card-body d-flex flex-column  justify-content-center align-items-center">
                    <div class="col-lg-6  mt-2 pb-3 text-center position-relative">
                        <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                            <h5 class="card-title m-0">Profil Image</h5>
                            <ToolTip class="p-2" :data="tooltipProfil"></ToolTip>
                        </div>
                        <div class="input-group mb-3 ">
                            <input type="file"
                                   ref="profilImageUpload"
                                   @input="memberForm.profile_image = $event.target.files[0]"
                                   class="form-control">
                        </div>
                        <div v-if="$page.props.errors.profile_image" class="position-absolute bottom-0 w-100">
                            <p class="text-danger">{{ $page.props.errors.profile_image }}</p>
                        </div>
                    </div>
                    <div class="d-flex row">
                        <div class=" col-6 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Profil Name</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="memberForm.name">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Name</span><span
                                    :class="[ this.charLeftProfilName > 25 ? 'text-danger' : '']">{{
                                        this.charLeftProfilName
                                    }} / 25</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.name">
                                <p class="text-danger">{{ $page.props.errors.name }}</p>
                            </div>
                        </div>


                        <div class=" col-6 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Profil Surname</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="memberForm.surname">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Surname</span><span
                                    :class="[ this.charLeftProfilSurname > 25 ? 'text-danger' : '']">{{
                                        this.charLeftProfilSurname
                                    }} / 25</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.surname">
                                <p class="text-danger">{{ $page.props.errors.surname }}</p>
                            </div>
                        </div>


                        <div class=" col-6 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Position</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="memberForm.position">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Position</span><span
                                    :class="[ this.charLeftProfilPosition > 25 ? 'text-danger' : '']">{{
                                        this.charLeftProfilPosition
                                    }} / 25</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.position">
                                <p class="text-danger">{{ $page.props.errors.position }}</p>
                            </div>
                        </div>


                        <div class=" col-6 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Email</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="memberForm.email">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Email</span><span
                                    :class="[ this.charLeftProfilEmail > 50 ? 'text-danger' : '']">{{
                                        this.charLeftProfilEmail
                                    }} / 50</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.email">
                                <p class="text-danger">{{ $page.props.errors.email }}</p>
                            </div>
                        </div>

                        <div class=" col-6 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Keywords</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="memberForm.address"
                                          style="height: 150px"></textarea>
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Keywords</span><span
                                    :class="[ this.charLeftProfilAddress > 125 ? 'text-danger' : '']">{{
                                        this.charLeftProfilAddress
                                    }} / 125</span></label>
                            </div>

                            <div class="mb-3" v-if="$page.props.errors.address">
                                <p class="text-danger">{{ $page.props.errors.address }}</p>
                            </div>
                        </div>

                        <div class=" col-6 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Bio</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="memberForm.bio"
                                          style="height: 150px"></textarea>
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Bio</span><span
                                    :class="[ this.charLeftProfilBio > 500 ? 'text-danger' : '']">{{
                                        this.charLeftProfilBio
                                    }} / 500</span></label>
                            </div>

                            <div class="mb-3" v-if="$page.props.errors.bio">
                                <p class="text-danger">{{ $page.props.errors.bio }}</p>
                            </div>
                        </div>


                        <div class=" col-6 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Team</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <select v-model="memberForm.selectedTeam">
                                    <option v-for="(item,index) in allTeam" :key="index" :value="item.id">{{item.name}}</option>
                                </select>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.selectedTeam">
                                <p class="text-danger">{{ $page.props.errors.selectedTeam }}</p>
                            </div>
                        </div>

                        <div class=" col-6 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Is Teaching ?</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <select v-model="memberForm.teaching">
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                </select>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.teaching">
                                <p class="text-danger">{{ $page.props.errors.teaching }}</p>
                            </div>
                        </div>



                        <div class=" col-6 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Orbi Link</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="memberForm.orbi_link">
                                <label class="d-flex justify-content-between  w-110"><span>Orbi Link</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.orbi">
                                <p class="text-danger">{{ $page.props.errors.orbi }}</p>
                            </div>
                        </div>

                        <div class=" col-6 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Google Link</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="memberForm.google_link">
                                <label class="d-flex justify-content-between  w-110"><span>Google Link</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.google">
                                <p class="text-danger">{{ $page.props.errors.google }}</p>
                            </div>
                        </div>


                        <div class=" col-6 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Research Link</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="memberForm.research_link">
                                <label class="d-flex justify-content-between  w-110"><span>Research Link</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.research">
                                <p class="text-danger">{{ $page.props.errors.research }}</p>
                            </div>
                        </div>


                        <div class=" col-6 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Scopus Link</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="memberForm.scopus_link">
                                <label class="d-flex justify-content-between  w-110"><span>Scopus Link</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.scopus">
                                <p class="text-danger">{{ $page.props.errors.scopus }}</p>
                            </div>
                        </div>


                    </div>
                    <Submit :loading="loading"></Submit>
                </div>
            </form>
        </section>

        <ModalError v-show="showModal" @handleModal="handleModal" :style="{ top : scrollPos + 'px' }"></ModalError>

    </Layout>
</template>

<script>
import Layout from "./Shared/Layout";
import Submit from "../../Components/Button/Submit";
import ToolTip from "../../Components/Info/ToolTip";
import ModalError from "../../Components/Status/ModalError";
import { Link } from '@inertiajs/inertia-vue'

export default {
    name: "EditMember",
    props: ['member','allTeam'],
    components: {
        Layout,
        Submit,
        ToolTip,
        ModalError,
        Link
    },
    data() {
        return {
            scrollPos:null,
            loading: false,
            showToaster: true,
            showModal: true,
            tooltipProfil: 'This change the profil image, dimension must be 1 /1, max size : 1mb ',
            memberForm: {
                profile_image: null,
                name: this.member.name,
                surname: this.member.surname,
                position: this.member.position,
                email: this.member.email,
                address: this.member.address,
                bio: this.member.bio,
                selectedTeam: this.member.team_id ? this.member.team_id : 0,
                teaching: this.member.has_teaching,
                orbi_link: this.member.orbi_link,
                google_link: this.member.google_link,
                research_link: this.member.research_link,
                scopus_link: this.member.scopus_link,
            }
        }
    },
    computed: {
        charLeftProfilName: function () {
            return this.charLeft(this.memberForm.name)
        },
        charLeftProfilSurname: function () {
            return this.charLeft(this.memberForm.surname)
        },
        charLeftProfilPosition: function () {
            return this.charLeft(this.memberForm.position)
        },
        charLeftProfilEmail: function () {
            return this.charLeft(this.memberForm.email)
        },
        charLeftProfilAddress: function () {
            return this.charLeft(this.memberForm.address)
        },
        charLeftProfilBio: function () {
            return this.charLeft(this.memberForm.bio)
        }
    }, methods: {
        charLeft(e) {
            return e.length;
        },
        handleModal(e) {
            console.log(e)
            this.showModal = e
        },
        submit(id) {
            this.$inertia.post(this.$route('team.update', id),
                {
                    profile_image: this.memberForm.profile_image,
                    name: this.memberForm.name,
                    surname: this.memberForm.surname,
                    position: this.memberForm.position,
                    email: this.memberForm.email,
                    address: this.memberForm.address,
                    bio: this.memberForm.bio,
                    selectedTeam: this.memberForm.selectedTeam,
                    teaching: this.memberForm.teaching,
                    orbi_link: this.memberForm.orbi_link,
                    google_link: this.memberForm.google_link,
                    research_link: this.memberForm.research_link,
                    scopus_link: this.memberForm.scopus_link,
                },
                {
                    preserveScroll: true,
                    onError: visit => {
                        this.loading = false;
                    },
                }
            );
        }
    }
}
</script>

<style scoped>
.w-110 {
    width: 110%;
}
</style>
