<template>
    <div class="loading-block">
    <div class="loader"></div>
        <p>Loading...</p>
    </div>
</template>

<script>
export default {
    name: "Loading"
}
</script>

<style scoped>
.loading-block{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.loader {
    border: 5px solid rgba(255,255,255,0.3); /* Light grey */
    border-top: 5px solid #F9C74F; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
