<template>
    <Layout>
        <section class="w-100 p-4">

            <form @submit.prevent="submit" class="card shadow mb-4">
                <div class="card-header">
                    <Link :href="this.$route('funders.management')">Funders Management</Link>
                    // Add funder
                </div>

                <div class="card-body d-flex flex-column  justify-content-center align-items-center">
                    <div class="col-lg-6  mt-2 pb-3 text-center position-relative">
                        <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                            <h5 class="card-title m-0">Partner Image</h5>
                            <ToolTip class="p-2" :data="tooltipPartner"></ToolTip>
                        </div>
                        <div class="input-group mb-3 ">
                            <input type="file"
                                   ref="partnerImageUpload"
                                   @input="form.image = $event.target.files[0]"
                                   class="form-control">
                        </div>
                        <div v-if="$page.props.errors.image" class="position-absolute bottom-0 w-100">
                            <p class="text-danger">{{ $page.props.errors.image }}</p>
                        </div>
                    </div>

                    <div class="col-12 row">
                        <div class=" col-6 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Partner Name</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="form.name">
                                <label class="d-flex justify-content-between  w-110"><span>Name</span><span
                                    :class="[ this.charLeftPartnerName > 25 ? 'text-danger' : '']">{{
                                        this.charLeftPartnerName
                                    }} / 25</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.name">
                                <p class="text-danger">{{ $page.props.errors.name }}</p>
                            </div>

                        </div>

                        <div class=" col-6 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Partner Link</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="form.link">
                                <label class="d-flex justify-content-between  w-110"><span>Link</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.link">
                                <p class="text-danger">{{ $page.props.errors.link }}</p>
                            </div>

                        </div>


                    </div>


                    <Submit :loading="loading"></Submit>
                </div>

            </form>
        </section>


    </Layout>
</template>

<script>
import Layout from "./Shared/Layout";
import Submit from "../../Components/Button/Submit";
import ToolTip from "../../Components/Info/ToolTip";
import {Link} from '@inertiajs/inertia-vue'

export default {
    name: "AddFunder",
    components: {
        Layout,
        Submit,
        ToolTip,
        Link
    },
    data() {
        return {
            loading: false,
            tooltipPartner: 'Change project image. Dimension ratio for image must be 1/1, only .jpg .jpeg or .png . Max weight 1mb.',
            downloadLink: null,
            document: null,
            form: {
                image: null,
                name: '',
                link: null,
            },
        }
    },
    computed: {
        charLeftPartnerName() {
            return this.charLeft(this.form.name)
        },
    },
    methods: {
        charLeft(e) {
            return e.length;
        },
        submit() {
            this.loading = true;
            this.$inertia.post(this.$route('funder.add'),
                this.form,
                {
                    preserveScroll: true,
                    onError: visit => {
                        this.loading = false;
                    },
                    onFinish: visit => {
                        this.loading = false;
                    }
                }
            )
        }
    }
}
</script>

<style scoped>
.w-110 {
    width: 110%;
}

.or {
    position: absolute;
    bottom: 20px;
    left: 35px;
}
</style>
