<template>
    <div>

        <div ref="carousel"  class="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
            <div class="carousel-indicators">
                <button v-for="(item,index) in projects" :key="index" type="button" data-bs-target="#carouselExampleCaptions" :data-bs-slide-to="index" :class="[ index === 0 ? 'active' : '' ]"
                        aria-current="true" aria-label="Slide 1"></button>

            </div>
            <div class="carousel-inner">
                <div ref="carouselItem" class="carousel-item" v-for="(item,index) in projects" :key="index">
                    <img :src="item.image" class="d-block w-100" alt="">
                    <div class="carousel-caption">
                        <div class="text">
                            <h3>{{ item.name }}</h3>
                            <p v-show="!mobileView">{{ item.description.slice(0, 300) + '...' }}</p>
                            <p v-show="mobileView">{{ item.description.slice(0, 75) + '...' }}</p>
                            <Link :href="$route(route,`name=${item.name}`)">
                                <button class="btn btn-light">Read More</button>
                            </Link>
                        </div>

                    </div>
                </div>
            </div>
            <button @click="carouselPrev" class="carousel-control-prev" type="button" data-bs-target="#"
                    data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button @click="carouselNext" class="carousel-control-next" type="button" data-bs-target="#"
                    data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>

    <div class="w-100 d-flex justify-content-center align-items-center mt-4">
        <button v-show="isPlaying" @click="pauseCarousel" class="pause-btn">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="white">
                <path d="M0 0h24v24H0V0z" fill="none"/>
                <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/>
            </svg>
        </button>
        <button v-show="!isPlaying" @click="playCarousel" class="play-btn">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="white">
                <path d="M0 0h24v24H0V0z" fill="none"/>
                <path d="M10 8.64L15.27 12 10 15.36V8.64M8 5v14l11-7L8 5z"/>
            </svg>
        </button>
    </div>
    </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue'
export default {
    name: "Carousel",
    props: ['projects','route'],
    components:{
        Link
    },
    data() {
        return {
            mobileView: false,
            isPlaying: true,
        }
    },
    created() {

    },
    mounted() {
        this.handleView();
        this.$nextTick(() => {
            this.handleActive()
        })
    },
    methods: {
        handleView() {
            this.mobileView = window.innerWidth < 720;
        },
        handleActive() {
            let element = this.$refs.carouselItem;
            if (element[0]) {
                element[0].classList.add('active');
            }
        },
        pauseCarousel() {
            this.isPlaying = false;
            let carousel = this.$refs.carousel
            $(carousel).carousel('pause');
        },
        playCarousel() {
            this.isPlaying = true;
            let carousel = this.$refs.carousel
            $(carousel).carousel('cycle');
        },
        carouselPrev(){
            let carousel = this.$refs.carousel
            $(carousel).carousel('prev');
        },
        carouselNext(){
            let carousel = this.$refs.carousel
            $(carousel).carousel('next');
        }
    },
}
</script>

<style scoped>
.carousel {
    width: 900px;
    height:600px;
}

.carousel-caption {
    background: rgba(0, 0, 0, 0.5);
    padding-bottom: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0;
    bottom: 0;
}

.carousel-item:nth-child(odd) .carousel-caption {
    left: 50%;
    right: 0;
}

.carousel-item:nth-child(even) .carousel-caption {
    left: 0;
    right: 50%;
}

.carousel-caption .text {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    height: 300px;
    width: 350px;
    margin-right: 2rem;
    margin-bottom: 1rem;
}

.carousel p{
    font-size:16px;
}

.text h3 {
    margin: 1rem;
}

.carousel-control-prev,
.carousel-control-next {
    width: 5%;
    opacity: .7;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
    opacity: 1;
}

.play-btn,
.pause-btn {
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 5px;
}

.play-btn:hover,
.pause-btn:hover {
    background-color: rgba(0, 0, 0, 0.2);
}


@media (max-width:1024px){
    .carousel {
        width: 90vw;
        height:auto;
    }
}

@media (max-width: 992px) {
    .carousel-caption .text {
        height: 350px;
        width: 275px;
    }
}

@media (max-width: 767px) {
    .carousel-item:nth-child(odd) .carousel-caption,
    .carousel-item:nth-child(even) .carousel-caption {
        left: 0;
        right: 0;
    }

    .carousel-caption .text {
        height: 100%;
        width: 300px;
        margin-right: 0;
        margin-bottom: 0;
    }
}

@media (max-width: 370px) {
    .carousel-caption {
        padding-top: 0;
    }
}
</style>
