<template>
    <Layout>

        <TopImg :url="headerImage" :pageTitle="partnersData.page_title" :pageDescription="partnersData.page_description"></TopImg>
        <section class="page-section">

            <Parallax :background="background"></Parallax>

            <div class="main-content">
                <PartnersLogo :data="partnersContent"></PartnersLogo>
            </div>

        </section>

    </Layout>
</template>

<script>
import Layout from "../Shared/Layout";
import TopImg from "../Components/TopImg";
import Parallax from "../Components/Parallax";
import TopTitle from "../Components/TopTitle";
import PartnersLogo from "../Components/Partners/PartnersLogo";

export default {
    name: "Partners",
    props:['headerImage','partnersData','partnersContent'],
    components: {
        Layout,
        TopImg,
        Parallax,
        TopTitle,
        PartnersLogo
    },
    data() {
        return {
            background: 'img/default/background/home-parallax.jpg'
        }
    }
}
</script>

<style scoped>
/* Parallax page style @ css/page-parallax.css*/

.main-content {
    position: relative;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
    margin: 0 auto;
    min-height:500px;
}

.row {
    max-width: 1000px;
}

.partner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    cursor: pointer;
}

.partner > div {
    margin-top: 1rem;
}

.partner img {
    width: 150px;
}

</style>
