<template>
    <Layout>
        <TopImg :url="headerImage" :pageTitle="publicationsData.page_title" :pageDescription="publicationsData.page_description"></TopImg>
        <section class="page-section">

            <Parallax :background="background"></Parallax>

                <div class="searchbar-content">
                    <div class="searchbar">
                        <div class="keywords">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                                 fill="white">
                                <path d="M0 0h24v24H0V0z" fill="none"/>
                                <path
                                    d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
                            </svg>
                            <input placeholder="Search..." v-model="searchInput">
                        </div>
                        <div class="year">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                                 fill="white">
                                <path d="M0 0h24v24H0V0z" fill="none"/>
                                <path
                                    d="M7 11h2v2H7v-2zm14-5v14c0 1.1-.9 2-2 2H5c-1.11 0-2-.9-2-2l.01-14c0-1.1.88-2 1.99-2h1V2h2v2h8V2h2v2h1c1.1 0 2 .9 2 2zM5 8h14V6H5v2zm14 12V10H5v10h14zm-4-7h2v-2h-2v2zm-4 0h2v-2h-2v2z"/>
                            </svg>
                            <select name="" id="#" v-model="selectedYear">
                                <option v-for="(item,index) in orderedYear" :value="item">{{ item }}</option>
                            </select>
                        </div>
                        <div class="thema">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                                 fill="white">
                                <path d="M0 0h24v24H0V0z" fill="none"/>
                                <path d="M14 17H4v2h10v-2zm6-8H4v2h16V9zM4 15h16v-2H4v2zM4 5v2h16V5H4z"/>
                            </svg>
                            <select name="" id="" v-model="selectedTheme">
                                <option v-for="(item, index) in themeArray" :value="item">{{ item }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="main-content row">
                    <div v-for="(item, index) in filteredList" :key="index"
                         v-show="handleSelectSearch(item.date,item.theme)"
                         class="box col-lg-3 col-md-3 col-sm-4 col-6">
                        <div class="img-box" >
                            <img :src="item.image" alt="">
                        </div>

                        <div class="h-100 d-flex flex-column justify-content-evenly ">
                            <h3 v-if="item.name.length >50">{{item.name.slice(0,50) + '...'}}</h3>
                            <h3 v-else>{{item.name.slice(0,50)}}</h3>

                            <div class="author">
                                <h5>Author:</h5>
                                <div class="d-flex justify-content-center align-items-center">
                                    <h5 v-for="(element, key) in item.author" :key:="key">
                                        <span v-if="key <= 1">{{element}}<span v-if="key < 2 "> &nbsp;</span></span>
                                    </h5>
                                    <span v-if="item.author.length > 2"><p class="end-dot">...</p></span>
                                </div>
                            </div>
                            <h5>{{item.date}}</h5>
                        </div>
                        <button class="btn btn-light" @click="openModal(item.id)">Read More</button>
                    </div>
                </div>

        </section>

        <PublicationsModal :isModalOpen="isModalOpen" :selectedContent="selectedContent"
                          @handleModalClose="handleModalClose"></PublicationsModal>


    </Layout>
</template>

<script>
import Layout from "../Shared/Layout";
import TopImg from "../Components/TopImg";
import Parallax from "../Components/Parallax";
import TopTitle from "../Components/TopTitle";
import PublicationsModal from "../Components/Publications/PublicationsModal";

export default {
    name: "Publications",
    components: {
        Layout,
        TopImg,
        Parallax,
        TopTitle,
        PublicationsModal,
    },
    props:['publicationsData','headerImage','allPublications','orderedYear','themeArray'],
    data() {
        return {
            background: 'img/default/background/home-parallax.jpg',
            isModalOpen: false,
            selectedContent: null,
            searchInput: '',
            selectedYear: 'All',
            selectedTheme: 'All',
        }
    },
    mounted(){
        this.$nextTick(()=>{
            if(window.location.href.indexOf('=') >0){
                this.searchInput =  window.location.href.slice(window.location.href.indexOf('=')+1,window.location.href.length).split('%20').join(' ');
                this.searchInput = this.searchInput.slice(0,-1);

                setTimeout(()=>{
                    window.history.replaceState(
                        null,
                        null,
                        this.$route('publications')
                    )
                },50)

            }
        })
    },
    methods: {
        openModal(index) {
            this.isModalOpen = true;
            this.selectedContent = this.allPublications.filter(item=>{
                if(item.id === index){
                    return item;
                }
            })
            this.selectedContent = this.selectedContent[0];
        },
        handleModalClose() {
            this.isModalOpen = false;
        },
        handleSelectSearch(date,theme){
            if(this.selectedYear === 'All' || date.slice(-4) === this.selectedYear){
                if(this.selectedTheme === 'All' || theme.includes(this.selectedTheme)){
                    return true;
                }
            }else{
                return false;
            }
        }
    },
    computed: {
        filteredList() {
            return this.allPublications.filter(item =>{
                return item.name.toLowerCase().includes(this.searchInput.toLowerCase())
                    || JSON.stringify(Array(item.author)).toLowerCase().includes(this.searchInput.toLowerCase())
                    || item.description.toLowerCase().includes(this.searchInput.toLowerCase())
            })
        }
    },
}
</script>

<style scoped>
/* Parallax page style @ css/page-parallax.css*/

.main-content {
    position: relative;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    margin: 0 auto;
    min-height:500px;
}

.searchbar-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 75px 0 30px;
}

.searchbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 900px;
    z-index: 9;
}

.searchbar input,
.searchbar select {
    padding: 10px;
    width: 150px;
    border-radius: 5px;
    border: none;
    color: white;
    background-color: rgba(10, 147, 150, 0.7);
}

.searchbar input:focus {
    text-decoration: none;
    outline: none;
}

.searchbar input::placeholder {
    color: rgba(240, 240, 240, .7);
}


.box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    width: 275px;
    height: 450px;
    margin: 25px 20px;
    padding: 0 10px 10px;
    background-color: rgba(26, 32, 44, 0.6);
    box-shadow: 2px 2px 2px 1px rgba(255, 255, 255, .1);
}

.img-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
}

.box img {
    width: 100%;
}

.box .author h5{
    font-size:20px;
}

.box button {
    margin: 10px 0;
}

.end-dot{
    font-size:20px;
    font-weight: bold;
}

.keywords svg,
.thema svg,
.year svg {
    margin-right: 1rem;
}

@media (max-width: 720px) {

    .main-content {
        padding: 50px 0;
    }
}

@media (max-width: 663px) {
    .searchbar {
        flex-direction: column;
    }

    .searchbar div {
        margin-top: 1rem;
    }
}


</style>
