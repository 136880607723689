<template>
    <div >
        <div ref="modal" v-show="isModalOpen" @click="closeModal" class="newModal">
            <div v-if="selectedContent != null" class="modal-content">
                <div class="close-modal close-btn">
                    <svg class="close-btn" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                         width="24px" fill="white">
                        <path class="close-btn" d="M0 0h24v24H0V0z" fill="none"/>
                        <path class="close-btn"
                              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/>
                    </svg>
                </div>
                <div class="top-content">
                    <img :src="selectedContent.image" alt="">
                </div>
                <div class="main-content">
                    <div class="title">
                        <h4>{{ selectedContent.name }}</h4>
                        <h5 class="mb-0">{{ selectedContent.date }}</h5>
                    </div>

                    <div v-if="selectedContent.theme" class="status">
                        <h5>Theme:</h5>
                        <h5>{{ selectedContent.theme }}</h5>
                    </div>

                    <hr>
                    <div class="scrollable-content">


                        <div class="description">
                            <p>
                                {{ selectedContent.description }}
                            </p>
                        </div>

                        <div class="author" v-if="selectedContent.author.length > 0">
                            <h5>Author:</h5>

                            <div class="d-flex justify-content-center align-items-center">
                                <Link class="text-decoration-none" @click="handleOverflow" :href="$route('team',`name=${item}`)"
                                   v-for="(item,index) in selectedContent.author" :key="index"><p class="p-0 ps-2 pe-2">
                                    {{ item }}</p></Link>
                            </div>

                        </div>

                    </div>

                    <div class="download">
                        <a :href="selectedContent.link" download>
                            <button class="btn btn-light">Download File</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue'

export default {
    name: "PublicationModal",
    props: ['isModalOpen', 'selectedContent', 'pageTitle'],
    components: {
        Link
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    updated(){
        //Fix to avoid modal off screen
        this.$refs.modal.style.top =  window.scrollY + 'px';
    },
    methods: {
        closeModal(e) {
            if (e.target.classList.contains('newModal') || e.target.classList.contains('close-btn')) {
                this.$emit('handleModalClose');
            }
        },
        handleScroll() {
            this.$refs.modal.style.top =  window.scrollY + 'px';
        },
        handleOverflow(){
            document.querySelector('body').style.overflowY = "auto";
        }

    },
    watch: {
        isModalOpen: function (e) {
            if (e) {
                document.querySelector('body').style.overflowY = "hidden";
            } else {
                document.querySelector('body').style.overflowY = "auto";
            }
        },
    }
}
</script>

<style scoped>

/* Track */
::-webkit-scrollbar-track {
    background: rgba(77, 144, 142, .2) !important;
    border-radius: 10px !important;
}

.newModal p{
    font-size:18px;
}

.newModal {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    position:absolute;
    overflow:auto;
    background-color: rgba(0, 0, 0, .6);
    content: "";
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
}

.modal p {
    font-size: 16px;
}

.modal-content {
    position: relative;
    background-color: #1e2125;
    width: 600px;
    height: 925px;
    color: whitesmoke;
    box-shadow: 5px 4px 2px rgba(255, 255, 255, 0.2);
}

.close-modal {
    position: absolute;
    right: 1rem;
    top: 1rem;
    color: white;
    cursor: pointer;
}

.top-content img {
    width: 100%;
}

.main-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 500px;
    margin-top: 1rem;
    overflow: auto;
}

.main-content hr {
    width: 50%;
    margin: 0;
}

.title {
    text-align: center;
    width: 75%;
}

.title h6 {
    margin: 1rem;
}

.scrollable-content {
    height: 60%;
    min-width:95%;
    overflow: auto;
    padding: 1rem 1rem 0;
    margin-top: 1rem;
    margin-right: 0.5rem;
}

.description p{
    text-align: center;
}


.title h5,
.description p,
.author p,
.keywords p {
    padding: 0 1rem;
}

.author {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    padding-bottom:20px;
}

.keywords {
    margin-top: 2rem;
    text-align: center;
}


.author h5,
.keywords h5 {
    border-top: 1px dashed rgba(255, 255, 255, 0.2);
    width: 50%;
    margin: 0 auto;
    padding-top: 1rem;
    text-align: center;
}

.download {
    margin-right: 1rem;
}


.author h5 {
    text-align: center;
    margin: 1rem 0;
}

.status {
    width: 200px;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    margin: 0 auto 0rem;
}


.status h5 {
    text-align: center;
    font-size: 18px;
}


@media (max-width: 720px) {

    .modal-content {
        height: 90vh;
        width: 100vw;
    }

    .author p,
    .keywords p,
    .description p {
        padding: 0;
    }

    .author,
    .keywords {
        margin-top: 1rem;
    }

    .download {
        margin-bottom: 1rem;
    }

}

@media (max-width: 420px) {
    .main-content hr:nth-of-type(1) {
        margin-top: 1rem;
    }

    .main-content hr:nth-of-type(2) {
        margin-bottom: 1rem;
    }
}

</style>
