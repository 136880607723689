<template>
    <div>
        <Link :href="this.$route('home')"><img class="logo" :src="logo" alt=""></Link>
    </div>
</template>

<script>
import {Link} from '@inertiajs/inertia-vue';

export default {
    name: "Logo",
    components: {
        Link
    },
    computed:{
        logo(){
            return this.$page.props.settings.logo;
        }
    }
}
</script>

<style scoped>
.logo{
    height:100%;
}
</style>
