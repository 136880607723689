<template>
    <Layout>
        <TopImg :url="headerImage" :pageTitle="impactData.page_title" :pageDescription="impactData.page_description"></TopImg>
        <section class="page-section">
            <Parallax :background="background"></Parallax>

            <div class="main-content">

                <DataText v-scroll-animation-left :data="impactContent"></DataText>

                <ParagraphBlock v-scroll-animation-right :data="impactContent.paragraphe_1"></ParagraphBlock>

                <ParagraphAndImage v-scroll-animation-up :data="content[0]"></ParagraphAndImage>

                <ParagraphBlock v-scroll-animation-up :data="impactContent.paragraphe_2"></ParagraphBlock>

            </div>

        </section>
        <Footer></Footer>
    </Layout>
</template>

<script>
import Layout from "../Shared/Layout";
import TopImg from "../Components/TopImg";
import Parallax from "../Components/Parallax";
import TopTitle from "../Components/TopTitle";
import DataText from "../Components/Text/DataText";
import ParagraphBlock from "../Components/Text/ParagraphBlock";
import ParagraphAndImage from "../Components/Text/ParagraphAndImage";

export default {
    name: "Impact",
    components: {
        Layout,
        TopImg,
        Parallax,
        TopTitle,
        DataText,
        ParagraphBlock,
        ParagraphAndImage
    },
    props:['headerImage','impactData','impactContent'],
    data() {
        return {
            background: 'img/default/background/home-parallax.jpg',
            content:[
                {
                    title: this.impactContent.block_title,
                    image: this.impactContent.image,
                    text: this.impactContent.block_content,
                }
            ]
        }
    }
}
</script>

<style scoped>
/* Parallax page style @ css/page-parallax.css*/

.main-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 100px 0;
    max-width: 1200px;
    margin: auto;
}

.block img {
    width: 40%;
    max-width: 450px;
    margin: 1rem;
    box-shadow: rgba(255, 255, 255, 0.08) 0px 10px 15px -3px, rgba(255, 255, 255, 0.08) 0px 4px 6px -2px;
}

@media (max-width: 720px) {
    .main-content {
        padding: 50px 0;
    }
}

</style>
