<template>
    <Layout>
        <section class="w-100 p-4">
            <div class="card shadow mb-4">
                <div class="card-header">
                    Team Management
                </div>
                <div class="card-body d-flex flex-row  justify-content-evenly align-items-center">
                    <div class="input-group">
                        <span class="input-group-text" id="basic-addon1">Search</span>
                        <input type="text" class="form-control" v-model="searchInput"
                               placeholder="search for name, surname, position, team, email"
                               aria-describedby="basic-addon1">
                    </div>

                    <Link :href="this.$route('team.add')">
                        <button class="btn btn-success text-white">Add Member</button>
                    </Link>
                </div>
                <div class="card-body d-flex flex-row  justify-content-evenly align-items-center">
                    <div class="input-group">
                        <span class="input-group-text">New Team</span>
                        <input type="text" class="form-control" v-model="newTeamName" placeholder="New team name"
                               aria-label="Username" aria-describedby="basic-addon1">
                        <span class="input-group-text"><button @click="addTeamName"
                                                               class="btn btn-success text-white">+</button></span>
                    </div>
                    <div class="d-flex justify-content-evenly w-25">
                        <select v-model="selectedTeam">
                            <option v-for="(item,index) in allTeam" :key="index" :value="item.id">{{
                                    item.name
                                }}
                            </option>
                        </select>
                        <button class="btn btn-info text-white" @click="editTeamName">Edit</button>
                        <button class="btn btn-danger text-white" @click="deleteTeamName">Delete</button>
                    </div>
                </div>

                <div v-show="showEditName">
                    <div class="card-body d-flex flex-row  justify-content-evenly align-items-center">
                        <div class="input-group">
                            <span class="input-group-text">Edit Team</span>
                            <input type="text" class="form-control" v-model="nameToEdit" placeholder="New team name"
                                   aria-label="Username" aria-describedby="basic-addon1">
                        </div>
                        <div class="d-flex justify-content-evenly w-25">
                            <button class="btn btn-info text-white" @click="updateTeamName">Confirm</button>
                            <button class="btn btn-danger text-white" @click="cancelTeamName">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card shadow">
                <div class="card-header">
                    Team Listing
                </div>
                <div class="card-body d-flex flex-column  justify-content-center align-items-center">

                    <table class="table  table-hover">
                        <thead>
                        <tr>
                            <th scope="col" class="text-center">#</th>
                            <th scope="col" class="text-center">Avatar</th>
                            <th scope="col" class="text-center">Name</th>
                            <th scope="col" class="text-center">Order</th>
                            <th scope="col" class="text-center">Position</th>
                            <th scope="col" class="text-center">Team</th>
                            <th scope="col" class="text-center">Email</th>
                            <th scope="col" class="text-center">Edit</th>
                            <th scope="col" class="text-center">Delete</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in paginatedData" :key="index" class="">
                            <td><span>{{ item.id }}</span></td>
                            <td><span><img class="profile-image" :src="item.profile_image" alt=""></span></td>
                            <td><span>{{ item.name }}</span></td>
                            <td class="d-flex flex-column">
                                <div class="h30" role="button" @click="increaseOrder(item.id)">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                         width="24px" fill="black">
                                        <path d="M0 0h24v24H0V0z" fill="none"/>
                                        <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z"/>
                                    </svg>
                                </div>
                                <div class="h30" role="button" @click="decreaseOrder(item.id)">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24"
                                         width="24px" fill="black">
                                        <path d="M0 0h24v24H0V0z" fill="none"/>
                                        <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>
                                    </svg>
                                </div>
                            </td>
                            <td><span>{{ item.position }}</span></td>
                            <td><span v-if="item.team_name">{{ item.team_name }}</span> <span v-else>-</span></td>
                            <td v-if="item.email.length > 21"><span>{{ item.email.slice(0,18) + '...' }}</span></td>
                            <td v-else><span>{{ item.email }}</span></td>
                            <td class="btn-width"><span><Link :href="$route('team.edit',item.id)" target="_blank"><button
                                class="btn btn-info text-white">Edit</button></Link></span></td>
                            <td class="btn-width"><span><button @click="deleteProfil(item.id)"
                                                                class="btn btn-danger text-white">Delete</button></span>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <nav aria-label="member pagination">
                        <ul class="pagination">
                            <li class="page-item" @click="prevPage">
                                <span class="page-link" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </span>
                            </li>
                            <li v-for="(item,index) in this.pageCount" :key="index" class="page-item"
                                @click="goToPage(item)"><span class="page-link">{{ item }}</span></li>
                            <li class="page-item" @click="nextPage">
                                <span class="page-link" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </span>
                            </li>
                        </ul>
                    </nav>

                </div>
            </div>
        </section>

        <SuccessToaster v-show="showToaster" @handleToaster="handleToaster"></SuccessToaster>

    </Layout>
</template>

<script>
import Layout from "./Shared/Layout";
import {Link} from '@inertiajs/inertia-vue'
import SuccessToaster from "../../Components/Status/SuccessToaster";

export default {
    name: "TeamManagement",
    props: ['allMember', 'allTeam'],
    components: {
        Layout,
        Link,
        SuccessToaster,
    },
    data() {
        return {
            loading: false,
            showToaster: true,
            showModal: true,
            scrollPos: null,
            pageNumber: 0,
            size: 10,
            searchInput: '',
            recalcPage: 0,
            newTeamName: null,
            selectedTeam: null,
            nameToEdit: null,
            showEditName: false,
        }
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.showToaster = false;
            }, 3000)
        })
    },
    computed: {
        pageCount() {
            if (this.recalcPage === 0) {
                let l = this.allMember.length;
                let s = this.size;
                return Math.ceil(l / s);
            } else {
                let l = this.recalcPage;
                let s = this.size;
                return Math.ceil(l / s);
            }
        },
        paginatedData() {
            const start = this.pageNumber * this.size,
                end = start + this.size;
            if (this.searchInput.length !== 0) {
                let data = this.allMember.filter(item => {
                    return item.name.toLowerCase().includes(this.searchInput.toLowerCase()) ||
                        item.surname.toLowerCase().includes(this.searchInput.toLowerCase()) ||
                        item.position.toLowerCase().includes(this.searchInput.toLowerCase()) ||
                        item.team_name.toLowerCase().includes(this.searchInput.toLowerCase()) ||
                        item.email.toLowerCase().includes(this.searchInput.toLowerCase())
                });
                data.slice(start, end);
                this.recalcPage = data.slice.length;
                return data;
            } else {
                this.recalcPage = 0;
                return this.allMember.slice(start, end);
            }

        },
    },
    methods: {
        handleToaster(e) {
            this.showToaster = e
        },
        nextPage() {
            if (this.pageNumber !== Math.ceil(this.allMember.length / this.size) - 1) {
                this.pageNumber++;
            }
        },
        prevPage() {
            if (this.pageNumber !== 0) {
                this.pageNumber--;
            }
        },
        goToPage(e) {
            this.pageNumber = e - 1;
        },
        deleteProfil(e) {
            this.loading = true;
            this.showToaster = true;
            this.$inertia.post(this.$route('team.delete', e),
                {}
                , {
                    preserveScroll: true,
                    onFinish: visit => {
                        this.loading = false;
                        setTimeout(() => {
                            this.showToaster = false;
                        }, 3000)
                    }
                }
            )
        },
        addTeamName() {
            this.showToaster = true;
            this.$inertia.post(this.$route('team.addTeamName'),
                {
                    newTeamName: this.newTeamName
                },
                {
                    preserveScroll: true,
                    onFinish: visit => {
                        this.newTeamName = null;
                        setTimeout(() => {
                            this.showToaster = false;
                        }, 3000)
                    }
                }
            )
        },
        editTeamName() {
            this.allTeam.filter(item => {
                if (item.id === this.selectedTeam) {
                    return this.nameToEdit = item.name;
                }
            });
            if (this.nameToEdit.length > 0) {
                this.showEditName = true;
            }

        },
        updateTeamName() {
            this.showToaster = true;
            this.$inertia.post(this.$route('team.updateTeamName', this.selectedTeam),
                {
                    updatedName: this.nameToEdit
                },
                {
                    preserveScroll: true,
                    onFinish: visit => {
                        this.updatedName = null;
                        this.selectedTeam = null;
                        this.nameToEdit = null;
                        this.showEditName = false;
                        setTimeout(() => {
                            this.showToaster = false;
                        }, 3000)
                    }
                }
            )
        },
        cancelTeamName() {
            this.updatedName = null;
            this.selectedTeam = null;
            this.nameToEdit = null;
            this.showEditName = false;
        },
        deleteTeamName() {
            this.showToaster = true;
            this.$inertia.post(this.$route('team.deleteTeamName', this.selectedTeam),
                {},
                {
                    preserveScroll: true,
                    onFinish: visit => {
                        this.selectedTeam = null;
                        setTimeout(() => {
                            this.showToaster = false;
                        }, 3000)
                    }
                }
            )
        },
        //This bring element closer to 1st position
        increaseOrder(id) {
            let movingElement = this.allMember.find(item => item.id === id);
            let movingElementIndex = this.allMember.findIndex(item => item.id === id);
            if(movingElementIndex > 0 ) {
                this.allMember[movingElementIndex].element_order--;
                this.allMember.splice(movingElementIndex, 1);
                this.allMember.splice(movingElementIndex - 1, 0, movingElement)
            }
            this.$inertia.post(this.$route('team.increaseOrder'),
                {
                    id: id,
                },
                {
                    preserveScroll: true,
                }
            )
        },
        decreaseOrder(id) {
            let movingElement = this.allMember.find(item => item.id === id);
            let movingElementIndex = this.allMember.findIndex(item => item.id === id);
            if(movingElementIndex < this.allMember.length-1) {
                this.allMember[movingElementIndex].element_order++;
                this.allMember.splice(movingElementIndex, 1);
                this.allMember.splice(movingElementIndex + 1, 0, movingElement)
            }
            this.$inertia.post(this.$route('team.decreaseOrder'),
                {
                    id: id,
                },
                {
                    preserveScroll: true
                }
            )
        }
    }
}
</script>

<style scoped>
.btn-width {
    width: 50px;
}

.table td span {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
}

.table tr span,
.table tr div {
    display: flex;
    justify-content: center;
}

.h30 {
    height: 30px;
}

.profile-image {
    width: 50px;
}



.page-item {
    cursor: pointer;
}

.input-group {
    width: 400px;
}

.table {
    table-layout: fixed;
}
</style>
