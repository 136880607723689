<template>
    <div class="main" v-if="$page.props.errors.maxAttempt" >
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    <div v-if="$page.props.errors.maxAttempt">
                        <p>Temp Ban</p>
                    </div>
                </h5>
                <button @click="closeModal" type="button" class="btn-close"></button>
            </div>
            <div class="modal-body text-center">

                <svg xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="0 0 35 35" width="50px" fill="red"><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/></svg>
                <div v-if="$page.props.errors.maxAttempt">

                    <p>{{$page.props.errors.maxAttempt}}</p>
                </div>
            </div>
            <div class="modal-footer">
                <button @click="closeModal" type="button" class="btn btn-secondary" >Close</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModalError",
    props:[''],
    methods:{
        closeModal() {
            this.$emit('handleModal', false)
        }
    },
}
</script>

<style scoped>
.main {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 99;
}

.modal-content{
    width:350px;
}
</style>
