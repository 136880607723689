<template>
    <Layout>
        <TopImg :url="headerImage" :pageTitle="teampageData.page_title" :pageDescription="teampageData.page_description"></TopImg>
        <section class="page-section">

            <Parallax :background="background"></Parallax>

            <div class="d-flex flex-column justify-content-around align-items-center">

                <div class="searchbar-content">
                    <div class="searchbar">
                        <div class="keywords">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                                 fill="white">
                                <path d="M0 0h24v24H0V0z" fill="none"/>
                                <path
                                    d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
                            </svg>
                            <input placeholder="Search..." v-model="searchInput">
                        </div>

                        <div class="thema">
                            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px"
                                 viewBox="0 0 24 24" width="24px" fill="white">
                                <rect fill="none" height="24" width="24"/>
                                <g>
                                    <path
                                        d="M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1C4.76,14.04,4.39,14,4,14 c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2 c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85C21.93,14.21,20.99,14,20,14 c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M16.24,13.65c-1.17-0.52-2.61-0.9-4.24-0.9 c-1.63,0-3.07,0.39-4.24,0.9C6.68,14.13,6,15.21,6,16.39V18h12v-1.61C18,15.21,17.32,14.13,16.24,13.65z M8.07,16 c0.09-0.23,0.13-0.39,0.91-0.69c0.97-0.38,1.99-0.56,3.02-0.56s2.05,0.18,3.02,0.56c0.77,0.3,0.81,0.46,0.91,0.69H8.07z M12,8 c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S11.45,8,12,8 M12,6c-1.66,0-3,1.34-3,3c0,1.66,1.34,3,3,3s3-1.34,3-3 C15,7.34,13.66,6,12,6L12,6z"/>
                                </g>
                            </svg>
                            <select name="" id="#" v-model="selectedTeam">
                                <option v-for="(item, index) in teamArray" :value="item">{{ item }}</option>
                            </select>
                        </div>
                    </div>
                </div>


                <div class="main-content row">
                    <div v-scrollAnimationUp v-for="(item, index) in filteredList" :key="index"
                         v-show="selectedTeam === item.team || selectedTeam === 'All' " class="profil col-lg-3 col-md-3 col-sm-4 col-6">
                        <div class="d-flex flex-column justify-content-evenly align-items-center h-100 mt-1">
                            <img :src="item.profile_thumb" alt="">
                            <div class="d-flex flex-column">
                                <h5>{{ item.name }}</h5>
                                <h5> {{ item.surname }}</h5>
                            </div>
                            <h6>{{ item.position }}</h6>
                            <h6>{{ item.team }}</h6>
                            <button class="btn btn-light" @click="openModal(item.id)">Read More</button>
                        </div>


                    </div>
                </div>
            </div>
        </section>

        <ProfileModal :selectedProfile="selectedProfile" :isModalOpen="isModalOpen" :pageTitle="teampageData.page_title"
                      @handleModalClose="handleModalClose"></ProfileModal>

    </Layout>
</template>

<script>

import Layout from "../Shared/Layout";
import TopImg from "../Components/TopImg";
import Parallax from "../Components/Parallax";
import TopTitle from "../Components/TopTitle";
import ProfileModal from "../Components/TheLab/ProfileModal";

export default {
    name: "Team",
    components: {
        Layout,
        TopImg,
        Parallax,
        TopTitle,
        ProfileModal,
    },
    props:['headerImage','teampageData','allMembers','teamArray'],
    data() {
        return {
            background: 'img/default/background/home-parallax.jpg',
            isModalOpen: false,
            selectedProfile: null,
            selectedTeam: 'All',
            searchInput: '',
        }
    },
    mounted(){
        this.$nextTick(()=>{
            if(window.location.href.indexOf('=') >0){
                this.searchInput =  window.location.href.slice(window.location.href.indexOf('=')+1,window.location.href.length).split('%20').join(' ');
                this.searchInput = this.searchInput.slice(0,-1);
                setTimeout(()=>{
                    window.history.replaceState(
                        null,
                        null,
                        this.$route('team')
                    )
                },50)

            }
        })
    },
    methods: {
        openModal(index) {
            this.selectedProfile = this.allMembers.filter(item=>{
                if(item.id === index){
                    return item;
                }
            });
            this.selectedProfile = this.selectedProfile[0]
            this.isModalOpen = true;
        },
        handleModalClose() {
            this.isModalOpen = false;
        }
    },
    computed: {
        filteredList() {
            return this.allMembers.filter(item =>{
                return item.name.toLowerCase().includes(this.searchInput.toLowerCase())
                || item.surname.toLowerCase().includes(this.searchInput.toLowerCase())
                || item.position.toLowerCase().includes(this.searchInput.toLowerCase())
                || item.email.toLowerCase().includes(this.searchInput.toLowerCase())
                || item.bio.toLowerCase().includes(this.searchInput.toLowerCase())
                || JSON.stringify(Array(item.publications)).toLowerCase().includes(this.searchInput.toLowerCase())
            })
        }
    },
}
</script>

<style scoped>
/* Parallax page style @ css/page-parallax.css*/
.top-text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.top-text > div:nth-child(2) {
    padding-left: 0;
}

.main-content {
    justify-content: center;
    align-items: center;
    margin-bottom: 75px;
    max-width: 1200px;
    min-height:500px;
    width: 100%;
    height: 100%;
    z-index: 9;
}

.searchbar-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 50px 0;
}

.searchbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 600px;
    z-index: 9;
}

.keywords svg,
.thema svg {
    margin-right: 1rem;
}

.searchbar input,
.searchbar select {
    padding: 10px;
    width: 150px;
    border-radius: 5px;
    border: none;
    color: white;
    background-color: rgba(10, 147, 150, 0.7);
}

.searchbar input:focus {
    text-decoration: none;
    outline: none;
}

.searchbar input::placeholder {
    color: rgba(240, 240, 240, .7);
}

.profil {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 250px;
    height:400px;
    margin: 25px 20px;
    padding: 0 10px 10px;
    background-color: rgba(26, 32, 44, 0.6);
    box-shadow: 2px 2px 2px 1px rgba(255, 255, 255, 0.1);
}

.profil img {
    margin-top: 10px;
}



.profil > * {
    z-index: 9;
}

.profil img {
    max-width: 150px;
    border-radius: 50%;
}

.profil button {
    margin: 1rem 0;
}


/* RESPONSIVE */

@media (max-width:1180px){
    .main-content{
        max-width:1100px;
    }
}

@media (max-width: 720px) {

    .top-text {
        flex-direction: column;
    }

    .main-content {
        padding: 50px 0;
    }

}

@media (max-width: 420px) {
    .searchbar {
        flex-direction: column;
    }

    .searchbar div {
        margin-top: 1rem;
    }
}

</style>
