<template>
    <Layout>
        <section class="w-100 p-4">
            <!--     Settings       -->
            <div class="card shadow">
                <div class="card-header">
                    Page Management
                </div>

                <ul class="nav nav-tabs d-flex justify-content-center mt-3">
                    <li class="nav-item">
                        <div @click="selected('home')" ref="home" class="nav-link active">Home</div>
                    </li>

                    <li class="nav-item">
                        <div @click="selected('teaching')" ref="teaching" class="nav-link">Teaching</div>
                    </li>

                    <li class="nav-item">
                        <div @click="selected('team')" ref="team" class="nav-link">Team</div>
                    </li>

                    <li class="nav-item">
                        <div @click="selected('research')" ref="research" class="nav-link">Research</div>
                    </li>

                    <li class="nav-item">
                        <div @click="selected('impact')" ref="impact" class="nav-link">Impact</div>
                    </li>

                    <li class="nav-item">
                        <div @click="selected('coop')" ref="coop" class="nav-link">Projects</div>
                    </li>

                    <li class="nav-item">
                        <div @click="selected('publications')" ref="publications" class="nav-link">Publications</div>
                    </li>

                    <li class="nav-item">
                        <div @click="selected('partners')" ref="partners" class="nav-link">Partners</div>
                    </li>

                    <li class="nav-item">
                        <div @click="selected('funding')" ref="funding" class="nav-link">Funding</div>
                    </li>

                    <li class="nav-item">
                        <div @click="selected('involved')" ref="involved" class="nav-link">Get Involved</div>
                    </li>

                </ul>

                <!--        HOMEPAGE PAGE FORM        -->

                <form ref="homeForm" @submit.prevent="submit('home')" method="post">
                    <div class="card-body d-flex flex-column  justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Home Header Image</h5>
                                <ToolTip class="p-2" :data="tooltipHome"></ToolTip>
                            </div>
                            <div class="input-group mb-3 ">
                                <input type="file"
                                       ref="homeHeaderUpload"
                                       @input="homeForm.headerImage = $event.target.files[0]"
                                       class="form-control">
                            </div>
                            <div v-if="$page.props.errors.headerImage" class="position-absolute bottom-0 w-100">
                                <p class="text-danger">{{ $page.props.errors.headerImage }}</p>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Reset header image to default</h5>
                                <ToolTip class="p-2" :data="tooltipHeaderReset"></ToolTip>
                            </div>

                            <div class="form-check form-switch d-flex justify-content-center">
                                <input v-model="homeForm.headerReset" class="form-check-input" type="checkbox"
                                       role="switch">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Header title & subtitle 1 </h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="homeForm.headerTitle1">
                                <label class="d-flex justify-content-between  w-110"><span>Header Title 1</span><span
                                    :class="[ this.charLeftHeaderTitle1 > 50 ? 'text-danger' : '']">{{
                                        this.charLeftHeaderTitle1
                                    }} / 50</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.headerTitle1">
                                <p class="text-danger">{{ $page.props.errors.headerTitle1 }}</p>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="homeForm.headerSubtitle1">
                                <label class="d-flex justify-content-between  w-110"><span>Header Subtitle 1</span><span
                                    :class="[ this.charLeftHeaderSubtitle1 > 75 ? 'text-danger' : '']">{{
                                        this.charLeftHeaderSubtitle1
                                    }} / 75</span></label>
                            </div>

                            <div class="mb-3" v-if="$page.props.errors.headerSubtitle1">
                                <p class="text-danger">{{ $page.props.errors.headerSubtitle1 }}</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Top title & subtitle 2</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="homeForm.headerTitle2">
                                <label class="d-flex justify-content-between w-110"><span>Header Title 2</span><span
                                    :class="[ this.charLeftHeaderTitle2 > 50 ? 'text-danger' : '']">{{
                                        this.charLeftHeaderTitle2
                                    }} / 50</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.headerTitle2">
                                <p class="text-danger">{{ $page.props.errors.headerTitle2 }}</p>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="homeForm.headerSubtitle2">
                                <label class="d-flex justify-content-between  w-110"><span>Header Subtitle 2</span><span
                                    :class="[ this.charLeftHeaderSubtitle2 > 75 ? 'text-danger' : '']">{{
                                        this.charLeftHeaderSubtitle2
                                    }} / 75</span></label>
                            </div>

                            <div class="mb-3" v-if="$page.props.errors.headerSubtitle1">
                                <p class="text-danger">{{ $page.props.errors.headerSubtitle2 }}</p>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Block 1</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="homeForm.blockTitle1">
                                <label class="d-flex justify-content-between  w-110"><span>Block Title 1</span><span
                                    :class="[ this.charLeftBlockTitle1 > 50 ? 'text-danger' : '']">{{
                                        this.charLeftBlockTitle1
                                    }} / 50</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.blockTitle1">
                                <p class="text-danger">{{ $page.props.errors.blockTitle1 }}</p>
                            </div>

                            <div class="form-floating">
                                <textarea class="form-control" v-model="homeForm.blockContent1"
                                          style="height: 225px"></textarea>
                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftBlockContent1 > 500 && this.charLeftBlockContent1 < 1000 ? 'text-warning bg-dark' : '', this.charLeftBlockContent1 > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftBlockContent1
                                    }} / 1000</span></label>
                            </div>

                            <div class="mb-3" v-if="$page.props.errors.blockContent1">
                                <p class="text-danger">{{ $page.props.errors.blockContent1 }}</p>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Map Block</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="homeForm.map_title">
                                <label class="d-flex justify-content-between  w-110"><span>Map Title</span><span
                                    :class="[ this.charLeftMapTitle > 50 ? 'text-danger' : '']">{{
                                        this.charLeftMapTitle
                                    }} / 50</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.map_title">
                                <p class="text-danger">{{ $page.props.errors.map_title }}</p>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="homeForm.map_url">
                                <label class="d-flex justify-content-between  w-110"><span>Map Url</span><span
                                    :class="[ this.charLeftMapUrl > 150 ? 'text-danger' : '']">{{
                                        this.charLeftMapUrl
                                    }} / 150</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.map_url">
                                <p class="text-danger">{{ $page.props.errors.map_url }}</p>
                            </div>

                            <div class="form-floating">
                                <textarea class="form-control" v-model="homeForm.map_description"
                                          style="height: 225px"></textarea>
                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftMapDescription > 500 && this.charLeftMapDescription < 1000 ? 'text-warning bg-dark' : '', this.charLeftMapDescription > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftMapDescription
                                    }} / 1000</span></label>
                            </div>

                            <div class="mb-3" v-if="$page.props.errors.map_description">
                                <p class="text-danger">{{ $page.props.errors.map_description }}</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Block 2</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="homeForm.blockTitle2">
                                <label class="d-flex justify-content-between  w-110"><span>Block Title 2</span><span
                                    :class="[ this.charLeftBlockTitle2 > 50 ? 'text-danger' : '']">{{
                                        this.charLeftBlockTitle2
                                    }} / 50</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.blockTitle2">
                                <p class="text-danger">{{ $page.props.errors.blockTitle2 }}</p>
                            </div>

                            <div class="form-floating">
                                <textarea class="form-control" v-model="homeForm.blockContent2"
                                          style="height: 225px"></textarea>
                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftBlockContent2 > 500 && this.charLeftBlockContent2 < 1000 ? 'text-warning bg-dark' : '', this.charLeftBlockContent2 > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftBlockContent2
                                    }} / 1000</span></label>
                            </div>

                            <div class="mb-3" v-if="$page.props.errors.blockContent2">
                                <p class="text-danger">{{ $page.props.errors.blockContent2 }}</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Block 3</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="homeForm.blockTitle3">
                                <label class="d-flex justify-content-between w-110"><span>Block Title 3</span><span
                                    :class="[ this.charLeftBlockTitle3 > 50 ? 'text-danger' : '']">{{
                                        this.charLeftBlockTitle3
                                    }} / 50</span></label>
                            </div>

                            <div class="mb-3" v-if="$page.props.errors.blockTitle3">
                                <p class="text-danger">{{ $page.props.errors.blockTitle3 }}</p>
                            </div>

                            <div class="form-floating">
                                <textarea class="form-control" v-model="homeForm.blockContent3"
                                          style="height: 225px"></textarea>
                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftBlockContent3 > 500 && this.charLeftBlockContent3 < 1000 ? 'text-warning bg-dark' : '', this.charLeftBlockContent3 > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftBlockContent3
                                    }} / 1000</span></label>
                            </div>

                            <div class="mb-3" v-if="$page.props.errors.blockContent3">
                                <p class="text-danger">{{ $page.props.errors.blockContent3 }}</p>
                            </div>
                        </div>

                        <Submit :loading="loading"></Submit>
                    </div>
                </form>


                <!--        TEACHING PAGE FORM        -->

                <form ref="teachingForm" @submit.prevent="submit('teaching')" method="post" class="hidden">
                    <div class="card-body d-flex flex-column  justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Teaching Header Image</h5>
                                <ToolTip class="p-2" :data="tooltipHome"></ToolTip>
                            </div>
                            <div class="input-group mb-3 ">
                                <input type="file"
                                       ref="teachingHeaderUpload"
                                       @input="teachingForm.headerImage = $event.target.files[0]"
                                       class="form-control">
                            </div>
                            <div v-if="$page.props.errors.teachingHeaderImage" class="position-absolute bottom-0 w-100">
                                <p class="text-danger">{{ $page.props.errors.teachingHeaderImage }}</p>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Reset header image to default</h5>
                                <ToolTip class="p-2" :data="tooltipHeaderReset"></ToolTip>
                            </div>

                            <div class="form-check form-switch d-flex justify-content-center">
                                <input v-model="teachingForm.headerReset" class="form-check-input" type="checkbox"
                                       role="switch">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Top Page Text Content</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="teachingForm.title">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Teaching page title</span><span
                                    :class="[ this.charLeftTeachingTitle > 17 ? 'text-danger' : '']">{{
                                        this.charLeftTeachingTitle
                                    }} / 17</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.teachingTitle">
                                <p class="text-danger">{{ $page.props.errors.teachingTitle }}</p>
                            </div>

                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="teachingForm.description"
                                          style="height: 225px"></textarea>
                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftTeachingDescription > 500 && this.charLeftTeachingDescription < 1000 ? 'text-warning bg-dark' : '', this.charLeftTeachingDescription > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftTeachingDescription
                                    }} / 1000</span></label>
                            </div>

                            <div class="mb-3" v-if="$page.props.errors.teachingDescription">
                                <p class="text-danger">{{ $page.props.errors.teachingDescription }}</p>
                            </div>
                        </div>

                        <Submit :loading="loading"></Submit>
                    </div>
                </form>


                <!--        TEAM PAGE FORM        -->


                <form ref="teamForm" @submit.prevent="submit('team')" method="post" class="hidden">
                    <div class="card-body d-flex flex-column  justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Team Header Image</h5>
                                <ToolTip class="p-2" :data="tooltipHome"></ToolTip>
                            </div>
                            <div class="input-group mb-3 ">
                                <input type="file"
                                       ref="teamHeaderUpload"
                                       @input="teamForm.headerImage = $event.target.files[0]"
                                       class="form-control">
                            </div>
                            <div v-if="$page.props.errors.teamHeaderImage" class="position-absolute bottom-0 w-100">
                                <p class="text-danger">{{ $page.props.errors.teamHeaderImage }}</p>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Reset header image to default</h5>
                                <ToolTip class="p-2" :data="tooltipHeaderReset"></ToolTip>
                            </div>

                            <div class="form-check form-switch d-flex justify-content-center">
                                <input v-model="teamForm.headerReset" class="form-check-input" type="checkbox"
                                       role="switch">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Top Page Text Content</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="teamForm.title">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Team page title</span><span
                                    :class="[ this.charLeftTeamTitle > 17 ? 'text-danger' : '']">{{
                                        this.charLeftTeamTitle
                                    }} / 17</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.teamTitle">
                                <p class="text-danger">{{ $page.props.errors.teamTitle }}</p>
                            </div>

                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="teamForm.description"
                                          style="height: 225px"></textarea>
                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftTeamDescription > 500 && this.charLeftTeamDescription < 1000 ? 'text-warning bg-dark' : '', this.charLeftTeamDescription > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftTeamDescription
                                    }} / 1000</span></label>
                            </div>

                            <div class="mb-3" v-if="$page.props.errors.teamDescription">
                                <p class="text-danger">{{ $page.props.errors.teamDescription }}</p>
                            </div>
                        </div>

                        <Submit :loading="loading"></Submit>
                    </div>
                </form>


                <!--        RESEARCH PAGE FORM        -->

                <form ref="researchForm" @submit.prevent="submit('research')" method="post" class="hidden">
                    <div class="card-body d-flex flex-column  justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Research Header Image</h5>
                                <ToolTip class="p-2" :data="tooltipHome"></ToolTip>
                            </div>
                            <div class="input-group mb-3 ">
                                <input type="file"
                                       ref="researchHeaderUpload"
                                       @input="researchForm.headerImage = $event.target.files[0]"
                                       class="form-control">
                            </div>
                            <div v-if="$page.props.errors.researchHeaderImage" class="position-absolute bottom-0 w-100">
                                <p class="text-danger">{{ $page.props.errors.researchHeaderImage }}</p>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Reset header image to default</h5>
                                <ToolTip class="p-2" :data="tooltipHeaderReset"></ToolTip>
                            </div>

                            <div class="form-check form-switch d-flex justify-content-center">
                                <input v-model="researchForm.headerReset" class="form-check-input" type="checkbox"
                                       role="switch">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Top Page Text Content</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="researchForm.title">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Research page title</span><span
                                    :class="[ this.charLeftResearchTitle > 17 ? 'text-danger' : '']">{{
                                        this.charLeftResearchTitle
                                    }} / 17</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.researchTitle">
                                <p class="text-danger">{{ $page.props.errors.researchTitle }}</p>
                            </div>

                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="researchForm.description"
                                          style="height: 225px"></textarea>
                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftResearchDescription > 500 && this.charLeftResearchDescription < 1000 ? 'text-warning bg-dark' : '', this.charLeftResearchDescription > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftResearchDescription
                                    }} / 1000</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.researchDescription">
                                <p class="text-danger">{{ $page.props.errors.researchDescription }}</p>
                            </div>


                            <!--        RESEARCH BLOCK 1        -->
                            <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative w-100">
                                <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                    <h5 class="card-title m-0">Research Block Image 1</h5>
                                    <ToolTip class="p-2" :data="toolTipResearchImage"></ToolTip>
                                </div>
                                <div class="input-group mb-3 ">
                                    <input type="file"
                                           ref="researchBlockImage1"
                                           @input="researchForm.block_image_1 = $event.target.files[0]"
                                           class="form-control">
                                </div>
                                <div v-if="$page.props.errors.researchBlockImage1"
                                     class="position-absolute bottom-0 w-100">
                                    <p class="text-danger">{{ $page.props.errors.researchBlockImage1 }}</p>
                                </div>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="researchForm.block_title_1">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Research block title 1</span><span
                                    :class="[ this.charLeftResearchBlockTitle1 > 17 ? 'text-danger' : '']">{{
                                        this.charLeftResearchBlockTitle1
                                    }} / 17</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.researchBlockTitle1">
                                <p class="text-danger">{{ $page.props.errors.researchBlockTitle1 }}</p>
                            </div>

                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="researchForm.block_content_1"
                                          style="height: 225px"></textarea>
                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftResearchBlockContent1 > 500 && this.charLeftResearchBlockContent1 < 1000 ? 'text-warning bg-dark' : '', this.charLeftResearchBlockContent1 > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftResearchBlockContent1
                                    }} / 1000</span></label>
                            </div>

                            <div class="mb-3" v-if="$page.props.errors.researchBlockContent1">
                                <p class="text-danger">{{ $page.props.errors.researchBlockContent1 }}</p>
                            </div>

                            <!--        RESEARCH BLOCK 2        -->
                            <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative w-100">
                                <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                    <h5 class="card-title m-0">Research Block Image 2</h5>
                                    <ToolTip class="p-2" :data="toolTipResearchImage"></ToolTip>
                                </div>
                                <div class="input-group mb-3 ">
                                    <input type="file"
                                           ref="researchBlockImage2"
                                           @input="researchForm.block_image_2 = $event.target.files[0]"
                                           class="form-control">
                                </div>
                                <div v-if="$page.props.errors.researchBlockImage2"
                                     class="position-absolute bottom-0 w-100">
                                    <p class="text-danger">{{ $page.props.errors.researchBlockImage2 }}</p>
                                </div>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="researchForm.block_title_2">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Research block title 2</span><span
                                    :class="[ this.charLeftResearchBlockTitle2 > 50 ? 'text-danger' : '']">{{
                                        this.charLeftResearchBlockTitle2
                                    }} / 17</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.researchBlockTitle2">
                                <p class="text-danger">{{ $page.props.errors.researchBlockTitle2 }}</p>
                            </div>

                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="researchForm.block_content_2"
                                          style="height: 225px"></textarea>
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Research block content 2</span></label>
                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftResearchBlockContent2 > 500 && this.charLeftResearchBlockContent2 < 1000 ? 'text-warning bg-dark' : '', this.charLeftResearchBlockContent2 > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftResearchBlockContent2
                                    }} / 1000</span></label>
                            </div>

                            <div class="mb-3" v-if="$page.props.errors.researchBlockContent2">
                                <p class="text-danger">{{ $page.props.errors.researchBlockContent2 }}</p>
                            </div>


                            <!--        RESEARCH BLOCK 3        -->
                            <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative w-100">
                                <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                    <h5 class="card-title m-0">Research Block Image 3</h5>
                                    <ToolTip class="p-2" :data="toolTipResearchImage"></ToolTip>
                                </div>
                                <div class="input-group mb-3 ">
                                    <input type="file"
                                           ref="researchBlockImage3"
                                           @input="researchForm.block_image_3 = $event.target.files[0]"
                                           class="form-control">
                                </div>
                                <div v-if="$page.props.errors.researchBlockImage3"
                                     class="position-absolute bottom-0 w-100">
                                    <p class="text-danger">{{ $page.props.errors.researchBlockImage3 }}</p>
                                </div>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="researchForm.block_title_3">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Research block title 3</span><span
                                    :class="[ this.charLeftResearchBlockTitle3 > 17 ? 'text-danger' : '']">{{
                                        this.charLeftResearchBlockTitle3
                                    }} / 17</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.researchBlockTitle3">
                                <p class="text-danger">{{ $page.props.errors.researchBlockTitle3 }}</p>
                            </div>

                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="researchForm.block_content_3"
                                          style="height: 225px"></textarea>
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Research block content 3</span>
                                </label>

                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftResearchBlockContent3 > 500 && this.charLeftResearchBlockContent3 < 1000 ? 'text-warning bg-dark' : '', this.charLeftResearchBlockContent3 > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftResearchBlockContent3
                                    }} / 1000</span></label>
                            </div>

                            <div class="mb-3" v-if="$page.props.errors.researchBlockContent3">
                                <p class="text-danger">{{ $page.props.errors.researchBlockContent3 }}</p>
                            </div>


                            <!--        RESEARCH BLOCK 4        -->
                            <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative w-100">
                                <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                    <h5 class="card-title m-0">Research Block Image 4</h5>
                                    <ToolTip class="p-2" :data="toolTipResearchImage"></ToolTip>
                                </div>
                                <div class="input-group mb-3 ">
                                    <input type="file"
                                           ref="researchBlockImage4"
                                           @input="researchForm.block_image_4 = $event.target.files[0]"
                                           class="form-control">
                                </div>
                                <div v-if="$page.props.errors.researchBlockImage4"
                                     class="position-absolute bottom-0 w-100">
                                    <p class="text-danger">{{ $page.props.errors.researchBlockImage4 }}</p>
                                </div>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="researchForm.block_title_4">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Research block title 4</span><span
                                    :class="[ this.charLeftResearchBlockTitle4 > 17 ? 'text-danger' : '']">{{
                                        this.charLeftResearchBlockTitle4
                                    }} / 17</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.researchBlockTitle4">
                                <p class="text-danger">{{ $page.props.errors.researchBlockTitle4 }}</p>
                            </div>

                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="researchForm.block_content_4"
                                          style="height: 225px"></textarea>
                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftResearchBlockContent4 > 500 && this.charLeftResearchBlockContent4 < 1000 ? 'text-warning bg-dark' : '', this.charLeftResearchBlockContent4 > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftResearchBlockContent4
                                    }} / 1000</span></label>
                            </div>

                            <div class="mb-3" v-if="$page.props.errors.researchBlockContent4">
                                <p class="text-danger">{{ $page.props.errors.researchBlockContent4 }}</p>
                            </div>


                        </div>
                        <Submit :loading="loading"></Submit>
                    </div>
                </form>

                <!--        IMPACT PAGE FORM        -->

                <form ref="impactForm" @submit.prevent="submit('impact')" method="post" class="hidden">
                    <div class="card-body d-flex flex-column  justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Impact Header Image</h5>
                                <ToolTip class="p-2" :data="tooltipHome"></ToolTip>
                            </div>
                            <div class="input-group mb-3 ">
                                <input type="file"
                                       ref="impactHeaderUpload"
                                       @input="impactForm.headerImage = $event.target.files[0]"
                                       class="form-control">
                            </div>
                            <div v-if="$page.props.errors.impactHeaderImage" class="position-absolute bottom-0 w-100">
                                <p class="text-danger">{{ $page.props.errors.impactHeaderImage }}</p>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Reset header image to default</h5>
                                <ToolTip class="p-2" :data="tooltipHeaderReset"></ToolTip>
                            </div>

                            <div class="form-check form-switch d-flex justify-content-center">
                                <input v-model="impactForm.headerReset" class="form-check-input" type="checkbox"
                                       role="switch">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Top Page Text Content</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="impactForm.title">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Impact page title</span><span
                                    :class="[ this.charLeftImpactTitle > 17 ? 'text-danger' : '']">{{
                                        this.charLeftImpactTitle
                                    }} / 17</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.impactTitle">
                                <p class="text-danger">{{ $page.props.errors.impactTitle }}</p>
                            </div>

                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="impactForm.description"
                                          style="height: 225px"></textarea>

                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftImpactDescription > 500 && this.charLeftImpactDescription < 1000 ? 'text-warning bg-dark' : '', this.charLeftImpactDescription > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftImpactDescription
                                    }} / 1000</span></label>

                            </div>

                            <div class="mb-3" v-if="$page.props.errors.impactDescription">
                                <p class="text-danger">{{ $page.props.errors.impactDescription }}</p>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Data Content</h5>
                            </div>

                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h6 class="card-title m-0">Data 1</h6>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="impactForm.data_title_1">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Data title 1</span><span
                                    :class="[ this.charLeftImpactDataTitle1 > 25 ? 'text-danger' : '']">{{
                                        this.charLeftImpactDataTitle1
                                    }} / 25</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.impactDataTitle1">
                                <p class="text-danger">{{ $page.props.errors.impactDataTitle1 }}</p>
                            </div>


                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="impactForm.data_content_1">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Data content 1</span><span
                                    :class="[ this.charLeftImpactDataContent1 > 75 ? 'text-danger' : '']">{{
                                        this.charLeftImpactDataContent1
                                    }} / 75</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.impactDataContent1">
                                <p class="text-danger">{{ $page.props.errors.impactDataContent1 }}</p>
                            </div>

                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h6 class="card-title m-0">Data 2</h6>
                            </div>


                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="impactForm.data_title_2">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Data title 2</span><span
                                    :class="[ this.charLeftImpactDataTitle2 > 25 ? 'text-danger' : '']">{{
                                        this.charLeftImpactDataTitle2
                                    }} / 25</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.impactDataTitle2">
                                <p class="text-danger">{{ $page.props.errors.impactDataTitle2 }}</p>
                            </div>


                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="impactForm.data_content_2">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Data content 2</span><span
                                    :class="[ this.charLeftImpactDataContent2 > 75 ? 'text-danger' : '']">{{
                                        this.charLeftImpactDataContent2
                                    }} / 75</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.impactDataContent2">
                                <p class="text-danger">{{ $page.props.errors.impactDataContent2 }}</p>
                            </div>


                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h6 class="card-title m-0">Data 3</h6>
                            </div>


                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="impactForm.data_title_3">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Data title 3</span><span
                                    :class="[ this.charLeftImpactDataTitle3 > 25 ? 'text-danger' : '']">{{
                                        this.charLeftImpactDataTitle3
                                    }} / 25</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.impactDataTitle3">
                                <p class="text-danger">{{ $page.props.errors.impactDataTitle3 }}</p>
                            </div>


                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="impactForm.data_content_3">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Data content 3</span><span
                                    :class="[ this.charLeftImpactDataContent3 > 75 ? 'text-danger' : '']">{{
                                        this.charLeftImpactDataContent3
                                    }} / 75</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.impactDataContent3">
                                <p class="text-danger">{{ $page.props.errors.impactDataContent3 }}</p>
                            </div>

                        </div>


                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Paragraphe 1</h5>
                            </div>


                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="impactForm.paragraphe_1"
                                          style="height: 225px"></textarea>

                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftImpactParagraphe1 > 500 && this.charLeftImpactParagraphe1 < 1000 ? 'text-warning bg-dark' : '', this.charLeftImpactParagraphe1 > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftImpactParagraphe1
                                    }} / 1000</span></label>

                            </div>

                            <div class="mb-3" v-if="$page.props.errors.impactParagraphe1">
                                <p class="text-danger">{{ $page.props.errors.impactParagraphe1 }}</p>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Text Block & Image</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="impactForm.block_title">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Impact block title</span><span
                                    :class="[ this.charLeftImpactBlockTitle > 25 ? 'text-danger' : '']">{{
                                        this.charLeftImpactBlockTitle
                                    }} / 25</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.impactBlockTitle">
                                <p class="text-danger">{{ $page.props.errors.impactBlockTitle }}</p>
                            </div>

                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="impactForm.block_content"
                                          style="height: 225px"></textarea>

                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftImpactBlockContent > 500 && this.charLeftImpactBlockContent < 1000 ? 'text-warning bg-dark' : '', this.charLeftImpactBlockContent > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftImpactBlockContent
                                    }} / 1000</span></label>

                            </div>

                            <div class="mb-3" v-if="$page.props.errors.impactBlockContent">
                                <p class="text-danger">{{ $page.props.errors.impactBlockContent }}</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Impact block image</h5>
                                <ToolTip class="p-2" :data="toolTipBlockImage"></ToolTip>
                            </div>
                            <div class="input-group mb-3 ">
                                <input type="file"
                                       ref="impactBlockImage"
                                       @input="impactForm.block_image = $event.target.files[0]"
                                       class="form-control">
                            </div>
                            <div v-if="$page.props.errors.impactBlockImage" class="position-absolute bottom-0 w-100">
                                <p class="text-danger">{{ $page.props.errors.impactBlockImage }}</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Paragraphe 2</h5>
                            </div>


                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="impactForm.paragraphe_2"
                                          style="height: 225px"></textarea>

                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftImpactParagraphe2 > 500 && this.charLeftImpactParagraphe2 < 1000 ? 'text-warning bg-dark' : '', this.charLeftImpactParagraphe2 > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftImpactParagraphe2
                                    }} / 1000</span></label>

                            </div>

                            <div class="mb-3" v-if="$page.props.errors.impactParagraphe2">
                                <p class="text-danger">{{ $page.props.errors.impactParagraphe2 }}</p>
                            </div>
                        </div>


                        <Submit :loading="loading"></Submit>
                    </div>
                </form>

                <!--        COOP PAGE FORM        -->

                <form ref="coopForm" @submit.prevent="submit('coop')" method="post" class="hidden">
                    <div class="card-body d-flex flex-column  justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Projects Header Image</h5>
                                <ToolTip class="p-2" :data="tooltipHome"></ToolTip>
                            </div>
                            <div class="input-group mb-3 ">
                                <input type="file"
                                       ref="coopHeaderUpload"
                                       @input="coopForm.headerImage = $event.target.files[0]"
                                       class="form-control">
                            </div>
                            <div v-if="$page.props.errors.coopHeaderImage" class="position-absolute bottom-0 w-100">
                                <p class="text-danger">{{ $page.props.errors.coopHeaderImage }}</p>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Reset header image to default</h5>
                                <ToolTip class="p-2" :data="tooltipHeaderReset"></ToolTip>
                            </div>

                            <div class="form-check form-switch d-flex justify-content-center">
                                <input v-model="coopForm.headerReset" class="form-check-input" type="checkbox"
                                       role="switch">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Top Page Text Content</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="coopForm.title">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Coop page title</span><span
                                    :class="[ this.charLeftCoopTitle > 17 ? 'text-danger' : '']">{{
                                        this.charLeftCoopTitle
                                    }} / 17</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.coopTitle">
                                <p class="text-danger">{{ $page.props.errors.coopTitle }}</p>
                            </div>

                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="coopForm.description"
                                          style="height: 225px"></textarea>

                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftCoopDescription > 500 && this.charLeftCoopDescription < 1000 ? 'text-warning bg-dark' : '', this.charLeftCoopDescription > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftCoopDescription
                                    }} / 1000</span></label>

                            </div>

                            <div class="mb-3" v-if="$page.props.errors.coopDescription">
                                <p class="text-danger">{{ $page.props.errors.coopDescription }}</p>
                            </div>
                        </div>
                        <Submit :loading="loading"></Submit>
                    </div>
                </form>

                <!--        PUBLICATIONS PAGE FORM        -->

                <form ref="publicationsForm" @submit.prevent="submit('publications')" method="post" class="hidden">
                    <div class="card-body d-flex flex-column  justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Publications Header Image</h5>
                                <ToolTip class="p-2" :data="tooltipHome"></ToolTip>
                            </div>
                            <div class="input-group mb-3 ">
                                <input type="file"
                                       ref="publicationsHeaderUpload"
                                       @input="publicationsForm.headerImage = $event.target.files[0]"
                                       class="form-control">
                            </div>
                            <div v-if="$page.props.errors.publicationsHeaderImage"
                                 class="position-absolute bottom-0 w-100">
                                <p class="text-danger">{{ $page.props.errors.publicationsHeaderImage }}</p>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Reset header image to default</h5>
                                <ToolTip class="p-2" :data="tooltipHeaderReset"></ToolTip>
                            </div>

                            <div class="form-check form-switch d-flex justify-content-center">
                                <input v-model="publicationsForm.headerReset" class="form-check-input" type="checkbox"
                                       role="switch">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Top Page Text Content</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="publicationsForm.title">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Publications page title</span><span
                                    :class="[ this.charLeftPublicationsTitle > 17 ? 'text-danger' : '']">{{
                                        this.charLeftPublicationsTitle
                                    }} / 17</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.publicationsTitle">
                                <p class="text-danger">{{ $page.props.errors.publicationsTitle }}</p>
                            </div>

                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="publicationsForm.description"
                                          style="height: 225px"></textarea>

                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftPublicationsDescription > 500 && this.charLeftPublicationsDescription < 1000 ? 'text-warning bg-dark' : '', this.charLeftPublicationsDescription > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftPublicationsDescription
                                    }} / 1000</span></label>

                            </div>

                            <div class="mb-3" v-if="$page.props.errors.publicationsDescription">
                                <p class="text-danger">{{ $page.props.errors.publicationsDescription }}</p>
                            </div>
                        </div>
                        <Submit :loading="loading"></Submit>
                    </div>
                </form>

                <!--        PARTNERS PAGE FORM        -->


                <form ref="partnersForm" @submit.prevent="submit('partners')" method="post" class="hidden">
                    <div class="card-body d-flex flex-column  justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Partners Header Image</h5>
                                <ToolTip class="p-2" :data="tooltipHome"></ToolTip>
                            </div>
                            <div class="input-group mb-3 ">
                                <input type="file"
                                       ref="partnersHeaderUpload"
                                       @input="partnersForm.headerImage = $event.target.files[0]"
                                       class="form-control">
                            </div>
                            <div v-if="$page.props.errors.partnersHeaderImage" class="position-absolute bottom-0 w-100">
                                <p class="text-danger">{{ $page.props.errors.partnersHeaderImage }}</p>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Reset header image to default</h5>
                                <ToolTip class="p-2" :data="tooltipHeaderReset"></ToolTip>
                            </div>

                            <div class="form-check form-switch d-flex justify-content-center">
                                <input v-model="partnersForm.headerReset" class="form-check-input" type="checkbox"
                                       role="switch">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Top Page Text Content</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="partnersForm.title">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Partners page title</span><span
                                    :class="[ this.charLeftPartnersTitle > 17 ? 'text-danger' : '']">{{
                                        this.charLeftPartnersTitle
                                    }} / 17</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.partnersTitle">
                                <p class="text-danger">{{ $page.props.errors.partnersTitle }}</p>
                            </div>

                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="partnersForm.description"
                                          style="height: 225px"></textarea>

                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftPartnersDescription > 500 && this.charLeftPartnersDescription < 1000 ? 'text-warning bg-dark' : '', this.charLeftPartnersDescription > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftPartnersDescription
                                    }} / 1000</span></label>

                            </div>

                            <div class="mb-3" v-if="$page.props.errors.partnersDescription">
                                <p class="text-danger">{{ $page.props.errors.partnersDescription }}</p>
                            </div>
                        </div>
                        <Submit :loading="loading"></Submit>
                    </div>
                </form>

                <!--        FUNDING PAGE FORM        -->

                <form ref="fundingForm" @submit.prevent="submit('funding')" method="post" class="hidden">
                    <div class="card-body d-flex flex-column  justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Funding Header Image</h5>
                                <ToolTip class="p-2" :data="tooltipHome"></ToolTip>
                            </div>
                            <div class="input-group mb-3 ">
                                <input type="file"
                                       ref="fundingHeaderUpload"
                                       @input="fundingForm.headerImage = $event.target.files[0]"
                                       class="form-control">
                            </div>
                            <div v-if="$page.props.errors.fundingHeaderImage" class="position-absolute bottom-0 w-100">
                                <p class="text-danger">{{ $page.props.errors.fundingHeaderImage }}</p>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Reset header image to default</h5>
                                <ToolTip class="p-2" :data="tooltipHeaderReset"></ToolTip>
                            </div>

                            <div class="form-check form-switch d-flex justify-content-center">
                                <input v-model="fundingForm.headerReset" class="form-check-input" type="checkbox"
                                       role="switch">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Top Page Text Content</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="fundingForm.title">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Funding page title</span><span
                                    :class="[ this.charLeftFundingTitle > 17 ? 'text-danger' : '']">{{
                                        this.charLeftFundingTitle
                                    }} / 17</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.fundingTitle">
                                <p class="text-danger">{{ $page.props.errors.fundingTitle }}</p>
                            </div>

                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="fundingForm.description"
                                          style="height: 225px"></textarea>

                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftFundingDescription > 500 && this.charLeftFundingDescription < 1000 ? 'text-warning bg-dark' : '', this.charLeftFundingDescription > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftFundingDescription
                                    }} / 1000</span></label>

                            </div>

                            <div class="mb-3" v-if="$page.props.errors.fundingDescription">
                                <p class="text-danger">{{ $page.props.errors.fundingDescription }}</p>
                            </div>
                        </div>
                        <Submit :loading="loading"></Submit>
                    </div>
                </form>

                <!--        FUNDING PAGE FORM        -->

                <form ref="involvedForm" @submit.prevent="submit('involved')" method="post" class="hidden">
                    <div class="card-body d-flex flex-column  justify-content-center align-items-center">
                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Involved Header Image</h5>
                                <ToolTip class="p-2" :data="tooltipHome"></ToolTip>
                            </div>
                            <div class="input-group mb-3 ">
                                <input type="file"
                                       ref="involvedHeaderUpload"
                                       @input="involvedForm.headerImage = $event.target.files[0]"
                                       class="form-control">
                            </div>
                            <div v-if="$page.props.errors.involvedHeaderImage" class="position-absolute bottom-0 w-100">
                                <p class="text-danger">{{ $page.props.errors.involvedHeaderImage }}</p>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Reset header image to default</h5>
                                <ToolTip class="p-2" :data="tooltipHeaderReset"></ToolTip>
                            </div>

                            <div class="form-check form-switch d-flex justify-content-center">
                                <input v-model="involvedForm.headerReset" class="form-check-input" type="checkbox"
                                       role="switch">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Top Page Text Content</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="involvedForm.title">
                                <label class="d-flex justify-content-between  w-110"><span class="text-danger">Involved page title</span><span
                                    :class="[ this.charLeftInvolvedTitle > 17 ? 'text-danger' : '']">{{
                                        this.charLeftInvolvedTitle
                                    }} / 17</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.involvedTitle">
                                <p class="text-danger">{{ $page.props.errors.involvedTitle }}</p>
                            </div>

                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="involvedForm.description"
                                          style="height: 225px"></textarea>

                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftInvolvedDescription > 500 && this.charLeftInvolvedDescription < 1000 ? 'text-warning bg-dark' : '', this.charLeftInvolvedDescription > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftInvolvedDescription
                                    }} / 1000</span></label>

                            </div>

                            <div class="mb-3" v-if="$page.props.errors.involvedDescription">
                                <p class="text-danger">{{ $page.props.errors.involvedDescription }}</p>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Text Block & Image</h5>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" v-model="involvedForm.block_title">
                                <label
                                    class="d-flex justify-content-between  w-110"><span>Get involved block title</span><span
                                    :class="[ this.charLeftInvolvedBlockTitle > 25 ? 'text-danger' : '']">{{
                                        this.charLeftInvolvedBlockTitle
                                    }} / 25</span></label>
                            </div>
                            <div class="mb-3" v-if="$page.props.errors.involvedBlockTitle">
                                <p class="text-danger">{{ $page.props.errors.involvedBlockTitle }}</p>
                            </div>

                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="involvedForm.block_content"
                                          style="height: 225px"></textarea>

                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftInvolvedBlockContent > 500 && this.charLeftInvolvedBlockContent < 1000 ? 'text-warning bg-dark' : '', this.charLeftInvolvedBlockContent > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftInvolvedBlockContent
                                    }} / 1000</span></label>

                            </div>

                            <div class="mb-3" v-if="$page.props.errors.involvedBlockContent">
                                <p class="text-danger">{{ $page.props.errors.involvedBlockContent }}</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 pb-3 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Involved block image</h5>
                                <ToolTip class="p-2" :data="toolTipBlockImage"></ToolTip>
                            </div>
                            <div class="input-group mb-3 ">
                                <input type="file"
                                       ref="involvedBlockImage"
                                       @input="involvedForm.block_image = $event.target.files[0]"
                                       class="form-control">
                            </div>
                            <div v-if="$page.props.errors.involvedBlockImage" class="position-absolute bottom-0 w-100">
                                <p class="text-danger">{{ $page.props.errors.involvedBlockImage }}</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-10 col-sm-12 mt-2 text-center position-relative">
                            <div class="d-flex flex-row justify-content-center align-items-center mt-2 mb-3">
                                <h5 class="card-title m-0">Paragraphe</h5>
                            </div>


                            <div class="form-floating mb-3">
                                <textarea class="form-control" v-model="involvedForm.paragraph"
                                          style="height: 225px"></textarea>

                                <label class="d-flex justify-content-between  w-110"><span>Block Content 1</span><span
                                    style="height:22px!important; opacity:100% !important; "
                                    :class="[ this.charLeftInvolvedParagraph > 500 && this.charLeftInvolvedParagraph < 1000 ? 'text-warning bg-dark' : '', this.charLeftInvolvedParagraph > 1000 ? 'text-danger bg-dark' : '' ] ">{{
                                        this.charLeftInvolvedParagraph
                                    }} / 1000</span></label>

                            </div>

                            <div class="mb-3" v-if="$page.props.errors.involvedParagraph">
                                <p class="text-danger">{{ $page.props.errors.involvedParagraph }}</p>
                            </div>
                        </div>


                        <Submit :loading="loading"></Submit>
                    </div>
                </form>


            </div>
        </section>

        <SuccessToaster v-show="showToaster" @handleToaster="handleToaster"></SuccessToaster>
        <ModalError v-show="showModal" @handleModal="handleModal" :style="{ top : scrollPos + 'px' }"></ModalError>

    </Layout>
</template>

<script>
import Submit from "../../Components/Button/Submit";
import Layout from "./Shared/Layout";
import SuccessToaster from "../../Components/Status/SuccessToaster";
import ModalError from "../../Components/Status/ModalError";
import ToolTip from "../../Components/Info/ToolTip";

export default {
    name: "PageManagement",
    props: ['homeData', 'teachingData', 'teamData', 'researchData', 'impactData', 'coopData', 'publicationsData', 'partnersData', 'fundingData', 'involvedData'],
    components: {
        Layout,
        Submit,
        SuccessToaster,
        ModalError,
        ToolTip
    },
    data() {
        return {
            loading: false,
            showToaster: true,
            showModal: true,
            scrollPos: null,
            tooltipHome: 'Change header image. Dimension ratio for image must be 2/3, only .jpg .jpeg or .png . Max weight 1mb.',
            tooltipHeaderReset: 'Reset the header image to default',
            toolTipResearchImage: 'Change block image. Dimension ratio for image must be 1/1, only .jpg .jpeg or .png . Max weight 1mb.',
            toolTipBlockImage: 'Change block image. Dimension ratio for image must be 2/3, only .jpg .jpeg or .png . Max weight 1mb.',
            homeForm: {
                headerImage: null,
                headerReset: null,
                headerTitle1: this.homeData.header_title_1 ? this.homeData.header_title_1 : '',
                headerSubtitle1: this.homeData.header_subtitle_1 ? this.homeData.header_subtitle_1 : '',
                headerTitle2: this.homeData.header_title_2 ? this.homeData.header_title_2 : '',
                headerSubtitle2: this.homeData.header_subtitle_2 ? this.homeData.header_subtitle_2 : '',
                blockTitle1: this.homeData.block_title_1 ? this.homeData.block_title_1 : '',
                blockContent1: this.homeData.block_content_1 ? this.homeData.block_content_1 : '',
                map_title: this.homeData.map_title ? this.homeData.map_title : '',
                map_url: this.homeData.map_url ? this.homeData.map_url : '',
                map_description: this.homeData.map_description ? this.homeData.map_description : '',
                blockTitle2: this.homeData.block_title_2 ? this.homeData.block_title_2 : '',
                blockContent2: this.homeData.block_content_2 ? this.homeData.block_content_2 : '',
                blockTitle3: this.homeData.block_title_3 ? this.homeData.block_title_3 : '',
                blockContent3: this.homeData.block_content_3 ? this.homeData.block_content_3 : '',
            },
            teachingForm: {
                headerImage: null,
                headerReset: null,
                title: this.teachingData.page_title,
                description: this.teachingData.page_description,
            },
            teamForm: {
                headerImage: null,
                headerReset: null,
                title: this.teamData.page_title,
                description: this.teamData.page_description,
            },
            researchForm: {
                headerImage: null,
                headerReset: null,
                title: this.researchData.page_title,
                description: this.researchData.page_description,
                block_title_1: this.researchData.block_title_1,
                block_content_1: this.researchData.block_content_1,
                block_image_1: null,
                block_title_2: this.researchData.block_title_2,
                block_content_2: this.researchData.block_content_2,
                block_image_2: null,
                block_title_3: this.researchData.block_title_3,
                block_content_3: this.researchData.block_content_3,
                block_image_3: null,
                block_title_4: this.researchData.block_title_4,
                block_content_4: this.researchData.block_content_4,
                block_image_4: null,
            },
            impactForm: {
                headerImage: null,
                headerReset: null,
                title: this.impactData.page_title,
                description: this.impactData.page_description,
                data_title_1: this.impactData.data_title_1,
                data_content_1: this.impactData.data_content_1,
                data_title_2: this.impactData.data_title_2,
                data_content_2: this.impactData.data_content_2,
                data_title_3: this.impactData.data_title_3,
                data_content_3: this.impactData.data_content_3,
                paragraphe_1: this.impactData.paragraphe_1,
                block_title: this.impactData.block_title,
                block_content: this.impactData.block_content,
                block_image: null,
                paragraphe_2: this.impactData.paragraphe_2,

            },
            coopForm: {
                headerImage: null,
                headerReset: null,
                title: this.coopData.page_title,
                description: this.coopData.page_description,
            },
            publicationsForm: {
                headerImage: null,
                headerReset: null,
                title: this.publicationsData.page_title,
                description: this.publicationsData.page_description,
            },
            partnersForm: {
                headerImage: null,
                headerReset: null,
                title: this.partnersData.page_title,
                description: this.partnersData.page_description,
            },
            fundingForm: {
                headerImage: null,
                headerReset: null,
                title: this.fundingData.page_title,
                description: this.fundingData.page_description,
            },
            involvedForm: {
                headerImage: null,
                headerReset: null,
                title: this.involvedData.page_title,
                description: this.involvedData.page_description,
                block_title: this.involvedData.block_title,
                block_content: this.involvedData.block_description,
                block_image: null,
                paragraph: this.involvedData.paragraph

            },
        }
    },
    computed: {
        error: function () {
            return this.$page.props.errors.maxAttempt;
        },
        charLeftHeaderTitle1: function () {
            return this.charLeft(this.homeForm.headerTitle1)
        },
        charLeftHeaderSubtitle1: function () {
            return this.charLeft(this.homeForm.headerSubtitle1)
        },
        charLeftHeaderTitle2: function () {
            return this.charLeft(this.homeForm.headerTitle2)
        },
        charLeftHeaderSubtitle2: function () {
            return this.charLeft(this.homeForm.headerSubtitle2)
        },
        charLeftBlockTitle1: function () {
            return this.charLeft(this.homeForm.blockTitle1)
        },
        charLeftBlockContent1: function () {
            return this.charLeft(this.homeForm.blockContent1)
        },
        charLeftMapTitle: function () {
            return this.charLeft(this.homeForm.map_title)
        },
        charLeftMapUrl: function () {
            return this.charLeft(this.homeForm.map_url)
        },
        charLeftMapDescription: function () {
            return this.charLeft(this.homeForm.map_description)
        },
        charLeftBlockTitle2: function () {
            return this.charLeft(this.homeForm.blockTitle2)
        },
        charLeftBlockContent2: function () {
            return this.charLeft(this.homeForm.blockContent2)
        },
        charLeftBlockTitle3: function () {
            return this.charLeft(this.homeForm.blockTitle3)
        },
        charLeftBlockContent3: function () {
            return this.charLeft(this.homeForm.blockContent3)
        },
        charLeftTeachingTitle: function () {
            return this.charLeft(this.teachingForm.title)
        },
        charLeftTeachingDescription: function () {
            return this.charLeft(this.teachingForm.description)
        },
        charLeftTeamTitle: function () {
            return this.charLeft(this.teamForm.title)
        },
        charLeftTeamDescription: function () {
            return this.charLeft(this.teamForm.description)
        },
        charLeftResearchTitle: function () {
            return this.charLeft(this.researchForm.title)
        },
        charLeftResearchDescription: function () {
            return this.charLeft(this.researchForm.description)
        },
        charLeftResearchBlockTitle1: function () {
            return this.charLeft(this.researchForm.block_title_1)
        },
        charLeftResearchBlockContent1: function () {
            return this.charLeft(this.researchForm.block_content_1)
        },
        charLeftResearchBlockTitle2: function () {
            return this.charLeft(this.researchForm.block_title_2)
        },
        charLeftResearchBlockContent2: function () {
            return this.charLeft(this.researchForm.block_content_2)
        },
        charLeftResearchBlockTitle3: function () {
            return this.charLeft(this.researchForm.block_title_3)
        },
        charLeftResearchBlockContent3: function () {
            return this.charLeft(this.researchForm.block_content_3)
        },
        charLeftResearchBlockTitle4: function () {
            return this.charLeft(this.researchForm.block_title_4)
        },
        charLeftResearchBlockContent4: function () {
            return this.charLeft(this.researchForm.block_content_4)
        },
        charLeftImpactTitle: function () {
            return this.charLeft(this.impactForm.title)
        },
        charLeftImpactDescription: function () {
            return this.charLeft(this.impactForm.description)
        },
        charLeftImpactDataTitle1: function () {
            return this.charLeft(this.impactForm.data_title_1)
        },
        charLeftImpactDataContent1: function () {
            return this.charLeft(this.impactForm.data_content_1)
        },
        charLeftImpactDataTitle2: function () {
            return this.charLeft(this.impactForm.data_title_2)
        },
        charLeftImpactDataContent2: function () {
            return this.charLeft(this.impactForm.data_content_2)
        },
        charLeftImpactDataTitle3: function () {
            return this.charLeft(this.impactForm.data_title_3)
        },
        charLeftImpactDataContent3: function () {
            return this.charLeft(this.impactForm.data_content_3)
        },
        charLeftImpactParagraphe1: function () {
            return this.charLeft(this.impactForm.paragraphe_1)
        },
        charLeftImpactBlockTitle: function () {
            return this.charLeft(this.impactForm.block_title)
        },
        charLeftImpactBlockContent: function () {
            return this.charLeft(this.impactForm.block_content)
        },
        charLeftImpactParagraphe2: function () {
            return this.charLeft(this.impactForm.paragraphe_2)
        },
        charLeftCoopTitle: function () {
            return this.charLeft(this.coopForm.title)
        },
        charLeftCoopDescription: function () {
            return this.charLeft(this.coopForm.description)
        },
        charLeftPublicationsTitle: function () {
            return this.charLeft(this.publicationsForm.title)
        },
        charLeftPublicationsDescription: function () {
            return this.charLeft(this.publicationsForm.description)
        },
        charLeftPartnersTitle: function () {
            return this.charLeft(this.partnersForm.title)
        },
        charLeftPartnersDescription: function () {
            return this.charLeft(this.partnersForm.description)
        },
        charLeftFundingTitle: function () {
            return this.charLeft(this.fundingForm.title)
        },
        charLeftFundingDescription: function () {
            return this.charLeft(this.fundingForm.description)
        },
        charLeftInvolvedTitle: function () {
            return this.charLeft(this.involvedForm.title)
        },
        charLeftInvolvedDescription: function () {
            return this.charLeft(this.involvedForm.description)
        },
        charLeftInvolvedBlockTitle: function () {
            return this.charLeft(this.involvedForm.block_title)
        },
        charLeftInvolvedBlockContent: function () {
            return this.charLeft(this.involvedForm.block_content)
        },
        charLeftInvolvedParagraph: function () {
            return this.charLeft(this.involvedForm.paragraph)
        }
    },
    watch: {
        error: function (e) {
            if (e && this.showModal) {
                document.body.style.overflow = 'hidden'
            } else {
                document.body.style.overflow = 'auto'
            }
        },
        showModal: function (e) {
            if (!e) {
                document.body.style.overflow = 'auto'
            }
        }

    },
    methods: {
        selected(e) {
            let array = ['home', 'teaching', 'team', 'research', 'impact', 'coop', 'publications', 'partners', 'funding', 'involved'];
            Object.keys(array).forEach((key) => {
                let value = array[key]
                let formBlock = value + 'Form';
                this.$refs[value].classList.remove('active');
                this.$refs[formBlock].classList.add('hidden');

                if (value === e) {
                    this.$refs[value].classList.add('active');
                    this.$refs[formBlock].classList.remove('hidden');
                }
            })
        },
        handleToaster(e) {
            this.showToaster = e
        },
        handleModal(e) {
            this.showModal = e
        },
        charLeft(e) {
            return e.length;
        },
        submit(e) {
            this.scrollPos = window.scrollY;
            this.loading = true;
            this.showToaster = true;
            this.showModal = true;
            if (e === 'home') {
                this.$inertia.post(this.$route('store.homepage'),
                    {
                        headerImage: this.homeForm.headerImage,
                        headerReset: this.homeForm.headerReset,
                        headerTitle1: this.homeForm.headerTitle1,
                        headerSubtitle1: this.homeForm.headerSubtitle1,
                        headerTitle2: this.homeForm.headerTitle2,
                        headerSubtitle2: this.homeForm.headerSubtitle2,
                        blockTitle1: this.homeForm.blockTitle1,
                        blockContent1: this.homeForm.blockContent1,
                        map_title: this.homeForm.map_title,
                        map_url: this.homeForm.map_url,
                        map_description: this.homeForm.map_description,
                        blockTitle2: this.homeForm.blockTitle2,
                        blockContent2: this.homeForm.blockContent2,
                        blockTitle3: this.homeForm.blockTitle3,
                        blockContent3: this.homeForm.blockContent3,
                    },
                    {
                        preserveScroll: true,
                        onFinish: visit => {
                            this.loading = false;
                            this.homeForm.headerReset = false;
                            this.homeForm.headerImage = null;
                            this.$refs.homeHeaderUpload.value = '';
                            setTimeout(() => {
                                this.showToaster = false;
                            }, 3000)
                        }
                    }
                );

            } else if (e === 'teaching') {
                this.$inertia.post(this.$route('store.teaching.page'),
                    {
                        teachingHeaderImage: this.teachingForm.headerImage,
                        headerReset: this.teachingForm.headerReset,
                        teachingTitle: this.teachingForm.title,
                        teachingDescription: this.teachingForm.description,
                    },
                    {
                        preserveScroll: true,
                        onFinish: visit => {
                            this.loading = false;
                            this.teachingForm.headerImage = null;
                            this.teachingForm.headerReset = false;
                            this.$refs.teachingHeaderUpload.value = '';
                            setTimeout(() => {
                                this.showToaster = false;
                            }, 3000)
                        }
                    }
                );
            } else if (e === 'team') {
                this.$inertia.post(this.$route('store.team.page'),
                    {
                        teamHeaderImage: this.teamForm.headerImage,
                        headerReset: this.teamForm.headerReset,
                        teamTitle: this.teamForm.title,
                        teamDescription: this.teamForm.description,
                    },
                    {
                        preserveScroll: true,
                        onFinish: visit => {
                            this.loading = false;
                            this.teamForm.headerImage = null;
                            this.teamForm.headerReset = false;
                            this.$refs.teamHeaderUpload.value = '';
                            setTimeout(() => {
                                this.showToaster = false;
                            }, 3000)
                        }
                    }
                );
            } else if (e === 'research') {
                this.$inertia.post(this.$route('store.research.page'),
                    {
                        researchHeaderImage: this.researchForm.headerImage,
                        headerReset: this.researchForm.headerReset,
                        researchTitle: this.researchForm.title,
                        researchDescription: this.researchForm.description,
                        researchBlockTitle1: this.researchForm.block_title_1,
                        researchBlockContent1: this.researchForm.block_content_1,
                        researchBlockImage1: this.researchForm.block_image_1,
                        researchBlockTitle2: this.researchForm.block_title_2,
                        researchBlockContent2: this.researchForm.block_content_2,
                        researchBlockImage2: this.researchForm.block_image_2,
                        researchBlockTitle3: this.researchForm.block_title_3,
                        researchBlockContent3: this.researchForm.block_content_3,
                        researchBlockImage3: this.researchForm.block_image_3,
                        researchBlockTitle4: this.researchForm.block_title_4,
                        researchBlockContent4: this.researchForm.block_content_4,
                        researchBlockImage4: this.researchForm.block_image_4,

                    },
                    {
                        preserveScroll: true,
                        onFinish: visit => {
                            this.loading = false;
                            this.researchForm.headerImage = null;
                            this.researchForm.headerReset = false;
                            this.researchForm.block_image_1 = null;
                            this.researchForm.block_image_2 = null;
                            this.researchForm.block_image_3 = null;
                            this.researchForm.block_image_4 = null;
                            this.$refs.researchBlockImage1.value = '';
                            this.$refs.researchBlockImage2.value = '';
                            this.$refs.researchBlockImage3.value = '';
                            this.$refs.researchBlockImage4.value = '';
                            this.$refs.researchHeaderUpload.value = '';
                            setTimeout(() => {
                                this.showToaster = false;
                            }, 3000)
                        }
                    }
                );
            } else if (e === 'impact') {
                this.$inertia.post(this.$route('store.impact.page'),
                    {
                        impactHeaderImage: this.impactForm.headerImage,
                        headerReset: this.impactForm.headerReset,
                        impactTitle: this.impactForm.title,
                        impactDescription: this.impactForm.description,
                        impactDataTitle1: this.impactForm.data_title_1,
                        impactDataContent1: this.impactForm.data_content_1,
                        impactDataTitle2: this.impactForm.data_title_2,
                        impactDataContent2: this.impactForm.data_content_2,
                        impactDataTitle3: this.impactForm.data_title_3,
                        impactDataContent3: this.impactForm.data_content_3,
                        impactParagraphe1: this.impactForm.paragraphe_1,
                        impactBlockTitle: this.impactForm.block_title,
                        impactBlockContent: this.impactForm.block_content,
                        impactBlockImage: this.impactForm.block_image,
                        impactParagraphe2: this.impactForm.paragraphe_2
                    },
                    {
                        preserveScroll: true,
                        onFinish: visit => {
                            this.loading = false;
                            this.impactForm.headerImage = null;
                            this.impactForm.headerReset = false;
                            this.impactForm.block_image = null;
                            this.$refs.impactHeaderUpload.value = '';
                            this.$refs.impactBlockImage.value = '';
                            setTimeout(() => {
                                this.showToaster = false;
                            }, 3000)
                        }
                    }
                );
            } else if (e === 'coop') {
                this.$inertia.post(this.$route('store.coop.page'),
                    {
                        coopHeaderImage: this.coopForm.headerImage,
                        headerReset: this.coopForm.headerReset,
                        coopTitle: this.coopForm.title,
                        coopDescription: this.coopForm.description,
                    },
                    {
                        preserveScroll: true,
                        onFinish: visit => {
                            this.loading = false;
                            this.coopForm.headerImage = null;
                            this.coopForm.headerReset = false;
                            this.$refs.coopHeaderUpload.value = '';
                            setTimeout(() => {
                                this.showToaster = false;
                            }, 3000)
                        }
                    }
                );
            } else if (e === 'publications') {
                console.log('CLICK OK');
                this.$inertia.post(this.$route('store.publications.page'),
                    {
                        publicationsHeaderImage: this.publicationsForm.headerImage,
                        headerReset: this.publicationsForm.headerReset,
                        publicationsTitle: this.publicationsForm.title,
                        publicationsDescription: this.publicationsForm.description,
                    },
                    {
                        preserveScroll: true,
                        onFinish: visit => {
                            this.loading = false;
                            this.publicationsForm.headerImage = null;
                            this.publicationsForm.headerReset = false;
                            this.$refs.publicationsHeaderUpload.value = '';
                            setTimeout(() => {
                                this.showToaster = false;
                            }, 3000)
                        }
                    }
                );
            } else if (e === 'partners') {
                this.$inertia.post(this.$route('store.partners.page'),
                    {
                        partnersHeaderImage: this.partnersForm.headerImage,
                        headerReset: this.partnersForm.headerReset,
                        partnersTitle: this.partnersForm.title,
                        partnersDescription: this.partnersForm.description,
                    },
                    {
                        preserveScroll: true,
                        onFinish: visit => {
                            this.loading = false;
                            this.partnersForm.headerImage = null;
                            this.partnersForm.headerReset = false;
                            this.$refs.partnersHeaderUpload.value = '';
                            setTimeout(() => {
                                this.showToaster = false;
                            }, 3000)
                        }
                    }
                );
            } else if (e === 'funding') {
                this.$inertia.post(this.$route('store.funding.page'),
                    {
                        fundingHeaderImage: this.fundingForm.headerImage,
                        headerReset: this.fundingForm.headerReset,
                        fundingTitle: this.fundingForm.title,
                        fundingDescription: this.fundingForm.description,
                    },
                    {
                        preserveScroll: true,
                        onFinish: visit => {
                            this.loading = false;
                            this.fundingForm.headerImage = null;
                            this.fundingForm.headerReset = false;
                            this.$refs.fundingHeaderUpload.value = '';
                            setTimeout(() => {
                                this.showToaster = false;
                            }, 3000)
                        }
                    }
                );
            } else if (e === 'involved') {
                this.$inertia.post(this.$route('store.involved.page'),
                    {
                        involvedHeaderImage: this.involvedForm.headerImage,
                        headerReset: this.involvedForm.headerReset,
                        involvedTitle: this.involvedForm.title,
                        involvedDescription: this.involvedForm.description,
                        involvedBlockTitle: this.involvedForm.block_title,
                        involvedBlockContent: this.involvedForm.block_content,
                        involvedBlockImage: this.involvedForm.block_image,
                        involvedParagraph: this.involvedForm.paragraph
                    },
                    {
                        preserveScroll: true,
                        onFinish: visit => {
                            this.loading = false;
                            this.involvedForm.headerImage = null;
                            this.involvedForm.headerReset = false;
                            this.involvedForm.block_image = null;
                            this.$refs.involvedHeaderUpload.value = '';
                            this.$refs.involvedBlockImage.value = '';
                            setTimeout(() => {
                                this.showToaster = false;
                            }, 3000)
                        }
                    }
                );
            }
        }
    }
}
</script>

<style scoped>
.nav-link {
    cursor: pointer;
}

.hidden {
    display: none;
}

.w-110 {
    width: 110%;
}

.form-floating > .form-control:not(:placeholder-shown) label {
    opacity: 1 !important;
}
</style>
